import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';

// DR Card
import DashboardServiceCard from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCard';
import DashboardServiceCardContent from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardContent';
import DashboardServiceCardHeader from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeader';
import DashboardServiceCardHeaderActionButton from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardHeaderActionButton';
import DashboardServiceCardStatusbar from 'components/v4/Cards/DashboardServiceCard/DashboardServiceCardStatusbar';
import AppAuthStatus from './AppAuthStatus';

// Components
import Text from 'components/v4/Text';
import Image from 'components/v4/Image';
import ServiceDashboardItemSyncUsers from 'components/v4/Services/ServiceDashboard/ServiceDashboardItemSyncUsers';
import ServiceDashboardTenantId from 'components/v4/Services/ServiceDashboard/ServiceDashboardTenantId';
import { MenuItem } from 'components/v4';

// Util
import { dateStringToDate } from 'utils/utils';

// API
import postOverrideLimit from 'API/Service/postOverrideLimit';

// Assets
import MsAppAuthLogo from 'assets/images/Graph_API_logo.png';
import postTCAPUserReset from 'API/Service/postTCAPUserReset';

import OverrideLimitModal from 'components/modal/OverrideLimitModal';
import path from '@/routes/constants';
import { useServicesDashboard } from 'context/ServicesDashboardContext';
import { APP_AUTH, loaded_status } from '../constants';
import { useServicesDashboardDispatch } from 'context/ServicesDashboardContext';
import { IconComponent } from '../IconComponent';

const walkthroughIdPrefix = '/dashboard-services/card';

export function ServiceDashboardItemLastHealthCheck({ data }) {
    if (!data.lastProcessed)
        return (
            <Box
                sx={{
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                }}>
                <Text
                    sx={{
                        fontWeight: '500',
                        minWidth: '120px',
                        marginBottom: '5px',
                        visibility: 'hidden',
                    }}>
                    {data.lastProcessed && 'Last Health Check'}
                </Text>
                <Text sx={{ visibility: 'hidden' }}>
                    {'Last Health Check,Last Health Check,Last Health Check'}
                </Text>
            </Box>
        );

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                marginBottom: '15px',
            }}>
            <Text
                sx={{
                    fontWeight: '500',
                    minWidth: '120px',
                    marginBottom: '5px',
                }}>
                {data.lastProcessed && 'Last Health Check'}
            </Text>
            <Text>{data.lastProcessed ?? ''}</Text>
        </Box>
    );
}

export default function AppAuthCard({
    service,
    serviceWizard,
    companyAppAuth,
    companyData,
}) {
    const modalState = useDisclosure();
    const accountScope = useSelector((state) => state.login.requiredScope);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [status, setStatus] = useState('idle');
    const [data, setData] = useState(service);

    const [serviceObj, setServiceObj] = useState(null);
    const { darkMode } = useSelector((state) => state.settings);

    const serviceStatus = useServicesDashboard();
    const cardStatus = serviceStatus[APP_AUTH];
    const dispatch = useServicesDashboardDispatch();

    const handleGrantAccess = async () => await serviceWizard.microsoftAuth();

    useEffect(() => {
        if (companyAppAuth) {
            //set MS Service Auth & Service Data.
            setData({
                ...serviceWizard.msServiceAuth,
                ...service,
                hasAppAuthData: true,
                lastProcessed:
                    serviceWizard.msServiceAuth?.lastProcessedTS &&
                    dateStringToDate(
                        serviceWizard.msServiceAuth?.lastProcessedTS,
                        'datetime',
                    ),
            });
        }
        if (service.id) {
            setServiceObj({ ...service, company: companyData });
        }
    }, [companyAppAuth, service, serviceWizard.msServiceAuth, companyData]);

    useEffect(() => {
        if (serviceObj) {
            const newServiceObj = {
                ...serviceObj,
            };
            dispatch({
                type: `${APP_AUTH}/${loaded_status}`,
                payload: {
                    serviceType: APP_AUTH,
                    service: newServiceObj,
                },
            });
        }
    }, [serviceObj, dispatch]);

    const classes = classNames({
        flash: service.changes === 'added',
    });

    useEffect(() => {
        if (!isOpen) {
            setStatus('idle');
        }
    }, [isOpen]);

    const handlePostOverrideLimit = async () => {
        setStatus('loading');
        const res = await postOverrideLimit(data.id);
        if (res.status === 200) {
            setStatus('success');
            toast({
                title: 'Override Limit',
                description:
                    'Override limit has been submitted for processing.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } else {
            setStatus('error');
            toast({
                title: 'Override Limit',
                description:
                    'Unable to Process Request. One or more validation errors occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleResetServiceAdmin = async () => {
        const res = await postTCAPUserReset(data.id);
        if (res.status === 200) {
            toast({
                title: 'Reset Service Admin',
                description: 'Reset Service Admin has been submitted.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Reset Service Admin',
                description:
                    'Unable to Process Request. One or more validation errors occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // submit Override Limit modal
    const handleSubmit = (e) => {
        e.preventDefault();
        handlePostOverrideLimit();
    };

    return (
        <>
            <ServiceDashboardTenantId {...modalState} />
            <DashboardServiceCard
                className={classes}
                dataWalkthroughId={service.name || service.serviceType}>
                <DashboardServiceCardStatusbar
                    cardStatus={cardStatus}
                    statusDisplay={
                        cardStatus?.statusDisplay || service.statusDisplay
                    }
                />
                <DashboardServiceCardHeader>
                    <Text fontSize="lg" className="truncate" marginBottom={'0'}>
                        Microsoft Graph API
                    </Text>
                    {/* MENU ITEMS  */}
                    <DashboardServiceCardHeaderActionButton
                        dataWalkthroughId={service.serviceType}>
                        {/* Edit service */}
                        <Link
                            to={`${path.SERVICES_APP_AUTH}/${data.id}/edit`}
                            state={service}
                            className="hover:text-inherit hover:!no-underline">
                            <MenuItem
                                icon={
                                    <IconComponent
                                        icon="Edit"
                                        color={darkMode ? 'white' : 'black'}
                                    />
                                }
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/edit-appAuth`}>
                                <Text marginBottom={'0'}>Edit AppAuth</Text>
                            </MenuItem>
                        </Link>

                        {/* Override Delete Limit */}
                        {accountScope >= 80 && (
                            <MenuItem
                                icon={
                                    <DeleteIcon
                                        color={darkMode ? 'white' : 'black'}
                                    />
                                }
                                onClick={onOpen}
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/override-deleteLimit`}>
                                <Text marginBottom={'0'}>
                                    Override Delete Limit
                                </Text>
                            </MenuItem>
                        )}

                        {/* Migration to New Sync */}
                        {!serviceObj?.graphAuth || cardStatus?.grantAccess ? (
                            <MenuItem
                                icon={
                                    <IconComponent
                                        icon="Settings"
                                        color={darkMode ? 'white' : 'black'}
                                    />
                                }
                                onClick={handleGrantAccess}
                                data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/provide-access`}>
                                <Text marginBottom={'0'}>Grant Access</Text>
                            </MenuItem>
                        ) : (
                            !serviceObj?.msGraphAppInstanceID && (
                                <MenuItem
                                    icon={
                                        <IconComponent
                                            icon="Settings"
                                            color={darkMode ? 'white' : 'black'}
                                        />
                                    }
                                    onClick={handleGrantAccess}
                                    data-walkthroughid={`${walkthroughIdPrefix}/${service.serviceType}/provide-access`}>
                                    <Text marginBottom={'0'}>
                                        Migrate to New Sync
                                    </Text>
                                </MenuItem>
                            )
                        )}
                    </DashboardServiceCardHeaderActionButton>
                </DashboardServiceCardHeader>
                <DashboardServiceCardContent>
                    <Box>
                        <Image
                            src={MsAppAuthLogo}
                            sx={{
                                maxWidth: '50px',
                                margin: '0 auto 15px auto',
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDir: 'column',
                            }}>
                            {/* Status */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Status
                                </Text>
                                <AppAuthStatus
                                    serviceObj={serviceObj}
                                    handleActions={{
                                        handleResetServiceAdmin,
                                        handleGrantAccess,
                                    }}
                                />
                            </Box>

                            {/* Sync */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '15px',
                                }}>
                                <Text
                                    sx={{
                                        fontWeight: '500',
                                    }}>
                                    Sync
                                </Text>
                                <Text sx={{ textAlign: 'left' }}>
                                    {/* TODO: APPAUTH change this when backend is ready */}
                                    <ServiceDashboardItemSyncUsers
                                        data={data}
                                    />
                                </Text>
                            </Box>

                            {/* Last Health Check */}
                            <ServiceDashboardItemLastHealthCheck data={data} />
                        </Box>
                    </Box>
                </DashboardServiceCardContent>
            </DashboardServiceCard>
            {/* Override Delete Limit Modal */}
            <OverrideLimitModal
                isOpen={isOpen}
                onClose={onClose}
                status={status}
                handleSubmit={handleSubmit}
            />
        </>
    );
}
