import { AddIcon } from '@chakra-ui/icons';
import {
    AlertTitle,
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    ModalCloseButton,
    Tooltip,
    useBoolean,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { BiImport } from 'react-icons/bi';
import { GiConfirmed } from 'react-icons/gi';
import { RiShutDownLine } from 'react-icons/ri';
import { TbProgressCheck } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    postActivateNumbers,
    postDeactivateNumbers,
    postValidateNumbers,
} from 'API/TPMTrial';
import { useAssociatedAccounts } from 'hooks/useCustomQuery';
import {
    clearErrors,
    clearErrorsDn,
    ERROR_MESSAGES,
    groupMessages,
    initValidation,
    NOT_ALLOCATE,
    NUMBER_EXCEED_LIMIT,
    refreshTable,
    search,
} from './helper';

import {
    Alert,
    AlertIcon,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@/components/v4';
import { useTpmContext } from '../';
import {
    ActivateNumbers as ActivateNumbersTable,
    ActivateNumbers as DeactivateNumbersTable,
} from './ActivateNumbers';
import AssociatedAccountsTable from './AssociatedAccountsTable';
import DisplayMessages from './DisplayMessages';
import ErrorAssociatedAccCard from './ErrorAssociatedAccCard';
import ImportError from './ImportError';
import ImportLoading from './ImportLoading';
import ImportSuccess from './ImportSuccess';
import TableToolbar from './TableToolbar';

import { CONTINUE, RESET } from '@/store/constants';
import { FaCircleCheck } from 'react-icons/fa6';
import { initialState } from '../constants';
import TrashIcon from './TrashIcon';

export default function AssociatedAccounts() {
    const {
        tpmNumber: [state, setState],
        tpmActivatedNumber: [telephoneNumbers, setTelephoneNumbers],
        tpmDeactivateNumber: [deactivateNumbers, setDeactivateNumbers],
    } = useTpmContext();
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDn, setFilterDn] = useState([]);
    const [numberCopy, setNumberCopy] = useState(false);
    const [dnCopy, setDnCopy] = useState(false);
    const [validateNumbers, setValidateNumbers] = useState([initValidation]);
    const [dnList, setDnList] = useState([initValidation]);
    const [formState, setFormState] = useState('idle');
    const [deactivatedList, setDeactivatedList] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activateSpin, setActivateSpin] = useBoolean();
    const [deactivateSpin, setDeactivateSpin] = useBoolean();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { pathname } = useLocation();
    const previousPage = pathname.split('/').slice(0, -1).join('/');
    const { darkMode, restError, colorScheme } = useSelector((state) => {
        return {
            darkMode: state.settings.darkMode,
            restError: state.modal.restError,
            colorScheme: state.settings?.colorScheme,
        };
    });
    const { number: mpn, currentStep } = useSelector(
        (state) => state.service.tpm,
    );

    const { mutate, status: verifiedStatus } = useVerifyNumbers();
    const { mutate: importNumbers, status: importStatus } = useImport();
    const { mutateAsync: importDNumbers, status: verifiedDnStatus } =
        useDeactivate();

    const handleRefresh = () => {
        refreshTable(setTelephoneNumbers);
        setValidateNumbers([initValidation]);
    };

    const handleRefreshDn = () => {
        refreshTable(setDeactivateNumbers);
        setFormState('idle');
    };
    const handleSearch = (term) => {
        search(term, telephoneNumbers, setFilteredData);
    };

    const handleSearchDn = (term) => {
        search(term, deactivateNumbers, setFilterDn);
    };

    const handleSpin = (type) => {
        const setSpin =
            type === 'activate' ? setActivateSpin : setDeactivateSpin;
        const refreshFunction =
            type === 'activate' ? handleRefresh : handleRefreshDn;

        setSpin.on();
        const numberInput = document.querySelector(
            `[aria-label^="${type}-number-input-0"]`,
        );

        const timer = setTimeout(() => {
            setSpin.off();
            refreshFunction();
            if (numberInput) {
                numberInput.focus();
            }
        }, 500);

        return () => clearTimeout(timer);
    };

    const handleStartOver = () => {
        setState(initialState);
        dispatch({
            type: 'RESET_MODAL',
        });
        dispatch({ type: RESET });
        navigate('/dashboard-services');
    };

    const handleVerify = () => {
        const telephoneList = [
            ...new Map(
                telephoneNumbers.map((item) => [item.number, item]),
            ).values(),
        ]; // remove duplicates

        mutate(
            telephoneList.map((item) => item.number),
            {
                onSuccess: async (res) => {
                    toast.success('Numbers verified!', { autoClose: 1500 });
                    const data = await res.data;
                    const vTelNumbers = telephoneList.map((item) => {
                        const foundData = data.find(
                            (d) => d.number === item.number,
                        );
                        if (foundData) {
                            return {
                                ...item,
                                status: foundData.message && 'FAIL',
                            };
                        }
                        return {
                            ...item,
                            status: 'PASS',
                        };
                    });

                    setTelephoneNumbers(vTelNumbers);
                    setValidateNumbers(data);
                },
            },
        );
    };

    const handleContinueButton = () => {
        setState(initialState);
        dispatch({
            type: 'RESET_MODAL',
        });
        // TODO: extra action, check to remove in the future
        dispatch({
            type: CONTINUE,
        });
    };

    const handleAssociationButton = () => {
        setState(initialState);
        dispatch({
            type: RESET,
        });
    };

    function useVerifyNumbers() {
        return useMutation({
            mutationFn: async (data) => {
                try {
                    return await postValidateNumbers({
                        serviceID: id,
                        phoneNumbers: data,
                    });
                } catch (error) {
                    throw new Error(
                        error?.message || 'Error verifying numbers',
                    );
                }
            },
        });
    }

    const handleClearErrors = () => {
        clearErrors(setTelephoneNumbers, setValidateNumbers);
    };

    const handleClearErrorsDn = () => {
        clearErrorsDn(setDeactivateNumbers);
        setDnList([initValidation]);
        setFormState('idle');
    };

    const handleSubmit = () => {
        setFormState('submitting');

        const activateNumbers = telephoneNumbers
            .filter((item) => item.status === 'PASS')
            .map((item) => item.number);
        importNumbers(activateNumbers, {
            onSuccess: () => {
                setFormState('finish');
            },
            onError: (error) => {
                setFormState('error');
                throw new Error(error);
            },
        });
    };

    const handleSubmitDn = async () => {
        setFormState('submitting');

        try {
            const data = await importDNumbers(
                deactivateNumbers.map((item) => item.number),
                {
                    onSuccess: () => {
                        setFormState('finish');
                        toast.success('Numbers deactivated!', {
                            autoClose: 1500,
                        });
                        setDeactivatedList(
                            deactivateNumbers.map((item) => item.number),
                        );
                        onOpen();
                        setDeactivateNumbers([{ number: '', status: '' }]);
                    },
                    onError: () => {
                        setFormState('error');
                    },
                },
            );
            return data;
        } catch (error) {
            const errMsg = error?.response?.data.message || restError;
            const regexNums = errMsg.match(/(?<=:\s*)[+\d]+(?:,\s*[+\d]+)*/);
            const errorMessageKey = errMsg?.split(':')[0];
            const errorMessage = ERROR_MESSAGES[errorMessageKey];
            const mapErrNums = regexNums ? regexNums[0].split(/\s*,\s*/) : [];
            const vDnNumbers = deactivateNumbers.map((item) => {
                const foundData = mapErrNums.find((d) => d === item.number);
                if (foundData) {
                    return {
                        ...item,
                        status: 'FAIL',
                        action: errorMessage.includes(NOT_ALLOCATE)
                            ? 'UNASSIGN'
                            : null,
                    };
                }
                return {
                    ...item,
                    status: '',
                };
            });

            setDeactivateNumbers(vDnNumbers);
            setDnList(() =>
                mapErrNums.map((dn) => ({
                    message: errorMessage,
                    number: dn,
                })),
            );
        }
    };

    function useImport() {
        return useMutation({
            mutationFn: async (data) => {
                try {
                    return await postActivateNumbers({
                        serviceID: id,
                        phoneNumbers: data,
                    });
                } catch (error) {
                    throw new Error(
                        error?.message || 'Error importing numbers',
                    );
                }
            },
        });
    }

    function useDeactivate() {
        return useMutation({
            mutationFn: async (data) => {
                return await postDeactivateNumbers({
                    serviceID: id,
                    phoneNumbers: data,
                });
            },
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const onSubmitDn = (e) => {
        e.preventDefault();
        handleSubmitDn();
    };

    const groupedMessages =
        verifiedStatus === 'success' &&
        validateNumbers.length >= 1 &&
        groupMessages(validateNumbers);

    const {
        data: associatedAccounts,
        isLoading,
        isError,
    } = useAssociatedAccounts(id);

    const accCardProps = {
        id,
        setState,
        state,
        darkMode,
    };

    const importProps = {
        restError,
        onClick: handleStartOver,
    };

    useEffect(() => {
        setFilteredData(telephoneNumbers);
        setFilterDn(deactivateNumbers);
    }, [telephoneNumbers, deactivateNumbers, setDeactivateNumbers]);

    useEffect(() => {
        if (state.number) {
            const newStatus = associatedAccounts?.find(
                (aa) => aa?.verifiedNumber === state.number,
            )
                ? 'PASS'
                : '';
            setTelephoneNumbers([{ number: state.number, status: newStatus }]);
        }
    }, [state.number, associatedAccounts, setTelephoneNumbers]);

    useEffect(() => {
        if (!telephoneNumbers?.length) {
            setTelephoneNumbers([{ number: '', status: '' }]);
        }
        if (!deactivateNumbers?.length) {
            setDeactivateNumbers([{ number: '', status: '' }]);
        }
    }, [
        telephoneNumbers,
        setTelephoneNumbers,
        deactivateNumbers,
        setDeactivateNumbers,
    ]);

    if (isLoading) {
        return (
            <VStack
                className="h-[calc(100vh-200px)]"
                width="full"
                justifyContent="center">
                <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                    <CardBody>
                        <Flex
                            justifyContent="flex-start"
                            flexDir="column"
                            width="full">
                            <Heading
                                as="h4"
                                fontWeight="semibold"
                                fontSize="2xl"
                                color={darkMode ? 'white' : 'black'}>
                                Associated Accounts
                            </Heading>
                            <Text mt={2}>
                                These associated entities exist with your
                                Operator account.
                            </Text>
                        </Flex>

                        <AssociatedAccountsTable
                            {...accCardProps}
                            data={associatedAccounts}
                            loading={isLoading}
                            serviceID={id}
                        />
                    </CardBody>
                </Card>
            </VStack>
        );
    }

    if (!associatedAccounts?.length) {
        return <Navigate to={previousPage} />;
    }

    if (isError || state?.status === 'ERROR') {
        return (
            <ErrorAssociatedAccCard
                initialState={initialState}
                navigate={navigate}
                {...accCardProps}
            />
        );
    }

    if (importStatus === 'loading') {
        return <ImportLoading />;
    }

    if (formState === 'error' && importStatus === 'error') {
        return <ImportError {...importProps} />;
    }

    if (formState === 'finish' && importStatus === 'success') {
        return <ImportSuccess {...importProps} />;
    }

    return (
        <VStack height="full" width="full">
            <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'} width="full">
                <CardBody>
                    <Flex
                        justifyContent="flex-start"
                        flexDir="column"
                        width="full">
                        <Heading
                            as="h4"
                            fontWeight="semibold"
                            fontSize="2xl"
                            color={darkMode ? 'white' : 'black'}>
                            Associated Accounts
                        </Heading>
                        <Text>
                            These associated entities exist with your Operator
                            account.
                        </Text>
                    </Flex>
                    <AssociatedAccountsTable
                        {...accCardProps}
                        data={associatedAccounts}
                        loading={isLoading}
                        serviceID={id}
                    />

                    <Flex width="full" flexDir="row" mt={6}>
                        <Flex justifyContent="flex-end" width="full">
                            {mpn && currentStep !== 0 && currentStep !== 3 && (
                                <Link
                                    className="ml-14"
                                    to={
                                        currentStep === 1
                                            ? {
                                                  pathname: `/services/tpm/${id}/activation/`,
                                              }
                                            : {
                                                  pathname:
                                                      '../sms-authorization',
                                                  search: `?number=${mpn.replace(/\d(?=\d{4})/g, 'X')}`,
                                              }
                                    }
                                    state={{ from: pathname }}>
                                    <Button
                                        px={2}
                                        sx={{
                                            '& span.chakra-button__icon': {
                                                me: 1,
                                            },
                                        }}
                                        minW="120px"
                                        colorScheme={
                                            colorScheme === 'secondary' ||
                                            colorScheme === 'green' ||
                                            colorScheme === 'whatsapp'
                                                ? 'tertiary'
                                                : 'secondary'
                                        }
                                        onClick={handleContinueButton}
                                        leftIcon={<TbProgressCheck />}>
                                        Continue
                                    </Button>
                                </Link>
                            )}
                            <Link to={previousPage} state={{ from: pathname }}>
                                <Button
                                    px={2}
                                    ml={6}
                                    sx={{
                                        '': {
                                            me: 1,
                                        },
                                    }}
                                    minW="128px"
                                    leftIcon={<AddIcon />}
                                    onClick={handleAssociationButton}>
                                    Association
                                </Button>
                            </Link>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>

            <Box aria-label="spacing box" display="flex" className="h-4" />

            {/* Activate Numbers Table */}
            {associatedAccounts?.length > 0 && (
                <Card
                    as="form"
                    onSubmit={onSubmit}
                    bg={darkMode ? 'dark.bgDarkGray' : 'white'}
                    width="full"
                    mt={4}>
                    <CardBody>
                        <Flex width="full" className="mt-0">
                            <Flex
                                justifyContent="flex-start"
                                flexDir="column"
                                width="full"
                                className="mt-1">
                                <Heading
                                    as="h4"
                                    fontWeight="semibold"
                                    fontSize="2xl"
                                    color={darkMode ? 'white' : 'black'}>
                                    Activate Numbers
                                </Heading>
                                <Text fontSize="sm">
                                    Add numbers below. 1 per line, max 1000
                                    lines.
                                </Text>
                            </Flex>
                            {/* Toolbar */}
                            <TableToolbar
                                type="activate"
                                onSearch={handleSearch}
                                darkMode={darkMode}
                                spin={activateSpin}
                                onSpin={() => handleSpin('activate')}
                            />
                        </Flex>

                        <Alert
                            status="info"
                            display="-webkit-box"
                            // mb="20px"
                            mt="15px">
                            <AlertIcon />
                            <Text>
                                To quickly add multiple numbers, copy and paste
                                a line separated list of numbers below.
                            </Text>
                        </Alert>
                        <Box color={darkMode ? 'white' : 'black'}>
                            {/* Error Message */}
                            {verifiedStatus === 'success' &&
                                validateNumbers?.length >= 1 &&
                                telephoneNumbers.find(
                                    (n) => n.status === 'FAIL',
                                ) && (
                                    <Alert
                                        status="error"
                                        flexDir="column"
                                        alignItems="flex-start"
                                        mt={4}>
                                        <Flex>
                                            <AlertIcon />
                                            <AlertTitle>
                                                Verification Error!
                                            </AlertTitle>
                                        </Flex>
                                        <DisplayMessages
                                            groupedMessages={groupedMessages}
                                        />
                                    </Alert>
                                )}

                            {telephoneNumbers?.length > 1000 && (
                                <Alert
                                    status="error"
                                    flexDir="column"
                                    alignItems="flex-start"
                                    mt={4}>
                                    <Flex>
                                        <AlertIcon />
                                        <AlertTitle>Error!</AlertTitle>
                                        {NUMBER_EXCEED_LIMIT}
                                    </Flex>
                                </Alert>
                            )}
                        </Box>

                        <ActivateNumbersTable
                            type="activate"
                            data={
                                filteredData ? filteredData : telephoneNumbers
                            }
                            setData={setTelephoneNumbers}
                            verifiedStatus={verifiedStatus}
                            numberCopy={numberCopy}
                            setNumberCopy={setNumberCopy}
                            spin={activateSpin}
                        />
                        <Flex width="full" flexDir="row" mt={6}>
                            <Flex justifyContent="flex-start" width="full">
                                <Tooltip
                                    hasArrow
                                    shouldWrapChildren
                                    mt="1"
                                    label={
                                        telephoneNumbers?.some(
                                            (item) => item.number === '',
                                        )
                                            ? 'Please fill in all numbers'
                                            : ''
                                    }>
                                    <Button
                                        colorScheme="whatsapp"
                                        minW="120px"
                                        px={2}
                                        onClick={handleVerify}
                                        leftIcon={<GiConfirmed />}
                                        isDisabled={
                                            telephoneNumbers?.some(
                                                (item) => item.number === '',
                                            ) || verifiedStatus === 'loading'
                                        }>
                                        {verifiedStatus === 'loading'
                                            ? 'Verifying'
                                            : 'Verify'}
                                    </Button>
                                </Tooltip>
                            </Flex>
                            <Flex justifyContent="space-between" width="full">
                                <Button
                                    ml={14}
                                    variant="outline"
                                    className="group bg-white"
                                    sx={{
                                        '& .chakra-button__icon': {
                                            marginTop: '0.25rem',
                                        },
                                    }}
                                    minW="120px"
                                    isDisabled={
                                        !telephoneNumbers?.some(
                                            (item) => item.status === 'FAIL',
                                        )
                                    }
                                    leftIcon={<TrashIcon />}
                                    onClick={handleClearErrors}>
                                    Errors
                                </Button>
                                <Tooltip
                                    hasArrow
                                    shouldWrapChildren
                                    mt="1"
                                    label={
                                        telephoneNumbers?.some(
                                            (item) => item.status !== 'PASS',
                                        )
                                            ? 'Please verify numbers and clear errors before importing'
                                            : ''
                                    }>
                                    <Button
                                        type="submit"
                                        minW="128px"
                                        ml={6}
                                        leftIcon={<BiImport />}
                                        isDisabled={
                                            telephoneNumbers?.some(
                                                (item) =>
                                                    item.status !== 'PASS',
                                            ) || importStatus === 'loading'
                                        }>
                                        Import
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    </CardBody>
                </Card>
            )}

            <Box aria-label="spacing box" display="flex" className="h-4" />
            {/* Deactivate Numbers Table */}
            {associatedAccounts?.length > 0 && (
                <Card
                    as="form"
                    onSubmit={onSubmitDn}
                    bg={darkMode ? 'dark.bgDarkGray' : 'white'}
                    width="full"
                    mt={4}>
                    <CardBody>
                        <Flex width="full" className="mt-0">
                            <Flex
                                justifyContent="flex-start"
                                flexDir="column"
                                width="full"
                                className="mt-1">
                                <Heading
                                    as="h4"
                                    fontWeight="semibold"
                                    fontSize="2xl"
                                    color={darkMode ? 'white' : 'black'}>
                                    Deactivate Numbers
                                </Heading>
                                <Text fontSize="sm">
                                    Add numbers below. 1 per line, max 1000
                                    lines.
                                </Text>
                            </Flex>
                            {/* Toolbar */}
                            <TableToolbar
                                type="deactivate"
                                onSearch={handleSearchDn}
                                darkMode={darkMode}
                                spin={deactivateSpin}
                                onSpin={() => handleSpin('deactivate')}
                            />
                        </Flex>

                        <Alert status="info" display="-webkit-box" mt="15px">
                            <AlertIcon />
                            <Text>
                                To quickly add multiple numbers, copy and paste
                                a line separated list of numbers below.
                            </Text>
                        </Alert>
                        <Box color={darkMode ? 'white' : 'black'}>
                            {verifiedDnStatus === 'error' &&
                                formState === 'error' && (
                                    <Alert
                                        status="error"
                                        alignItems="center"
                                        mt={4}>
                                        <Flex>
                                            <AlertIcon />
                                            <AlertTitle>
                                                Deactivate Error!
                                            </AlertTitle>
                                        </Flex>
                                        <Flex minH="24px" alignItems="center">
                                            {Object.keys(ERROR_MESSAGES).find(
                                                (ms) =>
                                                    ERROR_MESSAGES[ms] ===
                                                    dnList[0]?.message,
                                            ) + '.'}
                                        </Flex>
                                    </Alert>
                                )}

                            {deactivateNumbers?.length > 1000 && (
                                <Alert
                                    status="error"
                                    flexDir="column"
                                    alignItems="flex-start"
                                    mt={4}>
                                    <Flex>
                                        <AlertIcon />
                                        <AlertTitle>Error!</AlertTitle>
                                        {NUMBER_EXCEED_LIMIT}
                                    </Flex>
                                </Alert>
                            )}
                        </Box>

                        <DeactivateNumbersTable
                            type="deactivate"
                            data={filteredDn ? filteredDn : deactivateNumbers}
                            setData={setDeactivateNumbers}
                            verifiedStatus={verifiedDnStatus}
                            numberCopy={dnCopy}
                            setNumberCopy={setDnCopy}
                            spin={deactivateSpin}
                        />
                        <Flex width="full" flexDir="row" mt={6}>
                            <Flex
                                justifyContent="flex-start"
                                width="full"
                                aria-label="spacing flex"></Flex>
                            <Flex justifyContent="space-between" width="full">
                                <Button
                                    ml={14}
                                    variant="outline"
                                    className="group bg-white"
                                    sx={{
                                        '& .chakra-button__icon': {
                                            marginTop: '0.25rem',
                                        },
                                    }}
                                    minW="120px"
                                    isDisabled={
                                        !deactivateNumbers.some(
                                            (item) => item.status === 'FAIL',
                                        )
                                    }
                                    onClick={handleClearErrorsDn}
                                    leftIcon={<TrashIcon />}>
                                    Errors
                                </Button>
                                <Button
                                    type="submit"
                                    px={2}
                                    ml={6}
                                    sx={{
                                        '& .chakra-button__icon': {
                                            me: 1,
                                        },
                                    }}
                                    minW="128px"
                                    leftIcon={<RiShutDownLine />}
                                    isDisabled={deactivateNumbers?.some(
                                        (item) =>
                                            item.number === '' ||
                                            isNaN(Number(item.number)),
                                    )}>
                                    Deactivate
                                </Button>
                            </Flex>
                        </Flex>
                    </CardBody>
                </Card>
            )}
            <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Numbers Deactivated</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack
                            maxH="300px"
                            overflowY="auto"
                            spacing={2}
                            align="stretch">
                            {deactivatedList?.map((number, index) => (
                                <Flex
                                    key={`${number}-${index}`}
                                    alignItems="center">
                                    <Icon
                                        boxSize={4}
                                        aria-label="Checked number"
                                        color="green.500"
                                        as={FaCircleCheck}
                                    />
                                    <Text ml={2}>{number}</Text>
                                </Flex>
                            ))}
                        </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Button colorScheme="messenger" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </VStack>
    );
}

AssociatedAccounts.displayName = 'AssociatedAccounts.Index';
