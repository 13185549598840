import {
    isIgnoredFilter,
    API_RESULT_FIELDS_TO_EXPORT,
    FAILED_FILENAME,
    ERROR_MESSAGE,
} from './constants';
import { axios } from 'services/axios';
import { downloadCSV } from 'utils/utils';
import { useCallback, useState } from 'react';
import Papa from 'papaparse';

export default function useCSVNumbersBulkUpdate() {
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [succeed, setSucceed] = useState([]);
    const [counter, setCounter] = useState(0);
    const [errorCounter, setErrorCounter] = useState(0);
    const [uploadPayloads, setUploadPayloads] = useState([]);

    //entry point to start API call
    const processSubmission = async (payloads) => {
        setLoading(true);
        // const finalPayloads = transformSubmitData(payloads);
        handleBulkUpdate(payloads);
    };

    //after API calls, set UI State
    const handleFinishBulkUpdate = ({ errors, responses }) => {
        setError(errors);
        setSucceed(responses);
    };

    //handle api calls
    const handleBulkUpdate = async (payloads) => {
        const errors = [];
        const responses = [];
        setUploadPayloads(payloads);
        payloads.forEach(async (payload, index) => {
            try {
                //api call
                const response = await axios.put('/number/', payload);
                //then store the success response
                responses.push(response);
            } catch (e) {
                //put to failed table
                errors.push({
                    ...payload,
                    apiErrors:
                        `${e?.message}\n${e?.response.data?.message}\n${e?.response?.status}` ||
                        ERROR_MESSAGE,
                });
                //count as error
                setErrorCounter((prev) => prev + 1);
            } finally {
                //set UI counter
                setCounter((prev) => prev + 1);
                if (index === payloads.length - 1) {
                    //setState for Failed & Succeed table
                    handleFinishBulkUpdate({ errors, responses });
                }
            }
        });
    };

    /**
     * handle UI button - start api call when payload is ready.
     */
    const handlePayloadChange = useCallback((payloads) => {
        processSubmission(payloads);
    }, []);

    /**
     * handle UI button - export API calls that failed to CSV.
     */
    const handleExportFailedData = () => {
        const failedData = transformJSONData(error);
        const res = Papa.unparse(failedData);
        downloadCSV(res, FAILED_FILENAME);
    };

    /**
     * transforms JSON Data from BE fields into more human readable CSV heading columns
     * @param {*} data
     * @returns
     */
    const transformJSONData = (data) => {
        return data.map((number) => {
            return Object.keys(API_RESULT_FIELDS_TO_EXPORT).reduce(
                (acc, key) => {
                    const value = number[key];
                    const newKey = API_RESULT_FIELDS_TO_EXPORT[key];
                    acc[newKey] = value;
                    return acc;
                },
                {},
            );
        });
    };

    return {
        handlePayloadChange,
        loading,
        counter,
        errorCounter,
        error,
        succeed,
        handleExportFailedData,
        total: uploadPayloads.length,
    };
}
