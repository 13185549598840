import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';

function DeleteAssociatedAccModal({
    isOpen,
    onClose,
    selectedRow,
    handleSubmit,
    status,
}) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent as="form" onSubmit={handleSubmit}>
                <ModalHeader>
                    Delete {selectedRow?.operatorCustomerID?.toUpperCase()}{' '}
                    Associated Account
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to remove{' '}
                    <span className="font-semibold">
                        {selectedRow?.operatorCustomerID}
                    </span>
                    &nbsp;as an associated account?
                </ModalBody>
                <ModalFooter justifyContent="space-evenly">
                    <Button onClick={onClose} variant="outline">
                        Close
                    </Button>
                    <Button
                        type="submit"
                        colorScheme="red"
                        isDisabled={status === 'loading'}
                        _focus={{
                            boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
                        }}>
                        {status === 'loading' ? 'Deleting...' : 'Delete'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DeleteAssociatedAccModal;
