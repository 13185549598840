export const root = {
    '--card-bg': '#FFFFFF',
    '--chakra-colors-body-text': 'var(--chakra-colors-gray-800)',
    '--card-radius': 'var(--chakra-radii-md)',
    '--card-padding': 'var(--chakra-space-5)',
    '--card-shadow': 'var(--chakra-shadows-base)',
    display: 'flex',
    background: `var(--card-bg)`,
    position: 'relative',
    borderRadius: 'var(--card-radius)',
    boxShadow: 'var(--card-shadow)',
    color: 'var(--chakra-colors-body-text)',
};

export const base = {
    '--card-bg': '#FFFFFF',
    fontFamily: 'var(--chakra-fonts-body)',
    marginTop: 'var(--chakra-space-5)',
    padding: 'var(--chakra-space-3)',
    background: 'var(--card-bg)',
    borderWidth: '1px',
    borderRadius: '12px',
    color: 'var(--chakra-colors-gray-700)',
    lineHeight: 'var(--chakra-lineHeights-base)',
    overflowX: 'auto',
};

export const header = {
    padding: 'var(--card-padding)',
};

export const body = {
    padding: 'var(--card-padding)',
    flex: '1 1 0%',
};

export const footer = {
    padding: 'var(--card-padding)',
};
