import { Box, Heading } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Text,
} from 'components/v4';
import MfaChakraCodeBox from './MfaChakraCodeBox';

import { useTpmContext } from './';

import {
    postRequestNumberVerification,
    postSubmitNumberVerification,
} from 'API/TPMTrial';
import walkthroughIds from './walkthroughIds';

import { RESEND, SUBMIT_NUMBER } from '@/store/constants';
import { path } from './constants';
import { useAssociatedAccounts } from 'hooks/useCustomQuery';

export default function MfaTeamsPhoneMobileStepOne() {
    const {
        tpmNumber: [state, setState],
    } = useTpmContext();
    const [mfaCodeError, setMfaCodeError] = useState({
        error: false,
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [hasEnteredCode, setHasEnteredCode] = useState(false);
    const [mfaChakraCode, setMfaChakraCode] = useState('');
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.settings);
    const {
        number: mpn,
        lastUpdate,
        currentStep,
    } = useSelector((state) => state.service.tpm);
    const dispatch = useDispatch();

    const currentTime = Date.now();
    const [timeElapse, setTimeElapse] = useState(currentTime - lastUpdate);
    const EXPIRED_TIME = 2 * 60 * 1000; // 2 minutes for SMS code
    const mfaChakraCodeRef = useRef(null);

    const { data: associatedAccounts, refetch } = useAssociatedAccounts(id);

    const handleMfaChakraCode = (value) => {
        setMfaChakraCode(value);
        mfaChakraCodeRef.current = value;
        setHasEnteredCode(true);
        if (value.length === 6) {
            mfaLogin();
        }
    };

    const handleResend = () => {
        postRequestNumberVerification({
            serviceID: id,
            phonenumber: mpn,
        })
            .then(() => {})
            .catch((err) => {
                const errMsg =
                    err?.response?.data?.message || 'Something went wrong';
                setMfaCodeError({
                    error: true,
                    message: errMsg,
                });
            });
        dispatch({
            type: RESEND,
        });
    };

    const { ufMfaNextButton, ufMfaCancelButton } =
        walkthroughIds.teamsPhoneMobileTrial;

    const number = new URLSearchParams(searchParams).get('number');
    // TODO: create a case when sending code fail from BE
    const mfaLogin = useCallback(async () => {
        setIsLoading(true);
        // TODO: backend call for Mfa
        // NOTE: enter '317317'
        // NOTE: remove this condition later when backend is ready

        postSubmitNumberVerification({
            serviceID: id,
            phonenumber: mpn || state.number || number,
            code: mfaChakraCodeRef?.current,
        })
            .then(async (res) => {
                if (res.status === 200) {
                    setState((prev) => ({
                        ...prev,
                        number: mpn,
                        status: 'PASS',
                    }));
                    dispatch({
                        type: 'RESET_MODAL',
                    });
                    dispatch({
                        type: SUBMIT_NUMBER,
                        payload: { code: mfaChakraCodeRef?.current },
                    });
                    await refetch();
                }
            })
            .catch((e) => {
                setMfaCodeError({
                    error: true,
                    message:
                        e.response?.data?.message ||
                        'Something went wrong. Please try again later.',
                });
                console.error(e);
            })
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mfaChakraCode, state, number, refetch, navigate]);

    const handleBackButtonClick = () => {
        setState((prev) => ({
            ...prev,
            status: 'IDLE',
        }));
        if (!associatedAccounts?.length) {
            navigate('/dashboard-services');
        } else {
            navigate(-1);
        }
    };

    const onMfaLogin = (e) => {
        e.preventDefault();
        if (hasEnteredCode) {
            mfaLogin();
        }
    };

    const handleSubmitButton = (e) => {
        onMfaLogin(e);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeElapse(Date.now() - lastUpdate);
        }, 1000);
        return () => clearInterval(interval);
    }, [lastUpdate]);

    useEffect(() => {
        if (associatedAccounts?.length > 0 && currentStep === 3) {
            navigate({
                pathname: `..${path.ASSOCIATED_ACCOUNTS}`,
            });
        }
    }, [associatedAccounts, currentStep, navigate]);

    if (!number || !mpn) {
        navigate(`/services/tpm/${id}/activation/associated-accounts`);
    }

    return (
        <Box as="form" className="px-8 pb-8" onSubmit={handleSubmitButton}>
            <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                <CardHeader>
                    <Heading
                        color={darkMode ? 'white' : 'gray.700'}
                        as="h2"
                        textAlign="center">
                        Enter your code
                    </Heading>
                </CardHeader>
                <Box px={6} display="flex" flexDir="column" mx="auto">
                    <CardBody py={0}>
                        <Text fontSize="md" sx={{ marginBottom: 3 }}>
                            You have been sent a Teams Phone Mobile verification
                            code by your mobile Operator to the number{' '}
                            <strong>
                                {(state.number || number)?.replace(
                                    /\d(?=\d{4})/g,
                                    'X',
                                )}
                            </strong>
                        </Text>
                        {timeElapse > EXPIRED_TIME ? (
                            <Text textAlign="center">
                                <Button
                                    variant="link"
                                    onClick={handleResend}
                                    fontWeight="400">
                                    Resend code
                                </Button>
                            </Text>
                        ) : (
                            <Text fontSize="md" textAlign="center">
                                Please enter the code below
                            </Text>
                        )}
                        <Box color={darkMode ? 'white' : 'gray.700'}>
                            <MfaChakraCodeBox
                                onComplete={handleMfaChakraCode}
                            />
                        </Box>
                        {mfaCodeError.error && (
                            <>
                                <Alert status="error">
                                    <AlertIcon />
                                    <AlertDescription>
                                        <Text>
                                            {mfaCodeError.message ||
                                                'The code you entered is incorrect.'}
                                        </Text>
                                    </AlertDescription>
                                </Alert>

                                <Box className="mb-6" />
                            </>
                        )}
                    </CardBody>

                    <CardFooter pt={0} className="px-[1.25rem]">
                        <Box
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-evenly">
                            <Button
                                variant="outline"
                                bg={darkMode ? 'white' : null}
                                onClick={handleBackButtonClick}
                                data-walkthroughid={ufMfaCancelButton}>
                                Back
                            </Button>
                            <Button
                                type="submit"
                                isDisabled={!hasEnteredCode || isLoading}
                                // onClick={handleSubmitButton}
                                isLoading={isLoading}
                                loadingText="Submitting"
                                data-walkthroughid={ufMfaNextButton}>
                                Next
                            </Button>
                        </Box>
                    </CardFooter>
                </Box>
            </Card>
        </Box>
    );
}
