//internal imports
import store from '../store/store';

import hash from 'object-hash';
/* phone number management */

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

//TODO: create helper function folder
function getTimeZone() {
    if (!store.getState().settings.timeZone) {
        //only use timeZone once
        return {
            timeZone: {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h12',
                timeZone: 'UTC',
                second: 'numeric',
                timeZoneName: 'short',
                hour12: undefined,
            },
            language: 'en-AU',
        };
    }
    const { language, ...rest } = store.getState().settings.timeZone;

    return {
        timeZone: {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC',
            second: 'numeric',
            timeZoneName: 'short',
            ...rest,

            hour12: undefined,
        },
        language: language ?? 'en-AU',
    };
}

//render phone number in a table
export const renderTelephoneNumber = (rowData, fieldName) => {
    if (rowData[fieldName]?.length > 0 && Number(rowData[fieldName])) {
        return '+' + rowData[fieldName];
    }

    return rowData[fieldName];
};

/* date on table view management */

export const dateStringToDate = (dateString, format) => {
    if (!dateString || dateString === '0001-01-01T00:00:00') {
        return '';
    }
    const finalDateString =
        dateString[dateString.length - 1] === 'Z'
            ? dateString
            : dateString + 'Z';
    const date = new Date(finalDateString);
    const dateTimeFormatter = new Intl.DateTimeFormat(
        getTimeZone().language ?? 'en-AU',
        {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC',
            second: 'numeric',

            timeZoneName: 'short',
            ...getTimeZone().timeZone,

            hour12: undefined,
        },
    );

    const dateFormatter = new Intl.DateTimeFormat(
        getTimeZone().language ?? 'en-AU',
        {
            weekday: getTimeZone().timeZone.weekday ?? 'short',
            year: getTimeZone().timeZone.year ?? 'numeric',
            month: getTimeZone().timeZone.month ?? 'short',
            day: getTimeZone().timeZone.day ?? 'numeric',
            timeZone: getTimeZone().timeZone.timeZone ?? 'UTC',
            timeZoneName: getTimeZone().timeZone.timeZoneName ?? 'short',
        },
    );
    const timeFormatter = new Intl.DateTimeFormat(
        getTimeZone().language ?? 'en-AU',
        {
            hour: getTimeZone().timeZone.hour ?? 'numeric',
            minute: getTimeZone().timeZone.minute ?? 'numeric',
            hourCycle: getTimeZone().timeZone.hourCycle ?? 'h12',
            hour12: undefined,
        },
    );
    //TODO: refactor switch case
    switch (format) {
        case 'date':
            return dateFormatter.format(date);
        case 'time':
            return timeFormatter.format(date);
        case 'datetime':
            return dateTimeFormatter.format(date);
        default:
            return dateTimeFormatter.format(date);
    }
};

export const stringCompareIgnoreCase = (l, r) => {
    return String(l ?? '').localeCompare(r ?? '', 'en', {
        sensitivity: 'accent',
    });
};

export const extractFilename = (value) => {
    //valid input 1: "attachment; filename="CDR_PingCo Test3_.csv"; filename*=UTF-8''CDR_PingCo%20Test3_.csv"
    //valid input 2: "attachment; filename=Invoice_Cygnett_INV-T300852.csv; filename*=UTF-8''Invoice_Cygnett_INV-T300852.csv"

    //TODO: Refactor with Regex
    const contentDispositionValues = value.split(';');
    const fileName = contentDispositionValues
        .find((v) => v.includes('filename=')) //assume you always get "filename="
        .replace('filename=', ''); //remove any prepends

    if (fileName.includes('"')) {
        const start = fileName.indexOf('"');
        const end = fileName.lastIndexOf('"');

        return fileName.substring(start + 1, end);
    }
    return fileName.trim();
};

//taken from https://github.com/mbrn/material-table/blob/v1.65.0/src/utils/index.js
export const byString = (o, s) => {
    //expands 'a.b.c' nested json
    if (!s) {
        return;
    }

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var x = a[i];
        if (o && x in o) {
            o = o[x];
        } else {
            return;
        }
    }
    return o;
};

//TODO: refaactor this
//converts camel case to readable string
export const addSpacing = (string) => {
    const newString = string
        ?.replace(/(_|-)/g, ' ')
        .trim()
        .replace(/\w\S*/g, function (str) {
            return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return newString;
};

//hashes page to see if fields have been changed
export const hashObject = (item) => {
    const newObject = { ...item };
    return hash(newObject);
};

//random password string, length is in multiple of 4.
export const generateRandomString = (length = 3) => {
    var lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    var result = '';
    var numbers = '0123456789';
    var upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var special = '!@#$%^&*()_+-={}[]|,.?/;:';
    var i;
    for (i = 0; i < length; i++) {
        result += lowerCase.charAt(
            Math.floor(Math.random() * lowerCase.length),
        );
        result += upperCase.charAt(
            Math.floor(Math.random() * upperCase.length),
        );
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        result += special.charAt(Math.floor(Math.random() * special.length));
    }
    return result;
};

export const removeDuplicates = (arr) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
};

// download CSV
export const downloadCSV = (csvTemplate, filename) => {
    const csvFile = new Blob([csvTemplate], { type: 'text/csv' });
    const downloadLink = window.document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
};

export const multiSelectDataMapper = (v) => ({
    ...v,
    label: v.name,
    value: v.id,
});

/* used to make query params to BE
 * The array is needed to be appended to the end of the api call as a query string
 * This function takes a array, splits it up and seperates it by commmas for correct query structure
 */
export const query = (operatorArray) => {
    let result = '';
    if (
        operatorArray !== undefined ||
        operatorArray !== null ||
        operatorArray !== ''
    ) {
        operatorArray.forEach((value) => {
            if (operatorArray[0].id === value.id) {
                result = result + value.id;
            } else {
                result = `${result},${value.id}`;
            }
        });
    }
    return result;
};

export const toSentenceCase = (string) => {
    return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
};

export const filterUniqueItemList = (list1 = [], list2 = [], key) => {
    if (list1.length > list2.length) {
        return list1.filter((item) => {
            return !list2.some((item2) => item2[key] === item[key]);
        });
    } else {
        return list2.filter((item) => {
            return !list1.some((item1) => item1[key] === item[key]);
        });
    }
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
