import { Flex } from '@chakra-ui/react';

function ImportWrapper({ children }) {
    return (
        <Flex
            className="h-[calc(100vh-172px)]"
            flexDir="column"
            justifyContent="center"
            alignItems="center">
            {children}
        </Flex>
    );
}

export default ImportWrapper;
