/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react';
import { EditRounded } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getUsers } from '@/API/Users/getUsers';
import useTableToolbar from 'hooks/useTableToolbar';
import useUsers from 'hooks/useUsers';

import { nullError } from '@/components/error';
import { Error } from '@/components/index';
import { loadingColumnsConstructor } from '@/components/tables/ReduxTable';
import { Alert, AlertIcon } from '@/components/v4';
import MaterialTable, { RefreshIcon } from '@/components/v4/MaterialTable';
import {
    AlphabetSelect,
    ChipHeader,
    TableHeader,
    TroubleshootModal,
    newTableMap,
    userDefaultColumns,
    userSynced,
} from '@/pages/accounts/resourceAccounts/table';

import config from '@/config.json';
import walkthroughIds from '@/pages/accounts/users/walkthroughIds';

export default function Users() {
    const tableRef = useRef();
    const dispatch = useDispatch();
    const { pathname } = useLocation;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tableData, setTableData] = useState({
        columns: userDefaultColumns,
        data: [],
    });
    const [hasNoUsers, setHasNoUsers] = useState(false);
    const { currentCompany } = useSelector((state) => state.navigationLists);
    const rowEditable = (rowData) =>
        !(rowData?.status !== 0 && rowData?.status !== 20);

    const {
        data,
        isLoading,
        refetch,
        isError,
        error,
        isRefetching,
        isFetching,
    } = useQuery({
        queryKey: ['users', currentCompany],
        queryFn: async () => {
            const data = await getUsers(currentCompany);
            return data;
        },
        onSuccess: async (data) => {
            const newTable = newTableMap(data);
            if (!data.length) {
                await userSynced(currentCompany, setHasNoUsers);
            }

            setTableData((prev) => ({
                ...prev,
                data: newTable,
            }));
            dispatch({
                type: 'CHANGE_TABLE_LOADING',
            });
        },
        refetchOnWindowFocus: false,
    });

    const { handleEditBtn } = useUsers();

    const {
        alphabet,
        alphabetOpen,
        setAlphabetOpen,
        alphabetFilter,
        refreshFn,
    } = useTableToolbar({
        data,
        defaultColumns: userDefaultColumns,
        refetch,
        setTableData,
    });

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(userDefaultColumns),
        [userDefaultColumns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;
    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    const componentLoadingState = isLoading || isRefetching;
    const chipHeaderStatus = componentLoadingState ? 'loading' : null;
    const renderedColumns = componentLoadingState
        ? loadingColumns
        : userDefaultColumns;
    const renderedTableData = componentLoadingState
        ? dummyTable
        : tableData.data;
    const tableActions = [
        {
            icon: RefreshIcon,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: () => {
                tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                        tableRef.current.onFilterChange(item.tableData.id, '');
                    });
                refreshFn();
            },
            hidden: !refreshFn,
        },
        {
            icon: () => (
                <AlphabetSelect
                    alphabet={alphabet}
                    alphabetOpen={alphabetOpen}
                    alphabetFilter={alphabetFilter}
                />
            ),
            position: 'toolbar',
            onClick: () => {
                setAlphabetOpen(!alphabetOpen);
            },
        },
        (rowData) =>
            rowEditable(rowData) && {
                icon: () => (
                    <EditRounded
                        fontSize="small"
                        data-walkthroughid={`${walkthroughIds.ufEditBtn}/${rowData?.tableData.id}`}
                    />
                ),
                tooltip: 'Edit User',
                isFreeAction: false,
                onClick: (e, rowData) => {
                    handleEditBtn(rowData);
                },
            },
    ];

    useEffect(() => {
        setHasNoUsers(false);
    }, [pathname]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.dataManager.columns.forEach((item) => {
                tableRef.current.onFilterChange(item.tableData.id, '');
            });
        }
    }, []);

    useEffect(() => {
        if (!(isFetching || isRefetching)) {
            refetch();
        }
    }, []);

    if (!currentCompany || isError) {
        return <Error error={nullError || error} />;
    }

    return (
        <>
            {hasNoUsers && (
                <Alert status="info" sx={{ mb: 4 }}>
                    <AlertIcon />
                    No services syncing users
                </Alert>
            )}
            <ChipHeader status={chipHeaderStatus} data={data} />
            <h1 className="mb-2 mt-1 text-4xl font-medium">Users</h1>
            <TroubleshootModal onClose={onClose} isOpen={isOpen} />
            <MaterialTable
                tableRef={tableRef}
                title={<TableHeader onClick={onOpen} />}
                columns={renderedColumns}
                data={renderedTableData}
                actions={tableActions}
                options={{
                    exportFileName: 'users',
                    maxBodyHeight:
                        window.innerHeight * config.plainView.tableHeight,
                }}
            />
        </>
    );
}
