export default {
    companies: {
        admin: {
            trialExpiryDatePicker: 'companies/admin/input/1',
            ufConvertButton: 'companies/admin/button/1',
            mfaSelect: 'companies/admin/select/1',
            ufDeleteButton: 'companies/admin/button/2',
            trialOnHoldCheckbox: 'companies/admin/input/2',
        },
        general: {
            ufFriendlyNameInput: '/companies/general/input/friendlyName',
            ufLegalNameInput: '/companies/general/input/legalName',
            ufCompanyNameInput: '/companies/general/input/companyName',
            ufActiveCheckbox: '/companies/general/checkbox/active',
            ufDefaultPlanSelect: '/companies/general/select/defaultPlan',
            ufDefaultAddressSelect: '/companies/general/select/defaultAddress',
            ufSelectiveSyncSelect: '/companies/general/select/selectiveSync',
            ufDefaultTrunkSelect: '/companies/general/select/defaultTrunk',
            ufEngineLocationsSelect:
                '/companies/general/select/engineLocations',
            ufCompanyRegistrationNumberInput:
                '/companies/general/input/companyRegistrationsNumber',
            ufPrimaryContactNameInput:
                '/companies/general/input/primaryContactName',
            ufPrimaryContactPhoneInput:
                '/companies/general/input/primaryContactPhone',
            ufPrimaryContactEmailInput:
                '/companies/general/input/primaryContactEmail',
            ufAccountsContactEmailInput:
                '/companies/general/input/accountsContactEmail',
            ufSupportContactEmailInput:
                '/companies/general/input/supportContactEmail',
            ufCreateButton: '/companies/general/button/create',
            ufCreateAsTrialButton: '/companies/general/button/createAsTrial',
            ufDeleteButton: '/companies/general/button/delete',
            ufEditButton: '/companies/table/button/edit',
            ufApproveButton: '/companies/table/button/approve',
        },
        billing: {
            ufPlansSelect: '/companies/billing/select/plans',
            ufDefaultPlanSelect: '/companies/billing/select/defaultPlan',
            ufDefaultPartnerPlanSelect:
                '/companies/billing/select/defaultPartnerPlan',
            ufBillToSelect: '/companies/billing/select/billTo',
            ufBillingDayInput: '/companies/billing/input/billingDay',
            ufChangeBillingDayButton:
                '/companies/billing/button/changeBillingDay',
            ufDefaultCompaniesBillingDaySelect:
                '/companies/billing/select/defaultCompaniesBillingDay',
            ufLeadInProRataCheckbox:
                '/companies/billing/checkbox/leadInProrata',
            ufProcessBillingCheckbox:
                '/companies/billing/checkbox/processBilling',
            ufOmitNumberBlocksCheckbox:
                '/companies/billing/checkbox/omitNumberBlocks',
            ufAlwaysBillGlobalNumberBlocksCheckbox:
                '/companies/billing/checkbox/alwaysBillGlobalNumberBlocks',
            ufSurchargeInput: '/companies/billing/input/surcharge',
            ufAllowPlansPerUserSelect:
                '/companies/billing/select/allowPlansPerUser',
            ufTrackingName2: '/companies/billing/input/trackingName2',
            ufTrackingOption2: '/companies/billing/input/trackingOption2',
            ufContractedMinUserCount:
                '/companies/billing/input/contractedMinUserCount',
            ufContractedMinResourceAccountCount:
                '/companies/billing/input/contractedMinResourceAccountCount',
            ufSingleItemInvoiceCheckbox:
                '/companies/billing/checkbox/singleItemInvoice',
            ufCdrDetailsToggle: '/companies/billing/toggle/cdrDetails',
            ufRgsDetailsToggle: '/companies/billing/toggle/rgsDetails',
            ufCompanyDetailsToggle: '/companies/billing/toggle/companyDetails',
            ufZeroItemsToggle: '/companies/billing/toggle/zeroItems',
            ufUserDetailsToggle: '/companies/billing/toggle/userDetails',
            ufTrunkDetailsToggle: '/companies/billing/toggle/trunkDetails',
            ufNumberBlockDetailsToggle:
                '/companies/billing/toggle/numberBlockDetails',
            ufBillingNotesModalOpenButton:
                '/companies/billing/button/billingNotesModal',
            modal: {
                ufBillingNoteInput:
                    '/companies/billing/modal/input/billingNote',
                ufRequiredScopeSelect:
                    '/companies/billing/modal/select/requiredScope',
                ufIsActiveSelect: '/companies/billing/modal/select/isActive',
                ufNoteLocationSelect:
                    '/companies/billing/modal/select/noteLocation',
                ufAddNoteButton: '/companies/billing/modal/button/addNote',
            },
            ufInvoiceFormatAccordion:
                '/companies/billing/accordion/invoiceFormat',
            ufInvoiceFormatResetButton:
                '/companies/billing/button/invoiceFormatReset',
            ufRestoreDefaultInvoiceFormatButton:
                '/companies/billing/modal/button/restoreDefaultInvoiceFormat',
            ufRestoreDefaultInvoiceCancelButton:
                '/companies/billing/modal/button/restoreDefaultInvoiceCancel',
            ufAccordionSelect: '/companies/billing/accordion/select',
            ufAccordionInput: '/companies/billing/accordion/input',
        },
    },
    partners: {
        ufPartnerTab: '/partners/tab/',
        ufDeleteBtn: '/partners/button/delete',
        general: {
            ufPartnerNameInput: '/partners/general/input/partnerName',
            ufHostHeaderInput: '/partners/general/input/hostHeader',
            ufDefaultPlanSelect: '/partners/general/select/defaultPlan',
            ufFriendlyNameInput: '/partners/general/input/friendlyName',
            ufLegalNameInput: '/partners/general/input/legalName',
            ufCompanyRegNumberInput: '/partners/general/input/companyRegNumber',
            ufPrimaryContactNameInput:
                '/partners/general/input/primaryContactName',
            ufPrimaryContactEmailInput:
                '/partners/general/input/primaryContactEmail',
            ufPrimaryContactPhoneInput:
                '/partners/general/input/primaryContactPhone',
            ufAccountsContactEmailInput:
                '/partners/general/input/accountsContactEmail',
            ufSupportContactEmailInput:
                '/partners/general/input/supportContactEmail',
        },
        branding: {
            ufBrandingTab: '/partners/branding/tab',
            ufLogoUploadButton: '/partners/branding/button/logoUpload',
            ufBackgroundTypeSelect: '/partners/branding/select/backgroundType',
            ufFakeLoginScreen: '/partners/branding/screen/fakeLoginScreen',
            ufSupportLinkInput: '/partners/branding/links/input/supportLink',
            ufSupportLinkSelect: '/partners/branding/links/select/supportLink',
            ufRegTcInput: '/partners/branding/links/input/regTc',
            ufPrivacyPolicyInput:
                '/partners/branding/links/input/privacyPolicy',
            ufBackButton: '/partners/branding/button/back',
            ufSaveButton: '/partners/branding/button/save',
        },
    },
    addresses: {
        ufEditButton: '/addresses/table/button/edit',
        ufAddButton: '/addresses/table/button/add',
        ufSearchAddress: '/addresses/input/searchAddress',
        ufDescriptionInput: '/addresses/input/description',
        ufBuildingTypeInput: '/addresses/input/buildingType',
        ufBuildingNumberInput: '/addresses/input/buildingNumber',
        ufBuildingSuffixInput: '/addresses/input/buildingSuffix',
        ufBuildingFloorTypeInput: '/addresses/input/buildingFloorType',
        ufBuildingFloorNumInput: '/addresses/input/buildingFloorNumber',
        ufBuildingFloorNumSuffixInput:
            '/address/input/buildingFloorNumberSuffix',
        ufBuildingPropertyInput: '/addresses/input/buildingProperty',
        ufBuildingLocationInput: '/addresses/input/buildingLocation',
        ufStreetHouseNumInput: '/addresses/input/streetHouseNum',
        ufStreetHouseNumSuffixInput: '/addresses/input/streetHouseNumSuffix',
        ufStreetNameInput: '/addresses/input/streetName',
        ufStreetTypeInput: '/addresses/input/streetType',
        ufStreetSuffixInput: '/addresses/input/streetSuffix',
        ufCityLocalityInput: '/addresses/input/cityLocality',
        ufDistrictInput: '/addresses/input/district',
        ufStateInput: '/addresses/input/state',
        ufZipCodeInput: '/addresses/input/zipCode',
        ufCountryInput: '/addresses/input/country',
        ufBackButton: '/addresses/button/back',
        ufSaveButton: '/addresses/button/save',
    },
    custom: {
        ufCustomAttribute: 'tab/custom/input/customAttribute',
    },
    approval: {
        ufApproveButton: 'modal/button/approve',
        ufApproveAsTrialButton: 'modal/butotn/approveAsTrial',
    },
};
