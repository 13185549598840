import { CloseIcon } from '@chakra-ui/icons';
import { Button, IconButton, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import {
    Alert,
    AlertIcon,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
} from 'components/v4';
import ImportWrapper from './ImportWrapper';

function ImportError({ restError, onClick }) {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <ImportWrapper>
            <Card
                minW={300}
                alignItems="center"
                bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                <CardHeader>
                    <Text
                        className="font-medium"
                        textAlign="center"
                        color="red.500">
                        Activation Failed
                    </Text>
                </CardHeader>
                <CardBody py={0} textAlign="center">
                    <IconButton
                        isRound={true}
                        variant="solid"
                        colorScheme="red"
                        aria-label="Done"
                        fontSize="20px"
                        mb={restError ? 5 : 2}
                        icon={<CloseIcon />}
                        _focus={{
                            boxShadow: 'none',
                        }}
                        _hover={{
                            cursor: 'unset',
                        }}
                    />

                    {restError && (
                        <Alert status="error">
                            <AlertIcon />
                            <Text className="font-medium" color="red.500">
                                {restError}
                            </Text>
                        </Alert>
                    )}
                </CardBody>
                <CardFooter justifyContent="center">
                    <Button onClick={onClick}>Start Over</Button>
                </CardFooter>
            </Card>
        </ImportWrapper>
    );
}

export default ImportError;
