function TrashIcon() {
    return (
        <span
            className="relative m-auto inline-block h-3 w-[13px] rounded-b-[3px] bg-brand-500"
            aria-label="trash can">
            <span
                className="absolute -left-[0.1rem] -right-0.5 -top-1 h-[3px] origin-[19%_100%] rotate-0 transform rounded-t-lg bg-brand-500 transition-transform duration-[250ms] after:absolute after:-top-0.5 after:left-[0.3rem] after:h-0.5 after:w-1.5 after:origin-[19%_100%] after:rotate-0 after:rounded-t-lg after:bg-brand-500 after:transition-transform after:duration-[250ms] after:content-[''] group-hover:-rotate-45 group-hover:transition-transform group-hover:duration-[250ms] group-disabled:-rotate-0 "
                aria-label="trash lid"
            />
            <i
                aria-label="trash stripes"
                className="relative mx-auto my-[2px] block h-2 w-[0.8px] bg-white before:absolute before:-right-[3px] before:h-2 before:w-[0.5px] before:bg-white before:content-[''] after:absolute after:-left-[3.5px] after:h-2 after:w-[0.5px] after:bg-white after:content-['']"
            />
        </span>
    );
}

export default TrashIcon;
