import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ChakraButtonProps
 * @property {import('react').ReactNode} children
 * @param {ChakraButtonProps & import('@chakra-ui/react').ButtonProps} props
 * @param {import('@chakra-ui/react').ButtonProps} props.rest
 * @returns
 */
export default function ChakraButton({ children, ...props }) {
    const { darkMode, mixedMode, colorScheme } = useSelector(
        (state) => state.settings,
    );
    const isSecondary =
        props.className?.includes('secondary') || props.variant === 'outline';
    const isGrayScheme = colorScheme === 'gray';
    let bgColor;
    let hover = {};

    // primary button
    if (!isSecondary && props.variant !== 'link') {
        hover = {
            ...hover,
            bg: `${colorScheme}.600`,
        };
    } else if (props.variant === 'link') {
        hover = {
            ...hover,
            color: `${colorScheme}.600`,
            textDecoration: 'underline',
        };
    } else {
        // secondary button
        hover = {
            ...hover,
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.600`,
            opacity: isGrayScheme ? '0.8' : null,
        };
    }

    if (props && isSecondary) {
        bgColor = darkMode || mixedMode ? 'white' : 'transparent';
    } else if (colorScheme === 'gray') {
        bgColor = `gray.${darkMode || mixedMode ? '500' : '300'}`;
    } else {
        bgColor = null;
    }
    const color = isSecondary
        ? isGrayScheme
            ? 'gray.600'
            : `${colorScheme}.600`
        : 'white';
    return (
        <Button
            colorScheme={colorScheme}
            // _hover={hover}
            bg={bgColor}
            // color={color}
            {...props}>
            {children}
        </Button>
    );
}
