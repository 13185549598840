import React, { useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import config from '../../../config';
import {MultiChild, TabView} from '../../../components';
import {ProfilesAdd, ProfilesEdit, ProfilesAdmin} from '../index';
import Consent from './consent';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

/*
 * Base page for operator profiles in the general admin tab
 * Presents the Table and provides endpoints for the various props for TableAndModal
 */
const OperatorProfiles = (props) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    // columns for the table
    const columns = [
        {
            title: 'Operator Name',
            field: 'name',
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
        },
        {
            title: 'App ID',
            field: 'appID',
        },
        {
            title: 'Tenant ID',
            field: 'tenantID',
        },
        {
            title: 'Last Sync',
            field: 'lastSync',
            type: 'datetime',
        },
    ];
    const tableActions = [
        {
            icon: ({disabled}) => {
                if (disabled) {
                    return;
                }
                return <ListRoundedIcon fontSize="small" />;
            },
            tooltip: 'View Operator Consents Menu',
            position: 'row',
            onClick: (event, rowData) => {
                dispatch({
                    type: 'OPEN_CHILD_TABLE',
                    payload: rowData,
                });
                dispatch({
                    type: 'CHANGE_BREADCRUMB_VIEW',
                    payload: 1,
                });
                dispatch({
                    type: 'RESET_MODAL',
                });
            },
        },
    ];
    // modal state access
    const {
        data: modalData,
        mode: modalMode,
        state: modalState,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    // current wholesaler from state
    const {currentWholesaler} = useSelector((state) => {
        return {...state.navigationLists};
    });

    /*
     * Table and Modal presentation and endpoints for opereator profiles
     * Requires validation as currently the modalValidate prop just checks that all fields have been seleceted
     */
    return (
        <>
            <MultiChild
                tableRef={tableRef}
                style={{height: '100%'}}
                newPage
                tabs={[
                    {details: true},
                    {
                        component: <Consent />,
                        label: 'Consents',
                    },
                ]}
                tableFetch={'/operatorconnectoperatorprofiles'}
                modalFetch={'/operatorconnectoperatorprofile/' + modalData?.id}
                modalAdd={'/operatorconnectoperatorprofile'}
                modalUpdate={'/operatorconnectoperatorprofile'}
                modalValidate={() => {
                    const newErrorState = {
                        name: !modalState?.name && 'Name is required',
                        carrierName:
                            !modalState?.carrierName &&
                            'Carrier Name is required',
                        appID: !modalState?.appID && 'App ID is required',
                        tenantID:
                            !modalState?.tenantID && 'Tenant ID is required',
                        regions:
                            !modalState?.regions && 'Region must be selected',
                    };

                    dispatch({
                        type: 'CHANGE_MODAL_STATE_ERROR',
                        payload: newErrorState,
                    });

                    return !(
                        newErrorState.carrierName ||
                        newErrorState.appID ||
                        newErrorState.tenantID ||
                        newErrorState.regions ||
                        newErrorState.name
                    );
                }}
                modalInitState={{
                    wholesalerID: currentWholesaler,
                }}
                modalUpload={(freshData, state) => {
                    return {
                        ...freshData,
                        ...state,
                    };
                }}
                add
                modalTitle={
                    modalData
                        ? modalMode + ' Operator Profiles -> '
                        : modalMode + ' Operator Profiles'
                }
                title="Operator Profiles"
                defaultColumns={columns}
                groupable
                filterable
                rowActions={{
                    multiChildTable: {
                        name: 'Edit',
                        to: 0,
                    },
                }}
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
                prependAdditionalActions={tableActions}
                // Renders the modal content as a veiw of tabs, Add page when mode == Add and Edit + Admin tabs otherwise
                modalContent={
                    <TabView
                        centered
                        tabLabels={
                            modalMode === 'Add'
                                ? ['General']
                                : ['General', 'Admin']
                        }
                        contents={
                            modalMode === 'Add'
                                ? [<ProfilesAdd />]
                                : [<ProfilesEdit />, <ProfilesAdmin />]
                        }
                        insideModal={true}
                    />
                }
            />
        </>
    );
};

export default OperatorProfiles;
