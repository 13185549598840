import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TableAndModal } from '@/components';
import { onModalError } from '@/components/tables/TableAndModal';
import config from '@/config.json';
import { axios } from '@/services/axios';
import { generateRandomString } from '@/utils/utils';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
    ChakraProvider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useDeepMerge } from 'hooks/useDeepMerge';
import { msalConfig } from 'pages/auth/new/sso/config/authConfig';
import { Button } from 'react-bootstrap';
import AdminsCreate from './new/AdminsCreate';
import walkthroughIds from './walkthroughIds';

import {
    customTabStylesCombineTheme,
    selectedTabStylesCombineTheme,
} from '@/constants';
import APIUsers from './apiUsers';
import PasswordPolicy from './passwordPolicy/PasswordPolicy';
import SSO from './sso/SSO';

const PERMISSION_ERROR = {
    name: 'Permission is empty',
    message: 'Permission field is required.',
};
const MFA_ENABLED_QUERY = '?action=mfaEnabled';
const MFA_DISABLED_QUERY = '?action=mfaDisabled';

const pca = new PublicClientApplication(msalConfig);
// Component for Table of the 'Admins' Page in the sidebar
const Admins = () => {
    const dispatch = useDispatch();
    const { theme } = useDeepMerge();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const tableRef = useRef();
    const [formState, setFormState] = useState('idle');

    const [tabIndex, setTabIndex] = useState(
        state?.index != null ? state.index : 0,
    );

    // state access
    const {
        data: modalData,
        state: modalState,
        mode: modalMode,
    } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const isCurrentUserMfaEnabled = useSelector(
        (state) => state.login.isCurrentUserMfaEnabled,
    );

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => state.settings);

    // default columns for the Table
    const defaultColumns = [
        {
            title: 'Email',
            field: 'email',
            defaultSort: 'asc',
        },

        {
            title: 'First Name',
            field: 'firstName',
        },
        {
            title: 'Last Name',
            field: 'lastName',
        },
        {
            title: 'Permission',
            field: 'permissionSet',
        },
        {
            title: 'Last Logged In',
            field: 'lastLogin',
            type: 'datetime',
        },
        {
            title: 'Active',
            field: 'isActive',
            width: '5%',
            type: 'boolean',
        },
    ];

    const editAdminNavigate = (data) => {
        navigate(`${data.id}/edit`, { state: data });
    };

    useEffect(() => {
        if (formState === 'success') {
            toast.success('Account Create Success!', {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
        if (formState === 'error') {
            toast.error('Account Create Error!', {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    }, [formState]);

    useEffect(() => {
        if (state?.index != null) {
            setTabIndex(state.index);
            return;
        }
        // Flag myself when hitting the admin page
        // make note of whether MFA is enabled,
        // so when we get a 200 from PUTing to admin,
        // we know to logout this admin user or not
        axios.get(`/adminuser/me`).then((response) => {
            dispatch({
                type: 'SET_IS_CURRENT_USER_MFA_ENABLED',
                payload: response.data.mfaEnabled,
            });
        });
    }, [state]);

    const handleSuccess = () => {
        setFormState('success');
        dispatch({ type: 'RESET_MODAL' });
        dispatch({ type: 'RESET_TABLE' });
    };

    const handleAddAdmin = async () => {
        // check company sso enforced
        const sso = await axios.get(`/sso/${currentCompany}`);
        axios
            .post(config.adminUsers.modalAdd, {
                ...modalState,
                companyID: currentCompany,
            })
            .then((res) => {
                // not enforced, send reset password
                if (!sso.data?.isEnforced) {
                    const host = window.location.host;
                    const endpoint = `/forgotpassword/`;
                    axios
                        .post(endpoint, {
                            username: modalState.email,
                            host: `${host}/ui/change-password?token=[TOKEN]`,
                        })
                        .then((res) => {
                            handleSuccess();
                        })
                        .catch((e) => {
                            setFormState('error');
                            onModalError(e);
                        });
                } else {
                    handleSuccess();
                }
            })
            .catch((e) => {
                onModalError(e);
            })
            .finally(() => setFormState('idle'));
    };

    // Add, Edit and A Admin page are rendered as children of this TableAndModal
    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <h1 className="mb-2 text-[2.5rem] font-medium">
                {tabIndex == 2
                    ? config.apiUsers.tableTitle
                    : config.adminUsers.tableTitle}
            </h1>

            <Tabs
                index={tabIndex}
                isLazy
                onChange={(index) => {
                    if (index == 0 || index == 2) {
                        dispatch({
                            type: 'RESET_MODAL',
                        });
                        dispatch({
                            type: 'RESET_TABLE',
                        });
                    }
                    setTabIndex(index);
                }}>
                <TabList borderBottom={'none'} mb={2}>
                    {config.adminUsers.tabsList.map((tab, index) => (
                        <Tab
                            data-walkthroughid={
                                walkthroughIds.ufTabButton + tab
                            }
                            key={index}
                            onMouseDown={(e) =>
                                e.button === 2 && e.preventDefault()
                            }
                            sx={{
                                ...customTabStylesCombineTheme,
                                color: darkMode
                                    ? 'rgba(255, 255, 255)'
                                    : 'inherit',
                            }}
                            _selected={{
                                ...selectedTabStylesCombineTheme,
                                // textColor: darkMode
                                //     ? 'rgba(255, 255, 255)'
                                //     : 'inherit',
                            }}>
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    <TabPanel padding={0}>
                        {tabIndex == 0 && (
                            <TableAndModal
                                tableRef={tableRef}
                                onModalSucceed={(result) => {
                                    axios
                                        .get(`/adminuser/me`)
                                        .then((response) => {
                                            if (
                                                result.data.username ===
                                                response.data.username
                                            ) {
                                                // Check if `this` admin user has mfa on/off
                                                // Then from the response, we can tell if the user just
                                                // switched ON mfa, that way we can log them out
                                                if (
                                                    isCurrentUserMfaEnabled !==
                                                    response.data.mfaEnabled
                                                ) {
                                                    const logoutAction =
                                                        response.data.mfaEnabled
                                                            ? MFA_ENABLED_QUERY
                                                            : MFA_DISABLED_QUERY;
                                                    navigate(logoutAction);
                                                    sessionStorage.clear();
                                                    localStorage.clear();
                                                    dispatch({
                                                        type: 'CLEAR_TOKEN',
                                                    });
                                                    dispatch({
                                                        type: 'LOGOUT',
                                                    });
                                                    return;
                                                }
                                            }
                                            dispatch({
                                                type: 'RESET_MODAL',
                                            });
                                            dispatch({
                                                type: 'RESET_TABLE',
                                            });
                                        });
                                }}
                                newPage
                                tableFetch={
                                    config.adminUsers.tableFetch +
                                    currentCompany
                                }
                                modalFetch={
                                    config.adminUsers.modalFetch + modalData?.id
                                }
                                overrideModalAdd={async () => handleAddAdmin()}
                                modalUpdate={config.adminUsers.modalUpdate}
                                modalDelete={`/adminuser/${modalData?.id}`}
                                validation={(freshData, state) => {
                                    if (!state?.permissionSetID) {
                                        dispatch({
                                            type: 'CHANGE_MODAL_BACKEND_ERROR',
                                            payload: PERMISSION_ERROR,
                                        });
                                        return false;
                                    }
                                    return true;
                                }}
                                modalUpload={(freshData, state) => {
                                    if (!state?.permissionSetID) {
                                        dispatch({
                                            type: 'CHANGE_MODAL_BACKEND_ERROR',
                                            payload: PERMISSION_ERROR,
                                        });
                                        return;
                                    }
                                    return {
                                        ...freshData,
                                        ...state,
                                        companyID: currentCompany,
                                    };
                                }}
                                defaultColumns={defaultColumns}
                                titleProps={
                                    <Button
                                        className="btn btn-primary btn-sm"
                                        data-walkthroughid={
                                            walkthroughIds.ufAddAdminUser
                                        }
                                        style={{ marginBottom: '1%' }}
                                        onClick={() => {
                                            dispatch({
                                                type: 'OPEN_MODAL',
                                                payload: {
                                                    state: {
                                                        isActive: true,
                                                        password:
                                                            generateRandomString(),
                                                    },
                                                    loading: false,
                                                    mode: 'Add',
                                                    hash: '',
                                                },
                                            });
                                            dispatch({
                                                type: 'CHANGE_MODAL_HASH',
                                            });
                                        }}>
                                        {' '}
                                        <AddRoundedIcon
                                            fontSize="small"
                                            style={{
                                                marginBottom: '2%',
                                            }}
                                        />{' '}
                                        Admin User
                                    </Button>
                                }
                                title={config.adminUsers.tableTitle}
                                noTitle
                                additionalActions={[
                                    (rowData) => ({
                                        icon: () => (
                                            <EditRoundedIcon
                                                fontSize="small"
                                                data-walkthroughid={`${walkthroughIds.ufTableEditBtn}/${rowData?.firstName}`}
                                            />
                                        ),
                                        tooltip: 'Edit Admin User',
                                        position: 'row',
                                        onClick: () => {
                                            editAdminNavigate(rowData);
                                        },
                                    }),
                                ]}
                                groupable
                                filterable
                                modalValidate={() => {
                                    const newErrorState = {
                                        firstName:
                                            (!modalState?.firstName ||
                                                modalState?.firstName?.length <
                                                    1) &&
                                            'Field is required.',
                                        permission:
                                            !modalState?.permissionSetID &&
                                            'Field is required.',
                                        email:
                                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                                                modalState?.email,
                                            ) && 'Invalid email address.',
                                        password:
                                            modalMode === 'Add' //adding new admin needs password
                                                ? modalState?.password &&
                                                  modalState?.password
                                                      ?.length >= 8 && //at least 8 char long
                                                  modalState?.password?.match(
                                                      /[A-Z]/,
                                                  ) && //uppercase
                                                  modalState?.password?.match(
                                                      /[a-z]/,
                                                  ) && //lowercase
                                                  modalState?.password?.match(
                                                      /[0-9]/,
                                                  ) && //numbers 0 to 9
                                                  !modalState?.password?.match(
                                                      /^[A-Za-z0-9<>]+$/,
                                                  ) && //symbols excluding < and >
                                                  !modalState?.password?.includes(
                                                      '>',
                                                  ) &&
                                                  !modalState?.password?.includes(
                                                      '<',
                                                  ) //no < or >
                                                    ? null
                                                    : 'Password does not meet the requirement(s).'
                                                : modalState?.password //updating an admin doesnt always need a password.
                                                  ? modalState?.password &&
                                                    modalState?.password
                                                        ?.length >= 8 && //at least 8 char long
                                                    modalState?.password?.match(
                                                        /[A-Z]/,
                                                    ) && //uppercase
                                                    modalState?.password?.match(
                                                        /[a-z]/,
                                                    ) && //lowercase
                                                    modalState?.password?.match(
                                                        /[0-9]/,
                                                    ) && //numbers 0 to 9
                                                    !modalState?.password?.match(
                                                        /^[A-Za-z0-9<>]+$/,
                                                    ) && //symbols excluding < and >
                                                    !modalState?.password?.includes(
                                                        '>',
                                                    ) &&
                                                    !modalState?.password?.includes(
                                                        '<',
                                                    ) //no < or >
                                                      ? null
                                                      : 'Password does not meet the requirement(s).'
                                                  : null,
                                    };
                                    dispatch({
                                        type: 'CHANGE_MODAL_STATE_ERROR',
                                        payload: newErrorState,
                                    });
                                    return !(
                                        newErrorState.firstName ||
                                        newErrorState.email ||
                                        newErrorState.permission ||
                                        newErrorState.password
                                    );
                                }}
                                maxBodyHeight={
                                    window.innerHeight *
                                    config.plainView.tableHeight
                                }
                                modalTitle={
                                    modalData
                                        ? modalMode +
                                          ' Admin Users : ' +
                                          modalData?.firstName +
                                          ' ' +
                                          (modalData?.lastName ?? '')
                                        : modalMode + ' Admin Users'
                                }
                                // NOTE: AdminsEdit is no longer part of modalContent
                                modalContent={<AdminsCreate />}
                            />
                        )}
                    </TabPanel>
                    <TabPanel>
                        <MsalProvider instance={pca}>
                            <SSO />
                        </MsalProvider>
                        <PasswordPolicy />
                    </TabPanel>
                    <TabPanel padding={0}>
                        {tabIndex == 2 && <APIUsers />}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </ChakraProvider>
    );
};

export default Admins;
