import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Tooltip,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon, QuestionIcon } from '@chakra-ui/icons';
import { Select as ChakraSelect } from 'chakra-react-select';

import {
    Button,
    Input,
    Checkbox,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@/components/v4';
import {
    postMfaReset,
    postForgotPassword,
    postBypassMinPasswordAge,
} from '@/API/AdminUser';
import { mfaDropdown, ACTIONS } from './constants';
import { defaultChakraSelectStyle, chakraSelectDarkMode } from '@/constants';
import walkthroughIds from '../walkthroughIds';

const RESET_PASSWORD_FAILURE = 'reset password error';
const BYPASS_MIN_PASSWORD_AGE_FAILURE = 'bypass min password age error';

export default function EditGeneral({
    adminId,
    adminUser,
    setAdminUser,
    permissionSets,
    setResetMfaError,
    setResetMfaSuccess,
    setHasError,
    setFormState,
    ssoEnforced,
    handleCheckboxChange,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const [succeed, setSucceed] = useState(false);
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);
    const [isLoadingBypassMinPasswordAge, setIsLoadingBypassMinPasswordAge] =
        useState(false);
    // Mfa flags to render different views
    const [isEditingThemself, setIsEditingThemself] = useState(false);
    const [isMfaEnabled, setIsMfaEnabled] = useState(true);
    const [isMfaOtpConfirmed, setIsMfaOtpConfirmed] = useState(false);

    const buttonColorSchemeDefault = succeed ? 'green' : 'brand';
    const buttonColorScheme = isLoadingResetPassword
        ? 'brand'
        : buttonColorSchemeDefault;

    const buttonTextDefault = succeed ? 'Reset Link Sent' : 'Reset Password';
    const buttonText = isLoadingResetPassword
        ? 'Please wait...'
        : buttonTextDefault;

    const { ...rest } = useSelector((state) => state.login);
    const { restError } = useSelector((state) => state.modal);
    const { darkMode } = useSelector((state) => state.settings);
    const chakraSelectDark = chakraSelectDarkMode(darkMode);

    const { adminsGeneral } = walkthroughIds;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdminUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleMfaChange = (item) => {
        setAdminUser((prev) => ({
            ...prev,
            mfaEnabled: item.value,
        }));
    };

    const handleSelectChange = (item, name) => {
        setAdminUser((prev) => ({
            ...prev,
            [name]: item.id,
        }));
    };
    // Clears the admin users MFA token, they will need to run the wizard again when logging in
    const onResetMfa = () => {
        postMfaReset(adminId)
            .then((res) => {
                if (res.status === 200) {
                    setHasError(null);
                }
                setResetMfaSuccess(true);
                setResetMfaError(false);
                if (isEditingThemself) {
                    // sessionStorage.clear();
                    // localStorage.clear();
                    dispatch({ type: ACTIONS.LOGOUT });
                    // dispatch({ type: ACTIONS.CLEAR_TOKEN });
                    navigate('?action=mfaTokenReset');
                }
            })
            .catch((err) => {
                setResetMfaSuccess(false);
                setResetMfaError(true);
                setHasError({
                    message:
                        err?.response?.data?.message ||
                        'Login does not have the required access on endpoint ResetMFA.',
                });
                throw new Error(err.message);
            });
    };

    const onResetPassword = () => {
        setIsLoadingResetPassword(true);
        const host =
            import.meta.env.MODE === 'development'
                ? 'teamsdev.tcap.dev'
                : window.location.host;

        postForgotPassword({
            username: adminUser.email,
            host: `${host}/ui/change-password?token=[TOKEN]`,
        })
            .then((res) => {
                if (res.status === 200) {
                    setIsLoadingResetPassword(false);
                    setSucceed(true);
                    setHasError(null);
                }
            })
            .catch((err) => {
                setIsLoadingResetPassword(false);
                setFormState(RESET_PASSWORD_FAILURE);
                setHasError({
                    message:
                        restError ||
                        'Unable to process request. Please contact your administrator.',
                });
                throw new Error(err.message);
            })
            .finally(() => setFormState('idle'));
    };

    const onBypassMinPasswordAge = () => {
        setIsLoadingBypassMinPasswordAge(true);

        postBypassMinPasswordAge(adminUser.id)
            .then((res) => {
                if (res.status === 200) {
                    setIsLoadingBypassMinPasswordAge(false);
                    setHasError(null);
                    onClose();
                    toast({
                        status: 'success',
                        title: 'Bypass Minimum Password Age Success',
                        description: 'Admin User can now reset their password!',
                        duration: 3000,
                    });
                }
            })
            .catch((err) => {
                setIsLoadingBypassMinPasswordAge(false);
                setFormState(BYPASS_MIN_PASSWORD_AGE_FAILURE);
                setHasError({
                    message:
                        err?.response?.data?.message ||
                        'Unable to process request. Please contact your administrator.',
                });
                throw new Error(err.message);
            })
            .finally(() => setFormState('idle'));
    };

    useEffect(() => {
        // Flag approriate MFA related privileges
        setIsEditingThemself(rest?.user === adminUser?.email);
        setIsMfaEnabled(adminUser?.mfaEnabled);
        setIsMfaOtpConfirmed(adminUser?.mfatotpConfirmed);
    }, [adminUser, rest?.user]);

    return (
        <Stack spacing="3">
            <Stack
                spacing="6"
                direction={{
                    base: 'column',
                    md: 'row',
                }}>
                <FormControl isInvalid={!adminUser?.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        type="text"
                        name="firstName"
                        onChange={handleInputChange}
                        value={adminUser.firstName}
                        data-walkthroughid={adminsGeneral.ufFirstName}
                    />
                    <FormErrorMessage>First name is required</FormErrorMessage>
                </FormControl>

                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        type="text"
                        name="lastName"
                        onChange={handleInputChange}
                        value={adminUser.lastName}
                        data-walkthroughid={adminsGeneral.ufLastName}
                    />
                </FormControl>
            </Stack>

            <Stack spacing="6" direction="row">
                <FormControl id="isActive">
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            isChecked={adminUser.isActive}
                            onChange={handleCheckboxChange}
                            colorScheme="whatsapp"
                            name="isActive"
                            mb={0}
                        />
                        <FormLabel mb={0} ml={2}>
                            Active
                        </FormLabel>
                    </Box>
                </FormControl>
            </Stack>
            <FormControl data-walkthroughid={adminsGeneral.ufPermissionSet}>
                <FormLabel isInvalid={Boolean(adminUser?.permissionSetID)}>
                    Permission
                </FormLabel>
                <ChakraSelect
                    value={permissionSets?.find(
                        (item) => item.id === adminUser.permissionSetID,
                    )}
                    options={permissionSets?.sort(function (a, b) {
                        var nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase();
                        if (nameA < nameB)
                            //sort string ascending
                            return -1;
                        if (nameA > nameB) return 1;
                        return 0; //default return value (no sorting)
                    })}
                    name="permissionSetID"
                    onChange={(item) =>
                        handleSelectChange(item, 'permissionSetID')
                    }
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    chakraStyles={{
                        ...defaultChakraSelectStyle,
                        ...chakraSelectDark,
                    }}
                    selectedOptionColor="brand"
                />
                <FormErrorMessage>Field is required</FormErrorMessage>
            </FormControl>

            {/*************/}
            {/* MFA stuff */}
            {/*************/}

            <FormControl data-walkthroughid={adminsGeneral.ufMfaSelect}>
                <FormLabel>MFA</FormLabel>
                <Box
                    sx={{
                        // marginBottom: '10px',
                        display: 'flex',
                        width: 'full',
                        alignItems: 'flex-start',
                    }}>
                    <ChakraSelect
                        className="w-full"
                        name="mfaEnabled"
                        options={mfaDropdown}
                        value={mfaDropdown.find(
                            (item) => item.value === adminUser.mfaEnabled,
                        )}
                        onChange={(item) => handleMfaChange(item)}
                        selectedOptionColor="brand"
                        chakraStyles={{
                            ...defaultChakraSelectStyle,
                            ...chakraSelectDark,
                        }}
                    />

                    {isMfaEnabled && (
                        <Button
                            ml="24px"
                            minW={['100%', '160px']}
                            minH="40px"
                            disabled={!isMfaOtpConfirmed || ssoEnforced}
                            onClick={onResetMfa}
                            data-walkthroughid={adminsGeneral.ufResetMfaButton}>
                            Reset MFA token
                        </Button>
                    )}
                </Box>
            </FormControl>

            {/* Email */}
            <FormControl isInvalid={!adminUser?.email} mb={'5px !important'}>
                <FormLabel>Email</FormLabel>
                <Box
                    sx={{
                        display: 'flex',
                        width: 'full',
                        alignItems: 'flex-start',
                    }}>
                    <Input
                        className="w-full"
                        type="email"
                        value={adminUser.email}
                        name="email"
                        onChange={handleInputChange}
                        data-walkthroughid={adminsGeneral.ufEmail}
                    />

                    <Button
                        data-walkthroughid={adminsGeneral.ufResetPassword}
                        ml="24px"
                        minW={['100%', '160px']}
                        minH="40px"
                        colorScheme={buttonColorScheme}
                        isDisabled={
                            ssoEnforced || isLoadingResetPassword || succeed
                        }
                        isLoading={isLoadingResetPassword}
                        onClick={onResetPassword}
                        leftIcon={succeed ? <CheckCircleIcon /> : undefined}>
                        {buttonText}
                    </Button>
                </Box>
                <FormErrorMessage
                    sx={{
                        width: '100%',
                    }}>
                    Email is required
                </FormErrorMessage>
            </FormControl>

            <Box textAlign="left" className="flex items-center">
                <Button
                    color="brand.500"
                    variant="link"
                    onClick={onOpen}
                    isDisabled={ssoEnforced || isLoadingBypassMinPasswordAge}
                    data-walkthroughid={adminsGeneral.ufBypassMinPasswordAge}>
                    Bypass Minimum Password Age
                </Button>
                <Tooltip
                    label={
                        'Enables users to reset their password whilst ignoring minimum password age requirement.'
                    }
                    hasArrow
                    placement="bottom">
                    <QuestionIcon ml={1} />
                </Tooltip>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bypass Minimum Password Age</ModalHeader>
                    <ModalBody>
                        Are you sure you would like to bypass minimum password
                        age for admin user to reset their password?
                    </ModalBody>

                    <ModalFooter>
                        <Box
                            style={{
                                border: 'none',
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}>
                            <Button
                                variant="outline"
                                isDisabled={isLoadingBypassMinPasswordAge}
                                className="btn btn-secondary btn-sm disabled:cursor-not-allowed"
                                onClick={() => onClose()}
                                data-walkthroughid={adminsGeneral.ufCloseBtn}>
                                Close
                            </Button>
                        </Box>
                        <Box
                            style={{
                                border: 'none',
                                textAlign: 'end',
                                justifyContent: 'end',
                                marginLeft: 10,
                            }}>
                            <Button
                                type="submit"
                                className="btn btn-primary btn-sm disabled:cursor-not-allowed"
                                isLoading={isLoadingBypassMinPasswordAge}
                                onClick={() => onBypassMinPasswordAge()}
                                data-walkthroughid={adminsGeneral.ufYesBtn}>
                                Yes
                            </Button>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    );
}
