import { useState, useEffect, useRef } from 'react';
import {
    Box,
    // Button,
    Heading,
    Stack,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useDisclosure,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button as BsButton } from 'react-bootstrap';

import {
    changeAdminUser,
    getAdminPermission,
    getAdminUser,
    deleteAdminUser,
} from 'API/AdminUser';
// import { classNames } from '@/utils/classNames';
import { LoadingIndicator } from '@/components/v4';
import AdminStateToasts from './AdminStatusToast';
import EditGeneral from './EditGeneral';
import DeleteAdminModal from 'components/modal/DeleteAdminModal';
import EditAdmin from './EditAdmin';

import { errorMessage, initialState, tabList, ACTIONS } from './constants';
import { customTabStyles, selectedTabStyles } from '@/constants';
import walkthroughIds from '../walkthroughIds';
import { axios } from '@/services/axios';

function AdminsEdit() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { state } = location;
    const { adminId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);

    const {
        requiredScope: accountScope,
        token,
        email,
    } = useSelector((state) => state.login);

    const { currentCompany } = useSelector((state) => state.navigationLists);

    const [resetMfaError, setResetMfaError] = useState(false);
    const [resetMfaSuccess, setResetMfaSuccess] = useState(false);
    const [adminUser, setAdminUser] = useState(initialState);
    const [oldAdmin, setOldAdmin] = useState(adminUser);
    const [formState, setFormState] = useState('idle');
    const [hasError, setHasError] = useState({ message: '' });

    const [ssoEnforced, setSSOEnforced] = useState(false);

    const { adminsGeneral } = walkthroughIds;

    const { data: adminData, status: adminDataStatus } = useQuery({
        queryKey: ['admin', { adminId }],
        queryFn: () => getAdminUser({ token, id: adminId }),
        enabled: Boolean(adminId),
        onSuccess: (data) => {
            setAdminUser(data);
            setOldAdmin(data);
        },
        onError: (err) => {
            throw new Error(err);
        },
        refetchOnWindowFocus: false,
    });

    const { data: permissionSets, status: permissionStatus } = useQuery({
        queryKey: ['permissionSets', currentCompany],
        queryFn: () => getAdminPermission({ accountScope, currentCompany }),
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            return data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        },
        refetchOnWindowFocus: false,
    });

    // eslint-disable-next-line
    const {} = useQuery({
        queryKey: ['sso', currentCompany],
        queryFn: async () => {
            const res = await axios.get(`/sso/${currentCompany}`);
            return res.data;
        },
        enabled: Boolean(currentCompany),
        onSuccess: (data) => {
            setSSOEnforced(data?.isEnforced);
        },
        refetchOnWindowFocus: false,
    });

    // NOTE: in case users use history link to access this page
    const adminName = `${state?.firstName || adminData?.firstName} ${
        state?.lastName || adminData?.lastName || ''
    }`;

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setAdminUser((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const editAdminUser = (data) => {
        setFormState('loading');
        changeAdminUser({
            formData: data,
            scope: accountScope,
        })
            .then((res) => {
                if (
                    res.data.mfaEnabled &&
                    !oldAdmin.mfaEnabled &&
                    email === res.data.email
                ) {
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch({ type: ACTIONS.LOGOUT });
                    dispatch({ type: ACTIONS.CLEAR_TOKEN });
                    navigate('/');
                }
                setFormState('success');
                navigate('/admins');
                dispatch({ type: 'RESET_MODAL' });
                dispatch({ type: 'RESET_TABLE' });
                if (res.status !== 200) {
                    setHasError({
                        message: errorMessage[res.status],
                    });
                    setFormState('error');
                }
            })
            .catch((err) => {
                setHasError({
                    message:
                        err?.response?.data?.message ||
                        'Cannot update admin user.',
                });
                setFormState('error');
                console.error(err);
            })
            .finally(() => setFormState('idle'));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        editAdminUser(adminUser);
    };
    const deleteOnClick = () => {
        deleteAdminUser({ id: adminId, token, scope: accountScope })
            .then((res) => {
                if (res.status === 200) {
                    setHasError(null);
                }
                setFormState('delete');
                navigate('/admins');
                dispatch({ type: 'RESET_MODAL' });
                dispatch({ type: 'RESET_TABLE' });
            })
            .catch((err) => {
                setHasError({
                    message:
                        err?.response?.data?.message ||
                        'Cannot delete admin user.',
                });
                setFormState('delete error');
                console.error(err);
            })
            .finally(() => setFormState('idle'));
    };

    useEffect(() => {
        setAdminUser((prev) => ({
            ...prev,
            ...state,
        }));
        // eslint-disable-next-line
    }, []);

    if (permissionStatus === 'loading' || adminDataStatus === 'loading')
        return <LoadingIndicator />;

    if (adminData?.companyID !== currentCompany) {
        navigate('/admins');
    }

    return (
        <>
            <AdminStateToasts
                resetMfaError={resetMfaError}
                resetMfaSuccess={resetMfaSuccess}
                formState={formState}
                hasError={hasError}
            />
            <Heading fontSize="3xl" as="h2" mb={2} pl={4}>
                <span className="text-2xl font-semibold">
                    Edit Admin: {adminName}
                </span>
            </Heading>
            <Box
                sx={{
                    padding: '10px 0',
                    maxWidth: '2xl',
                    minWidth: 'xl',
                    margin: '0 auto',
                }}>
                <Box
                    as="form"
                    px={{ base: '4', md: '6' }}
                    py={{ base: '5', md: '6' }}
                    onSubmit={handleSubmit}>
                    <Tabs>
                        <TabList justifyContent="center" borderBottom={0}>
                            {tabList.map((tab) => (
                                <Tab
                                    key={tab}
                                    sx={customTabStyles}
                                    _selected={selectedTabStyles}>
                                    {tab}
                                </Tab>
                            ))}
                        </TabList>

                        <TabPanels>
                            <TabPanel px={0}>
                                <EditGeneral
                                    adminId={adminId}
                                    adminUser={adminUser}
                                    setAdminUser={setAdminUser}
                                    setResetMfaError={setResetMfaError}
                                    setResetMfaSuccess={setResetMfaSuccess}
                                    permissionSets={permissionSets}
                                    handleCheckboxChange={handleCheckboxChange}
                                    setHasError={setHasError}
                                    setFormState={setFormState}
                                    ssoEnforced={ssoEnforced}
                                />
                            </TabPanel>

                            <TabPanel px={0}>
                                <EditAdmin
                                    adminUser={adminUser}
                                    onOpen={onOpen}
                                    handleCheckboxChange={handleCheckboxChange}
                                />
                            </TabPanel>
                        </TabPanels>

                        <Stack
                            spacing="6"
                            px={{ base: '4', md: '6' }}
                            pt={{ base: '5', md: '6' }}
                            pb="2px"
                            sx={{
                                justifyContent: 'center',
                                width: '170px',
                                margin: '0 auto',
                            }}
                            direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <BsButton
                                    data-walkthroughid={adminsGeneral.ufBackBtn}
                                    variant="secondary"
                                    className="h-10 min-w-[5rem]"
                                    onClick={() => navigate(-1)}>
                                    Back
                                </BsButton>
                            </Box>
                            <Box>
                                <BsButton
                                    data-walkthroughid={adminsGeneral.ufSaveBtn}
                                    variant="primary"
                                    type="submit"
                                    className="h-10 min-w-[5rem]">
                                    {formState === 'loading'
                                        ? 'Saving...'
                                        : 'Save'}
                                </BsButton>
                            </Box>
                        </Stack>
                    </Tabs>
                </Box>
                <DeleteAdminModal
                    isOpen={isOpen}
                    onClose={onClose}
                    finalRef={finalRef}
                    deleteOnClick={deleteOnClick}
                    adminUser={adminUser}
                />
            </Box>
        </>
    );
}

export default AdminsEdit;
