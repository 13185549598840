import config from '@/config.json';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { TableAndModal } from 'components/index';
import moment from 'moment';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import walkthroughIds from '../walkthroughIds';

const APIUsers = () => {
    const navigate = useNavigate();
    const tableRef = useRef();

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const defaultColumns = [
        {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc',
        },
        {
            title: 'API Key',
            field: 'apiKey',
            render: (rowData) => {
                return rowData.apiKey + '...';
            },
        },
        {
            title: 'Permission',
            field: 'permissionSet',
        },
        {
            title: 'Last Logged In',
            field: 'lastLoggedIn',
            type: 'datetime',
        },
        {
            title: 'Expiration',
            field: 'expiration',
            type: 'datetime',
        },
        {
            title: 'Active',
            field: 'isActive',
            width: '5%',
            type: 'boolean',
        },
    ];

    const editAPINavigate = (data) => {
        navigate(`/admins/api/${data.id}/edit`, { state: data });
    };

    return (
        <>
            <TableAndModal
                tableRef={tableRef}
                title={config.apiUsers.tableTitle}
                noTitle
                newPage
                tableFetch={config.apiUsers.tableFetch + currentCompany}
                defaultColumns={defaultColumns}
                titleProps={
                    <Button
                        className="btn btn-primary btn-sm"
                        data-walkthroughid={walkthroughIds.ufAddApiUser}
                        style={{ marginBottom: '1%' }}
                        onClick={() => {
                            navigate('/admins/api');
                        }}>
                        {' '}
                        <AddRoundedIcon
                            fontSize="small"
                            style={{
                                marginBottom: '2%',
                            }}
                        />{' '}
                        API User
                    </Button>
                }
                additionalActions={[
                    (rowData) => ({
                        icon: () => (
                            <EditRoundedIcon
                                fontSize="small"
                                data-walkthroughid={`${walkthroughIds.ufTableApiUserEditBtn}/${rowData?.name}`}
                            />
                        ),
                        tooltip: 'Edit API User',
                        position: 'row',
                        onClick: () => {
                            editAPINavigate(rowData);
                        },
                    }),
                ]}
                groupable
                filterable
                maxBodyHeight={
                    window.innerHeight * config.plainView.tableHeight
                }
            />
        </>
    );
};

export default APIUsers;
