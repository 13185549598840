import {
    Box,
    HStack,
    Icon,
    Image,
    Spinner,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { MdPhonelinkRing } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import ServiceProfileCard from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCard';
import ServiceProfileCardTitle from 'components/v4/Cards/ServiceProfileCard/ServiceProfileCardTitle';
import walkthroughIds from 'pages/services/walkthroughIds';
import { axios } from 'services/axios';

import path from '@/routes/constants';


import msTeamsLogo from 'assets/images/ms_teams_logo.png';
import mobileLogo from 'assets/images/mobile.png';

function TpmService({ serviceProfile, serviceWizard, id }) {
    const { ufNewServiceProfileCard } = walkthroughIds.dashboardServices;
    const navigate = useNavigate();
    const { currentPartner, currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });
    const { SERVICES_TPM } = path;
    return (
        <ServiceProfileCard
            key={id}
            pointerEvents={serviceWizard.isCreatingService ? 'none' : 'auto'}
            onClick={(e) => {
                serviceWizard.setSelectedServiceProfile('TPM');
                serviceWizard.setIsCreatingService(true);
                serviceWizard.setHasCreateServiceError(null);
                return axios
                    .post(`/Service/TeamsPhoneMobile/Magic/${currentCompany}`)
                    .then((res) => {
                        navigate(`${SERVICES_TPM}/${res.data.id}/activation`);
                    })
                    .catch((e) => {
                        const message =
                            e?.response?.data?.message ||
                            'Error creating Teams Phone Mobile, Please Try Again.';
                        serviceWizard.setHasCreateServiceError(message);
                    });
            }}
            data-walkthroughid={`${ufNewServiceProfileCard}/${id}`}>
            {serviceWizard.isCreatingService && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: '0',
                        top: '0',
                        background: 'white',
                        opacity: '0.7',
                    }}>
                    {serviceWizard.selectedServiceProfile === 'TPM' && (
                        <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="brand.500"
                            size="xl"
                        />
                    )}
                </Box>
            )}
            <Box className="flex h-[120px] items-center justify-center">
                <Icon as={MdPhonelinkRing} fontSize={75} color="#4e57c0" />
            </Box>
            <ServiceProfileCardTitle>
                Teams Phone Mobile
            </ServiceProfileCardTitle>
            <Text>
                Enable Teams Phone Mobile connectivity. This requires a valid
                Operator Connect service to be deployed.
            </Text>
            {currentPartner == '100d5296-0c38-4192-1c43-08dbec03eb47' && (
                <HStack mt={'auto'}>
                    <Tooltip label="Microsoft">
                        <Image boxSize={'5'} src={msTeamsLogo} />
                    </Tooltip>
                    <Tooltip label="Mobile Calling">
                        <Image boxSize={'5'} src={mobileLogo} />
                    </Tooltip>
                </HStack>
            )}
        </ServiceProfileCard>
    );
}

export default TpmService;
