/* eslint-disable no-unused-vars */
import {
    Box,
    Flex,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    chakra,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FaSortAmountDown, FaSortAmountUpAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import EditableCell from './EditableCell';
import Status from './Status';

import { exceedsLimits } from './helper';

function ActivateNumbers({
    data,
    setData: setTelephoneNumbers,
    verifiedStatus,
    type = 'activate',
    numberCopy,
    setNumberCopy,
    spin,
}) {
    const { darkMode } = useSelector((state) => state.settings);
    const { isTrial } = useSelector(
        (state) => state.navigationLists?.currentCompanyData,
    );
    const trialNumberLimit = 5;
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);
    // const [width, setWidth] = useState({
    //     value: 'full',
    //     touched: false,
    // });
    const [focusedRow, setFocusedRow] = useState(-1);
    // TODO: remove this later, just for UI test
    // const _data = [
    //     ...data,
    //     {
    //         number: '123',
    //         status: 'FAIL',
    //         action: 'UNASSIGN',
    //     },
    // ];
    const telephoneNumbers = data;

    /** @const {import('@tanstack/react-table').ColumnDef} */
    const columns = [
        columnHelper.accessor('number', {
            header: 'Telephone Number',
            cell: EditableCell,
            enableColumnFilter: true,
            filterFn: 'includesString',
            meta: {
                // isNumeric: true,
            },
            minSize: 225,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) =>
                info.getValue() ? (
                    <Status
                        info={info}
                        verifiedStatus={verifiedStatus}
                        type={type}
                    />
                ) : null,
        }),
    ];

    // const deactivateColumns = [
    //     ...columns,
    //     columnHelper.accessor('action', {
    //         header: 'Action',
    //         cell: (info) =>
    //             type === 'deactivate' && (
    //                 <div className="flex justify-end">
    //                     {info.getValue() === 'UNASSIGN' && (
    //                         <Button
    //                             colorScheme="tertiary"
    //                             onClick={() => console.log('click unassign')}>
    //                             Unassign
    //                         </Button>
    //                     )}
    //                 </div>
    //             ),
    //     }),
    // ];

    const table = useReactTable({
        columns,
        data,
        // data: _data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        columnResizeMode: 'onChange',
        meta: {
            focusedRow,
            setFocusedRow,
            numberCopy,
            setNumberCopy,
            spin,
            type,
            updateData: (rowIndex, columnId, value, addRow = false) => {
                setTelephoneNumbers((prev) => {
                    const updatedRows = prev.map((row, index) =>
                        index === rowIndex
                            ? {
                                  ...prev[rowIndex],
                                  [columnId]: value,
                                  status:
                                      (columnId === 'number' && !value) ||
                                      (row.status !== 'FAIL' &&
                                          row.status !== 'PASS')
                                          ? 'IDLE'
                                          : row.status,
                              }
                            : row,
                    );

                    if (addRow) {
                        updatedRows.push({ number: '', status: 'IDLE' });
                        if (
                            isTrial &&
                            telephoneNumbers.length >= trialNumberLimit
                        ) {
                            alert(
                                `Cannot exceed a total of ${trialNumberLimit} numbers while Company is on Trial.`,
                            );
                            return prev;
                        }

                        if (telephoneNumbers.length > 1000) {
                            alert('Cannot exceed a total of 1000 numbers.');
                            return prev;
                        }
                    }

                    return updatedRows;
                });
            },
            deleteRow: (rowIndex) => {
                setTelephoneNumbers((prev) => {
                    const updatedRows = prev.filter(
                        (_, index) => index !== rowIndex,
                    );
                    return updatedRows;
                });
            },
            updateTable: (rowIndex, columnId, value, setValue) => {
                setTelephoneNumbers((prev) => {
                    const newTable = [...prev];
                    if (
                        columnId === 'number' &&
                        numberCopy &&
                        isNaN(value) &&
                        value.length > 0
                    ) {
                        const inputData = value.trim();
                        if (!inputData) {
                            return prev;
                        }
                        const newNumbers = inputData
                            .split(/[\s,;]+/)
                            .map(Number)
                            .join(',');

                        const splitNumbers =
                            newNumbers.split(/\s*(?:,\s*|\n|\/n)/);
                        const filteredNumbers = splitNumbers.filter(
                            (num) =>
                                !isNaN(num) &&
                                num !== 0 &&
                                num.match('^[1-9][0-9]+$'),
                        );

                        if (
                            exceedsLimits(
                                isTrial,
                                telephoneNumbers,
                                filteredNumbers,
                                trialNumberLimit,
                            )
                        ) {
                            return prev;
                        }

                        newTable.length = 0;
                        let updatedNumbers = filteredNumbers.map((row) => ({
                            number: row,
                            status: 'IDLE',
                        }));
                        const filterPrevTable = prev.filter(
                            (row) => row.number !== '',
                        );
                        newTable.push(...filterPrevTable, ...updatedNumbers);
                        setFocusedRow(newTable?.length - 1);
                        setNumberCopy(false);
                    } else {
                        setValue(value);
                        newTable[rowIndex].number = value;
                        setNumberCopy(false);
                    }

                    return newTable;
                });
            },
        },
    });

    return (
        <Flex
            flex="1 1 auto"
            flexDir="column"
            overflow="auto"
            w="full"
            maxHeight="400px">
            <TableContainer
                width="full"
                maxW="full"
                overflowY="auto"
                className="mt-[16px]">
                <Table
                    variant="striped"
                    size="sm"
                    colorScheme="messenger"
                    // w={'100%'}
                >
                    <Thead
                        position="sticky"
                        top={0}
                        zIndex={1}
                        height="40px"
                        background={darkMode ? 'dark.tcap' : 'white'}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const meta = header.column.columnDef.meta;
                                    return (
                                        <Th
                                            key={header.id}
                                            isNumeric={meta?.isNumeric}
                                            colSpan={header.colSpan}
                                            className="relative"
                                            textTransform="capitalize"
                                            fontSize="sm"
                                            letterSpacing="wide">
                                            <span
                                                onClick={header.column.getToggleSortingHandler()}
                                                className="cursor-pointer">
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                            </span>
                                            {/* Resizer */}
                                            <Box
                                                onDoubleClick={() =>
                                                    header.column.resetSize()
                                                }
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                                className={`resizer ${
                                                    header.column.getIsResizing()
                                                        ? 'isResizing'
                                                        : ''
                                                }`}
                                            />
                                            <chakra.span
                                                pl="4"
                                                onClick={header.column.getToggleSortingHandler()}
                                                className="cursor-pointer">
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() ===
                                                    'desc' ? (
                                                        <Icon
                                                            as={
                                                                FaSortAmountDown
                                                            }
                                                            aria-label="sorted descending"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            as={
                                                                FaSortAmountUpAlt
                                                            }
                                                            aria-label="sorted ascending"
                                                        />
                                                    )
                                                ) : null}
                                            </chakra.span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {data?.length > 0 ? (
                            table.getRowModel().rows.map((row, index) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        const meta = cell.column.columnDef.meta;
                                        return (
                                            <Td
                                                key={cell.id}
                                                id={cell.id}
                                                isNumeric={meta?.isNumeric}
                                                className={
                                                    darkMode
                                                        ? index % 2 === 0
                                                            ? '!text-black'
                                                            : '!text-white'
                                                        : 'text-black'
                                                }
                                                sx={{
                                                    '&': {
                                                        paddingInlineEnd:
                                                            cell.getValue() ===
                                                            'UNASSIGN'
                                                                ? 0
                                                                : null,
                                                        button: {
                                                            color: darkMode
                                                                ? index % 2 ===
                                                                  0
                                                                    ? 'black'
                                                                    : 'white'
                                                                : 'black',
                                                        },
                                                    },
                                                }}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td
                                    colSpan={2}
                                    className="!border-none !bg-transparent text-center">
                                    No records to display
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
}

export { ActivateNumbers };

ActivateNumbers.propTypes = {
    data: PropTypes.array,
    verifiedStatus: PropTypes.oneOf(['idle', 'loading', 'error', 'success']),
    type: PropTypes.oneOf(['activate', 'deactivate']),
};
