import React, { useState } from 'react';

import MaterialTable from 'material-table';
import MaterialFilterList from '@material-ui/icons/FilterList';
import MaterialRefreshIcon from '@material-ui/icons/Refresh';
import { IoLayers } from 'react-icons/io5';

import { tableIcons, components } from './consts';

import walkthroughIds from './walkthroughIds';

const { ufFilterButton, ufRefreshButton, ufGroupButton } = walkthroughIds.table;

const FilterList = React.forwardRef((props, ref) => (
    <MaterialFilterList
        {...props}
        ref={ref}
        data-walkthroughid={ufFilterButton}
    />
));

export const RefreshIcon = React.forwardRef((props, ref) => (
    <MaterialRefreshIcon
        {...props}
        ref={ref}
        data-walkthroughid={ufRefreshButton}
    />
));

const GroupIcon = React.forwardRef((props, ref) => (
    <IoLayers {...props} ref={ref} data-walkthroughid={ufGroupButton} />
));

/**
 * @param {import('material-table').MaterialTableProps} props
 */
export default function MaterialTableHoc({
    data,
    columns,
    actions = [],
    refreshFn,
    options = {},
    ...props
}) {
    const [isFiltering, setIsFiltering] = useState(false);
    const [isGrouping, setIsGrouping] = useState(false);

    function toggleFiltering() {
        setIsFiltering((p) => !p);
    }

    function toggleGrouping() {
        setIsGrouping((p) => !p);
    }

    const MATERIAL_TABLE_OPTIONS = {
        sorting: true,
        filtering: isFiltering,
        actionsColumnIndex: -1, // Makes actions last column
        pageSize: 50,
        pageSizeOptions: (4)[(50, 100, 250, 500)],
        maxBodyHeight: 696,
        addRowPosition: 'first',
        cellStyle: { textAlign: 'left', wordWrap: 'break-word' },
        columnsButton: true,
        emptyRowsWhenPaging: false,
        exportAllData: true,
        exportButton: { csv: true, pdf: true },
        exportFileName: 'export',
        grouping: isGrouping,
        headerStyle: { position: 'sticky', top: 0 },
        paging: true,
        search: true,
        searchAutoFocus: false,
        selection: undefined,
        showTextRowsSelected: false,
        //loadingType: 'linear',
    };

    const MATERIAL_TABLE_ACTIONS = [
        {
            icon: FilterList,
            tooltip: 'Filter',
            isFreeAction: true,
            onClick: toggleFiltering,
        },
        {
            icon: GroupIcon,
            tooltip: 'Group',
            isFreeAction: true,
            onClick: toggleGrouping,
        },
        {
            icon: RefreshIcon,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: refreshFn,
            hidden: !refreshFn,
        },
    ];

    return (
        <MaterialTable
            {...props}
            icons={tableIcons}
            data={data}
            columns={columns}
            actions={[...MATERIAL_TABLE_ACTIONS, ...actions]}
            options={{ ...MATERIAL_TABLE_OPTIONS, ...options }}
            components={components}
        />
    );
}
