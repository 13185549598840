//external imports
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from '../../../components/v4/MaterialTable';
import Heading from '../../../components/v4/Heading';
import useAddress from '../../../hooks/useAddress';

import { axios } from '../../../services/axios';
import { Button } from 'react-bootstrap';
import { addressColumns } from './consts';
import { ChakraProvider, Box } from '@chakra-ui/react';
import chakratheme from '../../../chakratheme';

import AddressesCreate from './AddressesCreate';
import AddressesEdit from './AddressesEdit';

import MaterialEditIcon from '@material-ui/icons/Edit';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import walkthroughIds from '../walkthroughIds';
import { loadingColumnsConstructor } from 'components/tables/ReduxTable';
import { nullError } from 'components/error';
import { Error } from 'components/index';

const { addresses } = walkthroughIds;

const Edit = React.forwardRef((props, ref) => (
    <MaterialEditIcon
        {...props}
        ref={ref}
        data-walkthroughid={addresses.ufEditButton}
    />
));

function AddressesHeader({ setAddressAction }) {
    return (
        <div>
            <Button
                className="btn btn-primary btn-sm"
                style={{ marginBottom: '5%', appearance: 'none' }}
                onClick={() => setAddressAction('Create')}
                data-walkthroughid={addresses.ufAddButton}>
                {' '}
                <AddRoundedIcon
                    fontSize="small"
                    style={{ marginBottom: '2%' }}
                />{' '}
                Address
            </Button>
        </div>
    );
}

const Addresses = () => {
    const [selectedRowData, setSelectedRowData] = useState();
    const tableRef = useRef();
    const [addressAction, setAddressAction] = useState('Get');
    const [addresses, setAddresses] = useState();
    const [loaded, setLoaded] = useState(false);
    const { currentCompany } =
        useSelector((state) => {
            return { ...state.navigationLists };
        });

    const { concatAddressFields } = useAddress();

    useEffect(() => {
        if (currentCompany) {
            getAddresses();
        }
    }, [addressAction, currentCompany]); // will trigger a refresh, after creating or saving an address

    function editAddressItem(rowData) {
        setSelectedRowData(rowData);
        setAddressAction('Edit');
    }

    function getAddresses() {
        axios.get(`/Addresses/${currentCompany}`).then((response) => {
            const addressess = response.data.map((address, i) => {
                return {
                    address: concatAddressFields(address),
                    ...address,
                };
            });
            setAddresses(addressess);
            setLoaded(true);
        }).catch((e) => {
            setLoaded(true);
            setAddresses();
        });
    }

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(addressColumns),
        [addressColumns],
    );
    const dummyTable = [];
    const dummyTableLength = 12;

    for (let i = 0; i < dummyTableLength; i++) {
        const obj = {
            title: `Title ${i + 1}`,
            field: `Field ${i + 1}`,
        };
        dummyTable.push(obj);
    }

    if (!currentCompany) {
        return (
            <>
                <Error error={nullError} />
            </>
        );
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            {addressAction === 'Get' && (
          <>
            <h1 className='mb-2 text-[2.5rem] font-medium'>Addresses</h1>
                <MaterialTable
                    refreshFn={getAddresses}
                    title={
                        <AddressesHeader setAddressAction={setAddressAction} />
                    }
                    columns={!loaded ? loadingColumns : addressColumns}
                    data={!loaded ? dummyTable : addresses}
                    actions={[
                        {
                            icon: Edit,
                            tooltip: 'Edit',
                            isFreeAction: false,
                            onClick: (e, rowData) => {
                                editAddressItem(rowData);
                            },
                        },
                    ]}
                />
            </>
            )
            }
            <Box sx={{ maxW: '1000px', margin: '0 auto' }}>
                {addressAction === 'Create' && (
                    <AddressesCreate setAddressAction={setAddressAction} />
                )}
                {addressAction === 'Edit' && (
                    <AddressesEdit
                        setAddressAction={setAddressAction}
                        rowData={selectedRowData}
                    />
                )}
            </Box>
        </ChakraProvider>
    );
};

export default Addresses;
