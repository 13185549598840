import {
    Alert,
    AlertDescription,
    AlertIcon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@/components/v4';
import { Button } from '@chakra-ui/react';

export default function DeleteAdminModal({
    isOpen,
    onClose,
    adminUser,
    deleteOnClick,
    finalRef,
}) {
    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Delete Admin User: &nbsp;
                    {`${adminUser?.firstName} ${adminUser?.lastName || ''}`}
                </ModalHeader>
                <ModalBody>
                    <Alert status="warning">
                        <AlertIcon />
                        <AlertDescription>
                            Are you sure you would like to delete this admin
                            user?
                        </AlertDescription>
                    </Alert>
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="outline"
                        bg="white"
                        mr={3}
                        onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="red"
                        onClick={deleteOnClick}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
