import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { actionButtons } from './helper';

const EditableCell = ({ getValue, row, column, table, addRow = true }) => {
    const initValue = getValue();
    const { darkMode } = useSelector((state) => state.settings);
    const {
        focusedRow,
        setFocusedRow,
        numberCopy,
        setNumberCopy,
        updateData,
        updateTable,
        deleteRow,
        spin,
        type,
    } = table.options.meta;
    const [value, setValue] = useState(initValue);
    const numberRef = useRef(null);

    const onBlur = () => {
        if (value !== initValue) updateData(row.index, column.id, value);
    };

    const addNewRow = () => {
        updateData(row.index, column.id, value, addRow);
        setFocusedRow((fr) => fr + 1);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onBlur();
            e.preventDefault();
            addNewRow();
        }
        if (
            e.key === 'Delete' &&
            value === '' &&
            table.options.data?.length > 1
        ) {
            e.preventDefault();
            const newIndex = row.index > 0 ? row.index - 1 : 0;
            deleteRow(row.index);
            setFocusedRow(newIndex);
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
            setNumberCopy(true);
        }
    };

    const shouldShowAddButton =
        (value || row.index === 0) &&
        row.index === table.options.data.length - 1;

    useEffect(() => {
        if (numberRef?.current) return;
        if (row.index === focusedRow) {
            numberRef?.current?.focus();
        }
    }, [focusedRow, row.index, numberRef]);

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    useEffect(() => {
        if (row.index === 0) {
            setFocusedRow(0);
        } else {
            setFocusedRow(table.options.data.length - 1);
        }
    }, [row.index, setFocusedRow, table]);

    return (
        <InputGroup>
            <Input
                as={numberCopy == false && NumberFormat}
                value={value}
                onChange={(e) => {
                    e.preventDefault();
                    updateTable(row.index, column.id, e.target.value, setValue);
                }}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                isNumericString={!numberCopy}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={!numberCopy}
                getInputRef={(el) => (numberRef.current = el)}
                ref={numberRef}
                autoFocus={row.index === focusedRow}
                name="number"
                variant="flushed"
                size="sm"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                aria-label={`${type}-${column.id}-input-${row.index}`}
                borderColor={
                    darkMode ? 'dark.tcap-borderGray' : 'light.text-weak'
                }
                _placeholder={{
                    color: darkMode ? '#6C757D' : null,
                }}
                placeholder="Please enter number in E164 format."
                className={`${spin ? 'animate-[pulse_0.5s_cubic-bezier(0.4,0,0.6,1)_infinite]' : ''}`}
                pe={8}
                onPaste={(event) => {
                    const numberData = event.clipboardData.getData('text');
                    if (numberData.length > 0) {
                        event.clipboardData.getData('text');
                        setNumberCopy(true);
                    }
                }}
            />
            {shouldShowAddButton ? (
                <InputRightElement opacity="0.8" width={8} h={8}>
                    <Tooltip label={actionButtons[0].label} hasArrow>
                        <IconButton
                            id={`${actionButtons[0]['aria-label']}-${row.index}`}
                            icon={value && <AddIcon boxSize="18px" />}
                            rounded="full"
                            variant="ghost"
                            isDisabled={!value}
                            height={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'brand.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                addNewRow();
                            }}
                        />
                    </Tooltip>
                </InputRightElement>
            ) : (
                <InputRightElement opacity="0.8" width={8} h={8}>
                    <Tooltip label={actionButtons[1].label} hasArrow>
                        <IconButton
                            id={`${actionButtons[1]['aria-label']}-${row.index}`}
                            icon={<MinusIcon boxSize="18px" />}
                            rounded="full"
                            variant="ghost"
                            height={8}
                            minW={8}
                            _hover={{
                                bg: darkMode ? 'dark.hoverGray' : null,
                                color: 'red.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                            }}
                            onClick={() => {
                                const newIndex =
                                    row.index > 0 ? row.index - 1 : 0;
                                deleteRow(row.index);
                                setFocusedRow(newIndex);
                            }}
                        />
                    </Tooltip>
                </InputRightElement>
            )}
        </InputGroup>
    );
};

export default EditableCell;
