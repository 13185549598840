import { ChakraSpinner } from '@/lib/loading-screen';
import ImportWrapper from './ImportWrapper';

function ImportLoading() {
    return (
        <ImportWrapper>
            <ChakraSpinner />
            <span className="mt-2 font-semibold">Activation in Progress</span>
            <span>Do not navigate away from this window.</span>
        </ImportWrapper>
    );
}

export default ImportLoading;
