import useCSVNumbersUpdate from 'hooks/useCSVNumbersUpdate';
import CSVTemplateView from 'pages/accounts/users/csv/CSVTemplateView.jsx';
import VerifyCSVView from 'pages/accounts/users/csv/VerifyCSVView';
import VerifyCSVTableView from 'pages/accounts/users/csv/VerifyCSVTableView';
import { ChakraProvider, Box } from '@chakra-ui/react';
import chakratheme from 'src/chakratheme';
import { useState } from 'react';
import useCSVNumbersBulkUpdate from 'hooks/useCSVNumbersUpdate/useCSVNumbersBulkUpdate';
import LoadingIndicator from 'components/v4/LoadingIndicator';
import {
    failedColumns,
    passedColumns,
    succeedAPIColumns,
    failedAPIColumns,
} from 'hooks/useCSVNumbersUpdate/constants';
import APILoadingView from 'pages/accounts/users/csv/APILoadingView';
import APIResultsView from 'pages/accounts/users/csv/APIResultsView';
const useCSVNumbersUpdateUIControl = () => {
    const [viewVerificationResults, setViewVerificationResults] =
        useState(false);
    const [viewAPIResults, setViewAPIResults] = useState(false);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const handleViewVerificationResults = () => {
        setViewVerificationResults(true);
        setViewAPIResults(false);
    };
    const handleCancel = () => {
        setLoadingIndicator(true);
        window.location.reload();
    };
    const handleViewAPIResults = () => {
        setViewAPIResults(true);
        setViewVerificationResults(false);
    };
    return {
        viewVerificationResults,
        handleViewVerificationResults,
        handleCancel,
        loadingIndicator,
        handleViewAPIResults,
        viewAPIResults,
    };
};
export default function CSVNumbersUpdate() {
    const {
        handleDownloadCSVTemplate,
        handleUploadData,
        templateLoading,
        verificationChecks,
        uploadData,
        handleExportInvalidData,
        verificationResult,
        payloads,
        error: verificationError,
    } = useCSVNumbersUpdate();
    const {
        viewVerificationResults,
        handleViewVerificationResults,
        handleCancel,
        handleViewAPIResults,
        loadingIndicator,
        viewAPIResults,
    } = useCSVNumbersUpdateUIControl();
    const {
        handlePayloadChange,
        loading: apiLoading,
        counter,
        errorCounter,
        error,
        succeed,
        handleExportFailedData,
        total,
    } = useCSVNumbersBulkUpdate();

    if (loadingIndicator) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <LoadingIndicator />
            </ChakraProvider>
        );
    }
    if (verificationError) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVView
                    handleStartOver={handleCancel}
                    errors={verificationError}
                />
            </ChakraProvider>
        );
    }
    if (viewAPIResults) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <APIResultsView
                    error={error}
                    succeed={succeed}
                    handleExportFailedData={handleExportFailedData}
                    handleDone={handleCancel}
                    succeedAPIColumns={succeedAPIColumns}
                    failedAPIColumns={failedAPIColumns}
                />
            </ChakraProvider>
        );
    }
    if (apiLoading) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <APILoadingView
                    completionCounter={counter}
                    payloads={payloads}
                    verificationResult={verificationResult}
                    errorCounter={errorCounter}
                    handleViewAPIResults={handleViewAPIResults}
                    total={total}
                />
            </ChakraProvider>
        );
    }

    if (viewVerificationResults) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVTableView
                    verificationResult={verificationResult}
                    handleCancel={handleCancel}
                    handleImport={() => {
                        handlePayloadChange(payloads);
                    }}
                    passedColumns={passedColumns}
                    failedColumns={failedColumns}
                    handleExportInvalidData={handleExportInvalidData}
                    handleStartOver={handleCancel}
                />
            </ChakraProvider>
        );
    }
    if (uploadData) {
        return (
            <ChakraProvider theme={chakratheme} resetCSS={true}>
                <VerifyCSVView
                    handleViewVerificationResults={
                        handleViewVerificationResults
                    }
                    verificationChecks={verificationChecks}
                />
            </ChakraProvider>
        );
    }

    return (
        <ChakraProvider theme={chakratheme} resetCSS={true}>
            <CSVTemplateView
                handleUploadUsersData={handleUploadData}
                downloadCSVText="Download"
                header="CSV Upload"
                step1Text="Step 1 - Download the CSV template"
                step2Text="Step 2 - Upload CSV to be imported"
                instructionsHeader="Instructions"
                handleDownloadTemplate={handleDownloadCSVTemplate}
                loading={templateLoading}
                bgColor="white"
                instructionsBody={[
                    'Number IDs and Telephone Numbers cannot be edited. Overstamp Numbers are not validated. ',
                    'Numbers Updates only applied on the currently managing company.',
                    'Please provide the correct address description for importing.',
                    'Please ensure you keep the original CSV file, in case of data corruption.',
                    'You are responsible for the changes you are about to make through this data import including any cost incurred as a result of number activation. Please consult your contract for all billing terms.',
                ]}
            />
        </ChakraProvider>
    );
}
