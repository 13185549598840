import { IconButton, useBoolean } from '@chakra-ui/react';
import { MdOutlineDelete, MdOutlineDeleteSweep } from 'react-icons/md';

function DeleteBin({ onClick }) {
    const [isHovered, setIsHovered] = useBoolean();
    return (
        <IconButton
            aria-label="Delete"
            onMouseEnter={setIsHovered.on}
            onMouseLeave={setIsHovered.off}
            _hover={{
                bg: 'none',
                borderRadius: 'full',
            }}
            _focus={{
                bg: 'none',
                borderRadius: 'full',
            }}
            _active={{
                bg: 'none',
            }}
            icon={
                isHovered ? (
                    <MdOutlineDeleteSweep size={20} />
                ) : (
                    <MdOutlineDelete size={18} />
                )
            }
            bg="transparent"
            onClick={onClick}
        />
    );
}

export default DeleteBin;
