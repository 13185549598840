import { CalendarIcon } from '@chakra-ui/icons';
import {
    Box,
    Checkbox as ChakraCheckbox,
    ChakraProvider,
    Skeleton as ChakraSkeleton,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Heading,
    Icon,
    InputGroup,
    InputRightElement,
    SkeletonCircle,
    Stack,
    VStack,
    forwardRef,
    useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { onModalError } from '../../../../components/tables/TableAndModal';
import { axios } from '../../../../services/axios';

import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import ChakraButton from 'components/v4/Button';
import Grid from 'components/v4/Grid';
import Select from 'components/v4/Select';

import { useState } from 'react';
import { COMPANY } from '../../sharedComponents/BillingForm';
import './alert.css';

import chakratheme, {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';
import { chakraDatepicker, chakraDatepickerDarkMode } from '@/constants';
import walkthroughIds from 'pages/organisations/walkthroughIds';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Skeleton = forwardRef((props, ref) => (
    <ChakraSkeleton ref={ref} className="!rounded-md" {...props} />
));

/*
 * Admin form for companies in the edit tab
 * Has a danger zone component with more senstive settings
 * Also has the ability to convert a company off trial
 */
const Admin = ({ setState, mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toastId = useRef(null);

    const { state, data: modalData } = useSelector((state) => {
        return { ...state.modal };
    });

    const { currentCompany } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const { darkMode } = useSelector((state) => state.settings);

    const [date, setDate] = useState(new Date(state?.trialEndLocal)); // NOTE: init use local timer to display correct date, but BE will save as UTC

    const [modalLoading, setModalLoading] = useState(false);
    const [userNumberState, setUserNumberState] = useState({
        deallocate: false,
        isDisabled: true,
        showIcon: true,
        showButton: true,
        isLoading: false,
        count: '0',
    });
    const [userData, setUserData] = useState([]);
    const [raNumberState, setRANumberState] = useState({
        deallocate: false,
        isDisabled: true,
        showIcon: true,
        showButton: true,
        isLoading: false,
        count: '0',
    });
    const [raData, setRAData] = useState([]);
    const [numberBlockState, setNumberBlockState] = useState({
        deallocate: false,
        isDisabled: true,
        showIcon: true,
        showButton: true,
        isLoading: false,
        count: '0',
    });
    const [nbData, setNBData] = useState([]);
    const [deactivateState, setDeactivateState] = useState({
        deactivate: false,
        isDisabled: true,
        showIcon: true,
        showButton: true,
        isLoading: false,
    });
    const [deleteState, setDeleteState] = useState({
        delete: false,
        isDisabled: true,
        isLoading: false,
    });

    const {
        trialExpiryDatePicker,
        ufConvertButton,
        mfaSelect,
        ufDeleteButton,
        trialOnHoldCheckbox,
    } = walkthroughIds.companies.admin;
    // modal data from state

    //
    useEffect(() => {
        if (!state?.trialEnd) {
            setDate({
                trialEnd: Date.now(),
            });
        }
    }, [state?.trialEnd]);

    // handler when converting a company off trial
    const onTrialConvertHandler = () => {
        setState({
            isTrial: !state.isTrial,
        });
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                mode: 'Convert',
                uploading: false,
                loading: false,
                data: modalData,
                state: state,
            },
        });
    };

    const errorToast = (message) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(message);
        }
    };

    const successToast = (message) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(message);
        }
    };

    const isCompany = mode === COMPANY;

    // delete handler
    const onDeleteHandler = async () => {
        setModalLoading(true);
        onOpen();
        try {
            const res0 = await axios.get(`/Company/${state?.id}/Numbers`);
            const res1 = await axios.get(`/resourceaccounts/${state?.id}`);
            const res2 = await axios.get(`/users/${state?.id}`);

            const userResponse = res2?.data?.filter((v) => v.numberID);
            const raResponse = res1?.data?.filter((v) => v.numberID);
            const numbersResponse = res0?.data?.map((v) => v.numberBlockID);
            let uniqueNumberBlockIDs = [...new Set(numbersResponse)];

            // user handling
            if (userResponse?.length > 0) {
                setUserNumberState({
                    ...userNumberState,
                    deallocate: true,
                    count: `${userResponse?.length} of ${res2?.data.length}`,
                    showButton: userResponse?.length > 0 ? true : false,
                    showIcon: userResponse.length > 0 ? true : false,
                    isDisabled: userResponse?.length > 0 ? false : true,
                });
                setUserData(userResponse);
            } else {
                setUserNumberState({
                    ...userNumberState,
                    deallocate: false,
                    count: `${res2?.data.length}`,
                    showButton: false,
                    showIcon: false,
                });
            }

            // ra handling
            if (raResponse?.length > 0) {
                setRANumberState({
                    ...raNumberState,
                    deallocate: userResponse?.length > 0 ? false : true,
                    count: `${raResponse?.length} of ${res1?.data?.length}`,
                    showButton: true,
                    showIcon: true,
                    isDisabled:
                        userResponse?.length > 0
                            ? true
                            : raResponse?.length > 0
                              ? false
                              : true,
                });
                setRAData(raResponse);
            } else {
                setRANumberState({
                    ...raNumberState,
                    deallocate: false,
                    count: `${res1?.data?.length}`,
                    showButton: false,
                    showIcon: false,
                });
            }

            // nb handling
            if (uniqueNumberBlockIDs.length > 0) {
                setNumberBlockState({
                    ...numberBlockState,
                    deallocate:
                        raResponse?.length > 0 || userResponse?.length > 0
                            ? false
                            : true,
                    count: uniqueNumberBlockIDs.length,
                    showButton: true,
                    showIcon: true,
                    isDisabled:
                        raResponse?.length > 0 || userResponse?.length > 0
                            ? true
                            : false,
                });
                setNBData(uniqueNumberBlockIDs);
            } else {
                setNumberBlockState({
                    ...numberBlockState,
                    deallocate: false,
                    count: res0?.data?.length,
                    showButton: false,
                    showIcon: false,
                });
            }

            if (state?.isActive) {
                setDeactivateState({
                    ...deactivateState,
                    deallocate:
                        raResponse?.length > 0 ||
                        userResponse?.length > 0 ||
                        uniqueNumberBlockIDs.length > 0
                            ? false
                            : true,
                    showButton: true,
                    showIcon: true,
                    isDisabled:
                        raResponse?.length > 0 ||
                        userResponse?.length > 0 ||
                        uniqueNumberBlockIDs.length > 0
                            ? true
                            : false,
                });
            }
            // if reaches here, check for previous steps to be sure it can be delete
            else {
                setDeactivateState({
                    ...deactivateState,
                    showButton: false,
                    showIcon: false,
                });
                if (
                    raResponse?.length > 0 ||
                    userResponse?.length > 0 ||
                    uniqueNumberBlockIDs.length > 0
                ) {
                    /* empty */
                } else {
                    setDeactivateState({
                        ...deactivateState,
                        showButton: false,
                        showIcon: false,
                    });
                    setDeleteState({
                        ...deleteState,
                        delete: true,
                        isDisabled: false,
                    });
                }
            }
            setModalLoading(false);
            // else there is no number blocks to be unassigned
        } catch (e) {
            onModalError;
            setModalLoading(false);
            return;
        }
    };

    const onDeallocateUsers = async () => {
        if (userData?.length > 0) {
            setUserNumberState({ ...userNumberState, isLoading: true });
            for (var item of userData) {
                item.numberID = null;
                if (item.planId) {
                    item.planId = null;
                }
                try {
                    await axios.put('/user', item);
                } catch (e) {
                    errorToast.error(e.response.data.message);
                    setUserNumberState({
                        ...userNumberState,
                        isLoading: false,
                    });
                    return;
                }
            }
            setUserNumberState({
                ...userNumberState,
                isLoading: false,
                showButton: false,
                showIcon: false,
                count: `${userData.length}`,
            });
            // numbers assigned to ra if any
            if (raData?.length > 0) {
                setRANumberState({
                    ...raNumberState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // nb assigned if any
            if (nbData?.length > 0) {
                setNumberBlockState({
                    ...numberBlockState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // state of company
            if (state?.isActive) {
                setDeactivateState({
                    ...deactivateState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // reaches here means all good, can delete company
            else {
                setDeleteState({
                    ...deleteState,
                    delete: true,
                    isDisabled: false,
                });
            }
        }
    };

    const onDeallocateRA = async () => {
        if (raData?.length > 0) {
            setRANumberState({ ...raNumberState, isLoading: true });
            for (var item of raData) {
                item.numberID = null;
                if (item.planId) {
                    item.planId = null;
                }
                try {
                    await axios.put('/resourceaccount', item);
                } catch (e) {
                    errorToast.error(e.response.data.message);
                    setRANumberState({
                        ...raNumberState,
                        isLoading: false,
                    });
                    return;
                }
            }
            setRANumberState({
                ...raNumberState,
                isLoading: false,
                showButton: false,
                showIcon: false,
                count: `${raData.length}`,
            });
            // nb assigned if any
            if (nbData?.length > 0) {
                setNumberBlockState({
                    ...numberBlockState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // state of company
            if (state?.isActive) {
                setDeactivateState({
                    ...deactivateState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // reaches here means all good, can delete company
            else {
                setDeleteState({
                    ...deleteState,
                    delete: true,
                    isDisabled: false,
                });
            }
        }
    };

    const onDeallocateNB = async () => {
        if (nbData?.length > 0) {
            setNumberBlockState({ ...numberBlockState, isLoading: true });
            for (const nbID of nbData) {
                try {
                    // get nb object
                    let res = await axios.get(`NumberBlock/${nbID}`);
                    res.data.companyID = null;
                    // unassign nb from company by setting company id to null
                    await axios.put('/NumberBlock', res.data);
                } catch (e) {
                    errorToast(e.response.data.message);
                    setNumberBlockState({
                        ...numberBlockState,
                        isLoading: false,
                    });
                    return;
                }
            }
            setNumberBlockState({
                ...numberBlockState,
                isLoading: false,
                showButton: false,
                showIcon: false,
                count: `${nbData.length}`,
            });
            // checks state of company on success
            if (state?.isActive) {
                setDeactivateState({
                    ...deactivateState,
                    deallocate: true,
                    isDisabled: false,
                });
                return;
            }
            // should be good to allow delete of company
            else {
                setDeleteState({
                    ...deleteState,
                    delete: true,
                    isDisabled: false,
                });
            }
        }
    };

    const onDeactivateCompany = async () => {
        // need to change local state value in general tab
        // need to perform an api call
        // on success then change local state value
        if (state?.isActive) {
            setDeactivateState({ ...deactivateState, isLoading: true });
            state.isActive = false;
            try {
                await axios.put('/company', state);
            } catch (e) {
                errorToast(e.response.data.message);
                setDeactivateState({ ...deactivateState, isLoading: false });
                return;
            }
            // update local state with new value
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    isActive: false,
                },
            });
            // update deactive company state and allow company to be deleted
            setDeactivateState({
                ...deactivateState,
                isLoading: false,
                showButton: false,
                showIcon: false,
            });
            setDeleteState({ ...deleteState, delete: true, isDisabled: false });
        }
    };

    // delete company handler
    const onCompanyDeleteHandler = () => {
        setDeleteState({ ...deleteState, isLoading: true });
        axios
            .delete(`/company/${state?.id}`)
            .then(() => {
                navigate('/companies');
                dispatch({ type: 'RESET_MODAL' });
                dispatch({ type: 'RESET_TABLE' });
                if (currentCompany == state?.id) {
                    dispatch({
                        type: 'CHANGE_CURRENT_COMPANY',
                        payload: state?.partnerID,
                    });
                    window.location.reload();
                }
                setTimeout(() => {
                    successToast('Company deleted successfully!');
                }, 1000);
            })
            .catch((err) => {
                errorToast(err.response.data.message);
                setDeleteState({ ...deleteState, isLoading: false });
                return;
            });
    };

    function onCompanyEnforceMfa(e) {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                mfaEnforced: e.target.value,
            },
        });
    }

    const viewNavigate = (route) => {
        dispatch({
            type: 'RESET_MODAL',
        });
        dispatch({
            type: 'RESET_TABLE',
        });
        dispatch({
            type: 'CHANGE_CURRENT_COMPANY',
            payload: state?.id,
        });
        dispatch({
            type: 'CHANGE_CURRENT_PARTNER',
            payload: state?.partnerID || state?.id,
        });

        // get parent menu item Accounts
        let acc = document.querySelector('.Accounts');
        // if only 1 then the menu item is closed
        if (acc.children.length == 1) {
            var divElement = acc.querySelector('div');
            // create on click event
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            divElement.dispatchEvent(clickEvent);
        }

        // redirect is to select the sub menu item
        // scope 20 to focus on currently managing company
        dispatch({
            type: 'REDIRECT',
            payload: {
                pathname:
                    '/accounts' +
                    (route === '/resource-accounts' ? '/Users' : route),
                scope: 20,
            },
        });

        navigate(route);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    // rendering the form and danger zone
    return (
        <ChakraProvider resetCSS theme={chakratheme}>
            <Box
                as="form"
                mt="21px"
                mx="8%"
                px="15px"
                maxW={[640, 768, 1280, 1536]}
                sx={{
                    h4: {
                        fontSize: '1.5rem',
                        fontWeight: 500,
                    },

                    label: {
                        fontWeight: 600,
                    },
                }}>
                <Stack spacing="12.5px">
                    {isCompany && (
                        <>
                            {state.isTrial === true ? (
                                <>
                                    <Heading as="h4"> Trial Status </Heading>

                                    <HStack spacing="25px">
                                        <FormControl className="my-auto max-w-[630px]">
                                            <FormLabel>Trial Expiry</FormLabel>
                                            <InputGroup
                                                sx={
                                                    darkMode
                                                        ? chakraDatepickerDarkMode
                                                        : chakraDatepicker
                                                }
                                                data-walkthroughid={
                                                    trialExpiryDatePicker
                                                }>
                                                <SingleDatepicker
                                                    name="date-input"
                                                    date={date}
                                                    onDateChange={(value) => {
                                                        const newDate =
                                                            new Date(value);
                                                        newDate.setHours(
                                                            0,
                                                            0,
                                                            0,
                                                            0,
                                                        );
                                                        setState({
                                                            trialEnd: newDate,
                                                        });
                                                        setDate(newDate);
                                                    }}
                                                    configs={{
                                                        dateFormat:
                                                            'yyyy/MM/dd',
                                                    }}
                                                    propsConfigs={
                                                        darkMode
                                                            ? ChakraDatePickerDarkModeStyles
                                                            : ChakraDatePickerStyles
                                                    }
                                                />
                                                <InputRightElement
                                                    color={
                                                        darkMode
                                                            ? 'white'
                                                            : 'gray.500'
                                                    }
                                                    pointerEvents="none">
                                                    <CalendarIcon />
                                                </InputRightElement>
                                            </InputGroup>
                                            <FormHelperText>
                                                Date Format is yyyy/MM/dd
                                            </FormHelperText>
                                        </FormControl>

                                        {state.isTrial && (
                                            <Box className="flex !w-3/4 flex-row">
                                                <FormControl>
                                                    <FormLabel>
                                                        Convert From Trial
                                                    </FormLabel>
                                                    <Box className="h-[40px]">
                                                        This will take the
                                                        company off trial
                                                    </Box>
                                                    <Box className="mt-6 flex" />
                                                </FormControl>

                                                <FormControl textAlign="right">
                                                    <Button
                                                        variant="primary"
                                                        onClick={
                                                            onTrialConvertHandler
                                                        }
                                                        data-walkthroughid={
                                                            ufConvertButton
                                                        }>
                                                        Convert
                                                    </Button>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </HStack>
                                </>
                            ) : (
                                <>
                                    <Heading as="h4">Trial Status</Heading>
                                    <HStack>
                                        <Box>
                                            <p>This company is not on trial</p>
                                        </Box>
                                    </HStack>
                                </>
                            )}
                            <Heading as="h4">MFA</Heading>
                            <FormControl
                                className="max-w-[630px]"
                                data-walkthroughid={mfaSelect}>
                                <FormLabel>MFA</FormLabel>
                                <Select
                                    options={[
                                        { label: 'Forced', value: true },
                                        { label: 'Enabled', value: false },
                                    ]}
                                    onChange={onCompanyEnforceMfa}
                                    value={state?.mfaEnforced}
                                />
                            </FormControl>
                        </>
                    )}

                    {state?.partnerID && (
                        <>
                            <Heading as="h4"> Danger Zone </Heading>
                            <Box
                                sx={{
                                    border: '2px solid red',
                                    borderRadius: '5px',
                                    padding: '5px 15px 5px 15px',
                                    backgroundColor: darkMode
                                        ? 'dark.bgDark'
                                        : '#fff',
                                    textColor: darkMode ? 'white' : 'inherit',
                                    marginBottom: '25px',
                                    '& > hr': {
                                        borderColor: 'rgba(0, 0, 0, 0.12)',
                                        margin: '10px auto',
                                    },
                                }}>
                                <Grid columns={5}>
                                    <Box gridColumn="span 4">
                                        <p>
                                            <b>Delete</b>
                                        </p>
                                        <p>
                                            By deleting a company they will stop
                                            syncing, invoices will change to
                                            finalizing and no further billing
                                            will occur
                                        </p>
                                    </Box>

                                    <Box
                                        textAlign="right"
                                        alignContent="center"
                                        my="auto">
                                        <ChakraButton
                                            colorScheme="red"
                                            _hover={{
                                                bg: 'red.600',
                                            }}
                                            bg={'red.500'}
                                            onClick={onDeleteHandler}
                                            _focus={{
                                                boxShadow:
                                                    '0 0 0 3px rgba(225, 66, 87, 0.6)',
                                            }}
                                            data-walkthroughid={ufDeleteButton}>
                                            Delete
                                        </ChakraButton>
                                    </Box>
                                </Grid>
                                {isCompany && state.isTrial === true && (
                                    <>
                                        <Divider
                                            bg={darkMode ? 'white' : 'inherit'}
                                            borderColor={'gray.500 !important'}
                                        />
                                        <Grid columns={5}>
                                            <Box gridColumn="span 4">
                                                <p>
                                                    <b> Trial on Hold </b>
                                                </p>
                                                <p>
                                                    Disables Inbound &amp;
                                                    Outbound Calls
                                                </p>{' '}
                                            </Box>
                                            <Box
                                                textAlign="right"
                                                alignContent="center"
                                                my="auto">
                                                <ChakraCheckbox
                                                    size="lg"
                                                    colorScheme="secondary"
                                                    isChecked={state.trialHold}
                                                    onChange={() => {
                                                        setState({
                                                            trialHold:
                                                                !state.trialHold,
                                                        });
                                                    }}
                                                    data-walkthroughid={
                                                        trialOnHoldCheckbox
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                {/* {requiredScope === 80 && (
                            <>
                                <Divider />
                                <Row style={{minHeight: 60}}>
                                    <Col style={{minWidth: '90%'}}>
                                        <b> Reverse Provisioning </b> <br />{' '}
                                        STOP! This begins de-provisioning
                                        process for this company
                                    </Col>
                                    <Col
                                        style={{
                                            minWidth: '10%',
                                            textAlign: 'right',
                                        }}>
                                        <Checkbox
                                            checked={state.reverseProvision}
                                            onChange={() => {
                                                setState({
                                                    reverseProvision:
                                                        !state.reverseProvision,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )} */}
                            </Box>

                            {/* Delete modal  */}
                            <Modal
                                autoFocus={false}
                                closeOnOverlayClick={false}
                                isOpen={isOpen}
                                onClose={onClose}
                                isCentered>
                                <ModalOverlay />
                                <ModalContent minW={'40%'}>
                                    <ModalHeader>
                                        Delete Company: {state?.name}{' '}
                                    </ModalHeader>
                                    <ModalBody>
                                        {modalLoading ? (
                                            <Stack>
                                                {Array(4)
                                                    .fill(1)
                                                    .map((_, index) => (
                                                        <HStack key={index}>
                                                            <SkeletonCircle
                                                                h={8}
                                                            />
                                                            <Skeleton
                                                                flex={5}
                                                                h={8}
                                                            />
                                                            <Skeleton
                                                                flex={1}
                                                                h={8}
                                                            />
                                                            <Skeleton
                                                                flex={1}
                                                                h={8}
                                                            />
                                                        </HStack>
                                                    ))}
                                            </Stack>
                                        ) : (
                                            <Stack>
                                                <HStack
                                                    display={'flex'}
                                                    justifyContent={
                                                        'space-between'
                                                    }>
                                                    <HStack>
                                                        <VStack>
                                                            <Box w={8} h={8}>
                                                                {!userNumberState.showIcon ? (
                                                                    <Icon
                                                                        as={
                                                                            MdCheckCircle
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="green.400"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        as={
                                                                            MdCancel
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="red.400"
                                                                    />
                                                                )}
                                                            </Box>
                                                            <Box w={8} h={8}>
                                                                {!raNumberState.showIcon ? (
                                                                    <Icon
                                                                        as={
                                                                            MdCheckCircle
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="green.400"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        as={
                                                                            MdCancel
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="red.400"
                                                                    />
                                                                )}
                                                            </Box>
                                                            <Box w={8} h={8}>
                                                                {!numberBlockState.showButton ? (
                                                                    <Icon
                                                                        as={
                                                                            MdCheckCircle
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="green.400"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        as={
                                                                            MdCancel
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="red.400"
                                                                    />
                                                                )}
                                                            </Box>
                                                            <Box w={8} h={8}>
                                                                {!deactivateState.showButton ? (
                                                                    <Icon
                                                                        as={
                                                                            MdCheckCircle
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="green.400"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        as={
                                                                            MdCancel
                                                                        }
                                                                        w={8}
                                                                        h={8}
                                                                        color="red.400"
                                                                    />
                                                                )}
                                                            </Box>
                                                        </VStack>

                                                        <VStack>
                                                            <Box
                                                                h={8}
                                                                width={'100%'}
                                                                pt={1}>
                                                                Remove Numbers
                                                                from{' '}
                                                                {
                                                                    userNumberState?.count
                                                                }{' '}
                                                                Users
                                                            </Box>
                                                            <Box
                                                                h={8}
                                                                width={'100%'}
                                                                pt={1}>
                                                                Remove Numbers
                                                                from{' '}
                                                                {
                                                                    raNumberState?.count
                                                                }{' '}
                                                                Resource
                                                                Accounts
                                                            </Box>
                                                            <Box
                                                                h={8}
                                                                width={'100%'}
                                                                pt={1}>
                                                                Remove{' '}
                                                                {
                                                                    numberBlockState?.count
                                                                }{' '}
                                                                Number Blocks
                                                                from Company
                                                            </Box>
                                                            <Box
                                                                h={8}
                                                                width={'100%'}
                                                                pt={1}>
                                                                Deactivate
                                                                Company
                                                            </Box>
                                                        </VStack>
                                                    </HStack>
                                                    <HStack>
                                                        <VStack>
                                                            <Box
                                                                display={'flex'}
                                                                flexDir={'row'}
                                                                h={8}>
                                                                {userNumberState.showButton && (
                                                                    <>
                                                                        <ChakraButton
                                                                            mr={
                                                                                1
                                                                            }
                                                                            onClick={() =>
                                                                                viewNavigate(
                                                                                    '/users',
                                                                                )
                                                                            }
                                                                            display={
                                                                                'flex'
                                                                            }
                                                                            size={
                                                                                'sm'
                                                                            }
                                                                            justifyContent={
                                                                                'right'
                                                                            }
                                                                            colorScheme={
                                                                                'blue'
                                                                            }>
                                                                            View
                                                                        </ChakraButton>
                                                                        <ChakraButton
                                                                            onClick={
                                                                                onDeallocateUsers
                                                                            }
                                                                            display={
                                                                                'flex'
                                                                            }
                                                                            size={
                                                                                'sm'
                                                                            }
                                                                            isLoading={
                                                                                userNumberState.isLoading
                                                                            }
                                                                            justifyContent={
                                                                                'right'
                                                                            }
                                                                            colorScheme={
                                                                                userNumberState.deallocate
                                                                                    ? 'red'
                                                                                    : 'blackAlpha'
                                                                            }
                                                                            isDisabled={
                                                                                userNumberState.isDisabled
                                                                            }>
                                                                            Deallocate
                                                                        </ChakraButton>
                                                                    </>
                                                                )}
                                                            </Box>
                                                            <Box
                                                                display={'flex'}
                                                                flexDir={'row'}
                                                                h={8}>
                                                                {raNumberState.showButton && (
                                                                    <>
                                                                        <ChakraButton
                                                                            mr={
                                                                                1
                                                                            }
                                                                            onClick={() =>
                                                                                viewNavigate(
                                                                                    '/resource-accounts',
                                                                                )
                                                                            }
                                                                            display={
                                                                                'flex'
                                                                            }
                                                                            size={
                                                                                'sm'
                                                                            }
                                                                            justifyContent={
                                                                                'right'
                                                                            }
                                                                            colorScheme={
                                                                                'blue'
                                                                            }>
                                                                            View
                                                                        </ChakraButton>
                                                                        <ChakraButton
                                                                            onClick={
                                                                                onDeallocateRA
                                                                            }
                                                                            display={
                                                                                'flex'
                                                                            }
                                                                            size={
                                                                                'sm'
                                                                            }
                                                                            isLoading={
                                                                                raNumberState.isLoading
                                                                            }
                                                                            justifyContent={
                                                                                'right'
                                                                            }
                                                                            colorScheme={
                                                                                raNumberState.deallocate
                                                                                    ? 'red'
                                                                                    : 'blackAlpha'
                                                                            }
                                                                            isDisabled={
                                                                                raNumberState.isDisabled
                                                                            }>
                                                                            Deallocate
                                                                        </ChakraButton>
                                                                    </>
                                                                )}
                                                            </Box>
                                                            <Box h={8}>
                                                                {numberBlockState.showButton && (
                                                                    <ChakraButton
                                                                        onClick={
                                                                            onDeallocateNB
                                                                        }
                                                                        display={
                                                                            'flex'
                                                                        }
                                                                        size={
                                                                            'sm'
                                                                        }
                                                                        isLoading={
                                                                            numberBlockState.isLoading
                                                                        }
                                                                        justifyContent={
                                                                            'right'
                                                                        }
                                                                        colorScheme={
                                                                            numberBlockState.deallocate
                                                                                ? 'red'
                                                                                : 'blackAlpha'
                                                                        }
                                                                        isDisabled={
                                                                            numberBlockState.isDisabled
                                                                        }>
                                                                        Deallocate
                                                                    </ChakraButton>
                                                                )}
                                                            </Box>
                                                            <Box h={8}>
                                                                {deactivateState.showButton && (
                                                                    <ChakraButton
                                                                        onClick={
                                                                            onDeactivateCompany
                                                                        }
                                                                        display={
                                                                            'flex'
                                                                        }
                                                                        size={
                                                                            'sm'
                                                                        }
                                                                        isLoading={
                                                                            deactivateState.isLoading
                                                                        }
                                                                        justifyContent={
                                                                            'right'
                                                                        }
                                                                        colorScheme={
                                                                            deactivateState.deallocate
                                                                                ? 'red'
                                                                                : 'blackAlpha'
                                                                        }
                                                                        isDisabled={
                                                                            deactivateState.isDisabled
                                                                        }>
                                                                        Deactivate
                                                                    </ChakraButton>
                                                                )}
                                                            </Box>
                                                        </VStack>
                                                    </HStack>
                                                </HStack>
                                            </Stack>
                                        )}
                                    </ModalBody>

                                    <ModalFooter>
                                        <ChakraButton
                                            colorScheme="blue"
                                            mr={3}
                                            onClick={onClose}>
                                            Close
                                        </ChakraButton>
                                        <ChakraButton
                                            isLoading={deleteState.isLoading}
                                            colorScheme={
                                                deleteState.delete
                                                    ? 'red'
                                                    : 'blackAlpha'
                                            }
                                            onClick={onCompanyDeleteHandler}
                                            isDisabled={deleteState.isDisabled}>
                                            Delete
                                        </ChakraButton>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </>
                    )}
                </Stack>
                <div className="pb-3.5" />
            </Box>
        </ChakraProvider>
    );
};

export default Admin;
