import React, { Suspense } from 'react';
// Dashboards
const CallStats = React.lazy(() => import('pages/dashboards/callStats'));
// Accounts
import { UsersRoutes } from '@/routes/users/index.routes';
import Admins from 'pages/accounts/admins/Index';
import Permissions from 'pages/accounts/permissions/Index';
import Templates from 'pages/accounts/templates/Index';

// Automation
import { Engine, Jobs, Triggers } from 'pages/automation';

// Billing
import { InternationalRatesList, Invoices, Plans } from 'pages/billing/';

// Organisations
import { Companies, Partners, WholesalerIndex } from 'pages/organisations/';
import Addresses from 'pages/organisations/addresses';

// Services
import { OperatorProfiles } from 'pages/services';
import ServiceDashboard from 'pages/services/new/ServiceDashboard';
import NewServiceProfiles from 'pages/services/serviceprofiles/new';

// Approval codes
import ApprovalCodes from 'pages/automation/approval-codes';

// Telephony
import {
    Calls,
    Carriers,
    Dial,
    NumberBlocks,
    Numbers,
    Regions,
    SpecialNumbers,
    TrunkTypes,
    Trunks,
    VoicePolicies,
} from 'pages/telephony';

// Settings

import Countries from 'pages/countries';
import ServiceCategories from 'pages/services/serviceCategories';

import { BiSolidDashboard } from 'react-icons/bi';
import {
    FaBroadcastTower,
    FaBuilding,
    FaChartLine,
    FaClone,
    FaCodeBranch,
    FaCogs,
    FaGlobe,
    FaGlobeAmericas,
    FaHammer,
    FaIndustry,
    FaKey,
    FaLaptopCode,
    FaPhoneVolume,
    FaShapes,
    FaSitemap,
} from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { HiCurrencyDollar, HiUser } from 'react-icons/hi';
import {
    MdAccountBox,
    MdAdminPanelSettings,
    MdCall,
    MdCallSplit,
    MdContactPage,
    MdDeviceHub,
    MdDialpad,
    MdFormatListNumbered,
    MdInsights,
    MdLocationCity,
    MdLocationOn,
    MdMap,
    MdOutlineCode,
    MdPeople,
    MdPermDataSetting,
    MdReceipt,
    MdViewModule,
    MdWork,
    MdCategory as CategoryIcon,
} from 'react-icons/md';
export const routes = [
    {
        label: 'Dashboards',
        Icon: BiSolidDashboard,
        parentPath: '',
        rootItem: true,
        scope: 20,
        children: [
            {
                path: '/dashboard-services',
                element: <ServiceDashboard />,
                permissionName: 'dashboardServices',
                label: 'Services',
                scope: 20,
                Icon: FaHammer,
            },
            {
                path: '/call-stats',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CallStats />
                    </Suspense>
                ),
                permissionName: 'callStatistics',
                label: 'Call Stats',
                scope: 20,
                Icon: FaChartLine,
            },
        ],
    },
    {
        label: 'Accounts',
        Icon: MdAccountBox,
        parentPath: 'accounts',
        rootItem: true,
        scope: 20,
        children: [
            {
                path: '/admins',
                element: <Admins />,
                permissionName: 'adminusers',
                label: 'Admins',
                scope: 20,
                Icon: FaUserGroup,
            },
            {
                path: '/permissions',
                element: <Permissions />,
                permissionName: 'permissions',
                label: 'Permissions',
                Icon: FaKey,
                scope: 20,
            },
            {
                path: '/resource-accounts',
                element: <MdAccountBox />,
                permissionName: 'Users',
                label: 'Resource Accounts',
                scope: 20,
                Icon: MdAccountBox,
            },
            {
                path: '/users',
                element: <UsersRoutes />,
                // element: <Users />,
                permissionName: 'users',
                label: 'Users',
                scope: 20,
                Icon: HiUser,
            },
        ],
    },
    {
        label: 'Automation',
        Icon: MdDeviceHub,
        parentPath: 'automation',
        rootItem: true,
        scope: 20,
        children: [
            {
                label: 'API',
                path: '/api',
                element: () => {
                    window.location.href = 'https://example.com/1234';
                    return null;
                },
                permissionName: 'api',
                Icon: MdOutlineCode,
                scope: 20,
            },
            {
                label: 'Triggers',
                path: '/triggers',
                element: <Triggers />,
                permissionName: 'triggers',
                Icon: MdDeviceHub,
                scope: 20,
            },
            {
                label: 'Jobs',
                path: '/jobs',
                element: <Jobs />,
                permissionName: 'jobs',
                Icon: MdWork,
                scope: 20,
            },
            {
                path: '/approval-codes',
                element: <ApprovalCodes />,
                permissionName: 'approvalCodes',
                label: 'Approval Codes',
                scope: 40,
                Icon: FaLaptopCode,
            },
        ],
    },
    {
        label: 'Billing',
        Icon: HiCurrencyDollar,
        parentPath: 'billing',
        rootItem: true,
        scope: 20,
        children: [
            {
                path: '/international-rates',
                element: <InternationalRatesList />,
                permissionName: 'internationalratelists',
                label: 'International Rates',
                scope: 40,
                Icon: FaGlobeAmericas,
            },
            {
                path: '/invoices',
                element: <Invoices />,
                permissionName: 'invoices',
                scope: 20,
                label: 'Invoices',
                Icon: MdReceipt,
            },
            {
                path: '/plans',
                element: <Plans />,
                permissionName: 'plans',
                label: 'Plans',
                scope: 40,
                Icon: MdFormatListNumbered,
            },
        ],
    },
    {
        label: 'Organisations',
        Icon: FaSitemap,
        parentPath: 'organisations',
        rootItem: true,
        scope: 20,
        children: [
            {
                path: '/companies',
                element: <Companies />,
                permissionName: 'companies',
                label: 'Companies',
                scope: 40,
                Icon: FaBuilding,
            },
            {
                path: '/partners',
                element: <Partners />,
                permissionName: 'partners',
                scope: 60,
                label: 'Partners',
                Icon: MdLocationCity,
            },
            {
                path: '/addresses',
                element: <Addresses />,
                permissionName: 'addresses',
                scope: 20,
                label: 'Addresses',
                Icon: MdMap,
            },
            {
                path: '/service-profiles',
                element: <NewServiceProfiles />,
                permissionName: 'serviceprofiles',
                label: 'Service Profiles',
                scope: 40,
                Icon: MdPeople,
            },
        ],
    },
    {
        label: 'Telephony',
        Icon: MdCall,
        parentPath: 'telephony',
        rootItem: true,
        scope: 20,
        children: [
            {
                path: '/calls',
                element: <Calls />,
                permissionName: 'calldetailrecords',
                label: 'Calls',
                scope: 20,
                Icon: (props) => (
                    <FaPhoneVolume
                        style={{ transform: 'rotate(315deg)' }}
                        {...props}
                    />
                ),
            },
            {
                path: '/dial-plans',
                element: <Dial />,
                permissionName: 'dialplans',
                label: 'Dial Plans',
                scope: 80,
                Icon: MdCallSplit,
            },

            {
                path: '/numbers',
                element: <Numbers />,
                permissionName: 'numbers',
                scope: 20,
                label: 'Numbers',
                Icon: MdDialpad,
            },
            {
                path: '/number-blocks',
                element: <NumberBlocks />,
                permissionName: 'numberblocks',
                scope: 40,
                label: 'Number Blocks',
                Icon: MdViewModule,
            },
            {
                path: '/trunks',
                element: <Trunks />,
                permissionName: 'trunks',
                label: 'Trunks',
                scope: 20,
                Icon: (props) => (
                    <FaCodeBranch
                        {...props}
                        style={{
                            transform: 'rotate(90deg)',
                        }}
                    />
                ),
            },
            {
                path: '/voice-policies',
                element: <VoicePolicies />,
                permissionName: 'voicepolicies',
                label: 'Voice Policies',
                scope: 80,
                Icon: MdPermDataSetting,
            },
        ],
    },
    {
        label: 'GA',
        Icon: MdAdminPanelSettings,
        parentPath: 'globaladmin',
        rootItem: true,
        scope: 80,
        children: [
            {
                path: '/templates',
                element: <Templates />,
                permissionName: 'templates',
                label: 'Templates',
                scope: 80,
                Icon: FaClone,
            },
            {
                path: '/service-categories',
                element: <ServiceCategories />,
                permissionName: 'service-categories',
                label: 'Service Categories',
                scope: 80,
                Icon: CategoryIcon,
            },
            {
                label: 'Engines',
                path: '/engines',
                element: <Engine />,
                permissionName: 'engines',
                Icon: FaCogs,
                scope: 80,
            },
            {
                path: '/wholesalers',
                element: <WholesalerIndex />,
                permissionName: 'wholesalers',
                label: 'Wholesalers',
                scope: 80,
                Icon: FaIndustry,
            },
            {
                path: '/carriers',
                element: <Carriers />,
                permissionName: 'carriers',
                label: 'Carriers',
                scope: 80,
                Icon: FaBroadcastTower,
            },
            {
                path: '/regions-and-locations',
                element: <Regions />,
                permissionName: 'trunklocations',
                label: 'Regions and Locations',
                scope: 80,
                Icon: MdLocationOn,
            },
            {
                path: '/countries',
                element: <Countries />,
                permissionName: 'countries',
                label: 'Countries',
                scope: 80,
                Icon: FaGlobe,
            },
            {
                path: '/special-numbers',
                element: <SpecialNumbers />,
                permissionName: 'specialnumbers',
                scope: 80,
                label: 'Special Numbers',
                Icon: (props) => (
                    <FaShapes
                        {...props}
                        style={{
                            transform: 'rotate(90deg)',
                        }}
                    />
                ),
            },
            {
                path: '/trunk-types',
                element: <TrunkTypes />,
                permissionName: 'trunktypes',
                scope: 80,
                label: 'Trunk Types',
                Icon: MdInsights,
            },
            {
                path: '/operator-connect-profiles',
                element: <OperatorProfiles />,
                permissionName: 'operatorprofiles',
                scope: 80,
                label: 'Operator Connect Profiles',
                Icon: MdContactPage,
            },
        ],
    },
];
