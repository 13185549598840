import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, AppBar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Worker from '@/utils/background-tasks';
import { useThemeHelper } from '@tcap/data-router/hooks/';
import { DashboardMenuProvider } from '@/context/DashboardMenuContext';
import { LayoutProvider } from '@/context/LayoutContext';

import '@/App.css';
import '@/console.js';

const Sidebar = lazy(() => import('@/components/sidebar/Sidebar'));
const Navbar = lazy(() => import('@/pages/navbar/Navbar'));

function ProtectedLayout() {
    const dispatch = useDispatch();
    const { classes, mixedMode, mixedModeTheme, theme } = useThemeHelper();

    const {
        token,
        reLogin,
        showMFA,
        defaultPartner,
        defaultCompany,
        defaultWholesaler,
    } = useSelector((state) => state.login);
    const { currentWholesaler } = useSelector((state) => state.navigationLists);

    useEffect(() => {
        if (
            (defaultPartner || defaultCompany || defaultWholesaler) &&
            !currentWholesaler
        ) {
            //if current stuffs arent set yet, set them to default.
            dispatch({
                type: 'CHANGE_CURRENT_PARTNER',
                payload: defaultPartner,
            });
            dispatch({
                type: 'CHANGE_CURRENT_COMPANY',
                payload: defaultCompany,
            });
            dispatch({
                type: 'CHANGE_CURRENT_WHOLESALER',
                payload: defaultWholesaler,
            });
        }
    }, [currentWholesaler]);

    if (!token || reLogin || showMFA) {
        return <Navigate to="/" replace />;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<></>}>
                <div className={classes.root}>
                    <LayoutProvider>
                        <ThemeProvider theme={mixedMode && mixedModeTheme}>
                            <AppBar position="fixed" className={classes.appBar}>
                                <Worker />
                                <Navbar />
                            </AppBar>
                            <Drawer
                                className={`${classes.drawer}`}
                                variant="permanent"
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                style={{
                                    transition: 'width 0.4s ease-in-out',
                                }}>
                                <Sidebar />
                            </Drawer>
                        </ThemeProvider>
                        <main className={classes.content}>
                            <DashboardMenuProvider>
                                <Outlet />
                            </DashboardMenuProvider>
                        </main>
                    </LayoutProvider>
                </div>
            </Suspense>
        </ThemeProvider>
    );
}

export default ProtectedLayout;
