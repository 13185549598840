import { AddIcon } from '@chakra-ui/icons';
import {
    Box,
    Link as ChakraLink,
    Flex,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    SkeletonText,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    Alert,
    AlertIcon,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Text,
} from '@/components/v4';

import { ChakraSpinner } from '@/lib/loading-screen';
import { useAssociatedAccounts, useRequestNumber } from 'hooks/useCustomQuery/';
import { useNavigateLocation } from 'hooks/useNavigateLocation/useNavigateLocation';
import { QueueStatusMessage, useTpmContext } from './';

import { postRequestNumberVerification } from 'API/TPMTrial';
import walkthroughIds from './walkthroughIds';

import {
    CONTINUE,
    ERROR,
    FOUND,
    REQUEST_NUMBER,
    RESET,
} from '@/store/constants';
import {
    associatedInstructionBody,
    delay,
    keywords,
    noAssociatedInstructionBody,
    path,
    PHONE_INVALID_FORMAT,
    trialEndInstructionBody,
} from './constants';
import InstructionMessages from './InstructionMessages';

function TpmTrialProcess() {
    const {
        tpmNumber: [state, setState],
    } = useTpmContext();
    const [operatorStatus, setOperatorStatus] = useState('idle'); // idle, loading, queue, pending, PAUSED
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const intervalRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { handleNavigateLocation } = useNavigateLocation();
    const location = useLocation();
    const { darkMode } = useSelector((state) => state.settings);
    const { currentCompanyData: companyData } = useSelector(
        (state) => state.navigationLists,
    );
    const {
        number: mpn,
        error: tpmError,
        currentStep,
        operatorStatus: oStatus,
    } = useSelector((state) => state.service.tpm);

    const { ufPhoneNumberInput, ufNextButton, ufCancelButton } =
        walkthroughIds.teamsPhoneMobileTrial;

    const isQueueExpired = state?.error?.includes(
        'the dynamic search has expired',
    );
    const isNotAvailable = state?.error?.includes('not available');
    const isDisabled = isQueueExpired || state?.error;
    const hasValidated = state?.error?.includes('already been validated');
    const isExistingVerification = (state?.error || tpmError)?.includes(
        'existing verification',
    );
    const isTrialExpired =
        companyData?.isTrial && Date.parse(companyData?.trialEnd) < Date.now();

    const {
        data: associatedAccounts,
        isLoading,
        isFetching,
        refetch,
    } = useAssociatedAccounts(id);

    const handleChangeNumber = (e) => {
        setState({
            status: 'SUBMITTING',
            number: e.target.value,
        });
    };
    const handleBlur = () => {
        setState((prev) => ({
            ...prev,
            touched: prev.number !== '' && true,
            error: !prev?.number?.match('^[1-9][0-9]+$')
                ? PHONE_INVALID_FORMAT
                : '',
        }));
    };
    const handleBackButtonClick = () => {
        setState((prev) => ({
            ...prev,
            status: 'IDLE',
            error: null,
        }));
        setOperatorStatus('idle');
        if (associatedAccounts?.length) {
            refetch();
            navigate('associated-accounts');
        } else {
            dispatch({
                type: CONTINUE,
            });
            navigate('/dashboard-services');
        }
    };

    const handleReset = () => {
        setState({
            number: '',
            status: 'IDLE',
            error: null,
        });
        setOperatorStatus('idle');
        dispatch({
            type: RESET,
        });
    };

    const _success = useCallback(() => {
        setState({
            status: 'SUCCESS',
            number: state.number || mpn,
        });
        dispatch({
            type: FOUND,
        });
        navigate({
            pathname: `/services/tpm/${id}/activation/sms-authorization`,
            search: `?number=${(state.number || mpn)?.replace(/\d(?=\d{4})/g, 'X')}`,
        });
    }, [state.number, mpn, navigate, id, setState, dispatch]);
    const _error = useCallback(
        (error) => {
            setOperatorStatus('error');
            const msg =
                error?.response?.data?.message ||
                'Error submitting request. Please try again.';
            setState({
                status: 'ERROR',
                error: msg,
                number: state.number || mpn,
            });
            dispatch({
                type: ERROR,
                payload: msg,
            });
        },
        [dispatch, mpn, setState, state.number],
    );
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!id) {
            window.alert('no valid ID specified');
            return;
        }

        setHasSubmitted(true);
        setState({
            status: 'LOADING',
            number: state.number,
            error: null,
        });

        setOperatorStatus('queued');
        dispatch({
            type: REQUEST_NUMBER,
            payload: { number: state.number },
        });
        postRequestNumberVerification({
            serviceID: id,
            phonenumber: state.number,
        })
            .then(() => {
                _success();
            })
            .catch((error) => {
                _error(error);
            });
    };

    const instructionBody = !associatedAccounts?.length
        ? noAssociatedInstructionBody
        : associatedInstructionBody;
    const requestNumber = useRequestNumber(id, mpn, delay);
    const mutateOptions = useCallback(
        () => ({
            onSuccess: () => {
                _success();
            },
            onError: (error) => {
                _error(error);
            },
        }),
        [_error, _success],
    );

    const startQueue = useCallback(() => {
        dispatch({ type: CONTINUE });
        setOperatorStatus('pending');
        postRequestNumberVerification({
            serviceID: id,
            phonenumber: mpn,
        })
            .then(() => {
                _success();
            })
            .catch((error) => {
                _error(error);
            });

        const now = new Date();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const nextBlock = Math.ceil((minutes + 1) / 15) * 15;
        const waitTime =
            (nextBlock - minutes - 1) * 60 * 1000 + (60 - seconds) * 1000;
        setTimeout(() => {
            intervalRef.current = setInterval(
                () => {
                    requestNumber.mutate(
                        {
                            phonenumber: mpn,
                        },
                        {
                            ...mutateOptions,
                        },
                    );
                },
                15 * 60 * 1000,
            );
        }, waitTime);
    }, [id, mpn, requestNumber, _error, _success, mutateOptions]);

    const pollingProps = {
        id,
        delay,
        mutateOptions,
        mpn,
        tpmError,
    };

    let tpmContent;
    if (isTrialExpired) {
        tpmContent = (
            <>
                <CardBody py={0}>
                    <Alert status="info" className="max-w-sm">
                        <InstructionMessages
                            instructionBody={trialEndInstructionBody}
                            darkMode={darkMode}
                            keywords={keywords}
                        />
                    </Alert>
                </CardBody>
                <CardFooter alignItems="center" justifyContent="center">
                    <Button
                        variant="outline"
                        w={{ base: 'full', md: '130px' }}
                        bg="white"
                        onClick={() => {
                            if (location.state?.from) {
                                navigate(location.state.from);
                            } else {
                                handleNavigateLocation('/dashboard-services');
                            }
                        }}
                        data-walkthroughid={ufCancelButton}>
                        Back
                    </Button>
                </CardFooter>
            </>
        );
    } else {
        tpmContent = (
            <>
                <CardBody pb={0}>
                    <FormControl
                        data-walkthroughid={ufPhoneNumberInput}
                        isInvalid={state.touched && state.error}>
                        <FormLabel
                            htmlFor="activate-number"
                            color={darkMode ? 'white' : 'gray.700'}>
                            Enter Mobile Number
                        </FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <AddIcon
                                    fontSize="xs"
                                    color={darkMode ? 'gray.100' : null}
                                />
                            </InputLeftElement>
                            <Input
                                type="text"
                                id="activate-number"
                                value={state.number}
                                onChange={handleChangeNumber}
                                onBlur={handleBlur}
                                className="!px-8"
                            />
                        </InputGroup>
                    </FormControl>
                    {isLoading || isFetching ? (
                        <SkeletonText my={4} noOfLines={4} spacing={4} />
                    ) : (
                        <Alert
                            mt={5}
                            status={
                                !associatedAccounts?.length ? 'success' : 'info'
                            }
                            className="max-w-sm">
                            <InstructionMessages
                                instructionBody={instructionBody}
                                darkMode={darkMode}
                                keywords={keywords}
                            />
                        </Alert>
                    )}

                    {state?.touched && state?.error && (
                        <Alert status="error" className="mb-7 mt-4 max-w-sm">
                            <AlertIcon />
                            <Text>{state.error}</Text>
                        </Alert>
                    )}
                </CardBody>

                <CardFooter alignItems="center" justifyContent="space-between">
                    <Button
                        variant="outline"
                        w={{ base: 'full', md: '130px' }}
                        bg="white"
                        onClick={() => {
                            if (location.state?.from) {
                                navigate(location.state.from);
                            } else {
                                handleNavigateLocation('/dashboard-services');
                            }
                        }}
                        data-walkthroughid={ufCancelButton}>
                        Back
                    </Button>
                    <Button
                        type="submit"
                        w={{ base: 'full', md: '130px' }}
                        isDisabled={
                            !state.number ||
                            state.status === 'LOADING' ||
                            state.error
                        }
                        data-walkthroughid={ufNextButton}>
                        Next
                    </Button>
                </CardFooter>
            </>
        );
    }

    useEffect(() => {
        if (!associatedAccounts?.length && currentStep === 2) {
            navigate({
                pathname: `/services/tpm/${id}/activation/sms-authorization`,
                search: `?number=${(state.number || mpn)?.replace(/\d(?=\d{4})/g, 'X')}`,
            });
        }
    }, [associatedAccounts, currentStep, id, mpn, navigate, state.number]);

    useEffect(() => {
        if (isQueueExpired || isNotAvailable || hasValidated) {
            dispatch({ type: RESET });
        }
    }, [isQueueExpired, isNotAvailable, hasValidated, dispatch]);

    useEffect(() => {
        let isMounted = true;
        if (
            mpn &&
            (oStatus === 'queued' || oStatus === 'pending') &&
            isMounted &&
            !hasSubmitted
        ) {
            startQueue();
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            isMounted = false;
        };
    }, [mpn, oStatus, dispatch, hasSubmitted]);

    if (!id || id === 'undefined') {
        navigate('/dashboard-services');
    }

    if (
        operatorStatus !== 'idle' ||
        (currentStep !== 0 &&
            (oStatus === 'queued' ||
                oStatus === 'pending' ||
                operatorStatus === 'pending'))
    ) {
        return (
            <Card bg={darkMode ? 'dark.bgDarkGray' : 'white'}>
                <CardHeader>
                    <Text className="text-lg font-semibold">
                        {!isDisabled && operatorStatus !== 'error'
                            ? 'Operator Validation is in progress'
                            : 'Operator Validation Error'}
                    </Text>
                </CardHeader>
                <CardBody py={0}>
                    <QueueStatusMessage
                        operatorStatus={operatorStatus}
                        state={state}
                        darkMode={darkMode}
                        {...pollingProps}
                    />
                </CardBody>

                {(oStatus === 'pending' || oStatus === 'queued') &&
                    operatorStatus !== 'error' &&
                    !state?.error && (
                        <Flex
                            mt="10px"
                            alignItems="center"
                            justifyContent="center">
                            <ChakraSpinner />
                        </Flex>
                    )}

                {isExistingVerification && (
                    <ChakraLink
                        as={Link}
                        to={{
                            pathname: `${path.SMS_AUTHORIZATION.replace('/', '')}`,
                            search: `?number=${(state?.number || mpn)?.replace(/\d(?=\d{4})/g, 'X')}`,
                        }}
                        mt={2.5}
                        mr="1.25rem"
                        textAlign="right">
                        <Button variant="link" fontWeight="normal">
                            Go to SMS Authorization
                        </Button>
                    </ChakraLink>
                )}
                <CardFooter
                    alignItems="center"
                    justifyContent={
                        tpmError || operatorStatus === 'error'
                            ? 'space-between'
                            : 'space-evenly'
                    }>
                    <Button
                        height="44px"
                        variant="outline"
                        borderRadius="md"
                        minW="130px"
                        onClick={handleBackButtonClick}>
                        Back
                    </Button>
                    {(tpmError || operatorStatus === 'error') && (
                        <Button
                            height="44px"
                            borderRadius="md"
                            onClick={handleReset}>
                            New Number
                        </Button>
                    )}
                </CardFooter>
            </Card>
        );
    }

    return (
        <Box className="py-16">
            <Box as="form" onSubmit={handleSubmit}>
                <Card bg={darkMode ? 'dark.tcap' : 'white'}>
                    <CardHeader>
                        <Text className="text-lg font-semibold">
                            TPM Activation
                        </Text>
                    </CardHeader>
                    {tpmContent}
                </Card>
            </Box>
        </Box>
    );
}

export default TpmTrialProcess;
