import { Row, Col, Container, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import walkthroughIds from '../walkthroughIds';
import {
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import ChakraButton from 'components/v4/Button';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import chakratheme from '@/chakratheme';
import ChakraAlert from 'components/v4/Alert';
import { axios } from '@/services/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function Admin({ reset }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const { child } = useSelector((state) => {
        return { ...state.table };
    });

    const handleDelete = () => {
        setIsLoading(true);
        axios.delete(`/numberblock/${child?.id}`)
        .then(() => {
            toast.success("Number block deleted successfully!");
            // reset modal, table and multichild
            reset();
        })
        .catch((err) => {
            toast.error(err?.response?.data?.message);
        })
        .finally(setIsLoading(false));
    }
    return (
        <>
            <Heading
                fontSize={'1.5rem'}
                lineHeight={'1.2'}
                mb={'0.5rem'}
                fontWeight={'500'}>
                {' '}
                Danger Zone{' '}
            </Heading>
            <Container fluid className="roundBox danger">
                <Row style={{ minHeight: 60 }}>
                    <Col style={{ minWidth: '60%' }}>
                        <b> Delete Number Block </b> <br /> If you want to
                        remove the number block from a partner and/or company,
                        you should set it to "Not allocated", rather than
                        deleting. To delete a number block, each number inside
                        the block must be not allocated, and must be in OK
                        status.
                    </Col>
                    <Col
                        style={{
                            minWidth: '40%',
                            textAlign: 'right',
                        }}>
                        <Button
                            style={{ marginTop: 10 }}
                            variant="danger"
                            onClick={() => {
                                onOpen();
                            }}
                            data-walkthroughid={walkthroughIds.ufDeleteButton}>
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ChakraProvider resetCSS theme={chakratheme}>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent minW={'30%'}>
                        <ModalHeader>
                            Delete Number Block : +{child?.first} - +
                            {child?.last}
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this number block?
                            If you want to remove the number block from a
                            partner and/or company, you should set it to "Not
                            allocated", rather than deleting. Once the number
                            block is deleted, it cannot be reinstated.
                            <br />
                            <ChakraAlert
                                status='warning'
                                style={{ marginBottom: 10, marginTop: 10 }}>
                                This action cannot be undone.
                            </ChakraAlert>
                        </ModalBody>
                        <ModalFooter>
                            <ChakraButton
                                colorScheme="blue"
                                mr={3}
                                onClick={onClose}>
                                Close
                            </ChakraButton>
                            <ChakraButton isLoading={isLoading} isDisabled={isLoading} onClick={handleDelete} colorScheme={'red'}>
                                Delete
                            </ChakraButton>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </>
    );
}
