import { axios } from '@/services/axios';
import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Skeleton,
    Tooltip,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, AlertIcon, Button, Heading, Text } from 'components/v4';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useServices } from './ServicesContext';
import ServicesAttributes from './attributes/ServicesAttributes';
import useServicesAttributesValidation from './attributes/useServicesAttributesValidation';

export default function Services({ userID, numberID, companyID }) {
    const { dispatch, services, originalData, attributesErrors } =
        useServices();
    const [isLoading, setIsLoading] = useState(false);
    const { definedAttributes: servicesCompany } = useSelector(
        (state) => state.navigationLists?.currentCompanyData,
    );
    const queryClient = useQueryClient();
    const queryState = queryClient.getQueryState(['companyID', companyID]);
    const servicesCompanyLoading = queryState?.fetchStatus === 'fetching';

    const { data: servicesData, isFetching: servicesLoading } = useQuery({
        // eg. user id
        queryKey: ['userID', userID],
        queryFn: async () => {
            const res = await axios.get(`user/${userID}/definedattributes`);
            return res.data;
        },
        enabled: Boolean(userID),
        onSuccess: (data) => {
            dispatch({
                type: 'ADD_SERVICES',
                payload: data,
            });
        },
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const { data: servicesNumber, isFetching: servicesNumberLoading } =
        useQuery({
            // eg. user id
            queryKey: ['numberID', numberID],
            queryFn: async () => {
                const res = await axios.get(
                    `number/${numberID}/definedattributes`,
                );
                return res.data;
            },
            enabled: Boolean(numberID),
            onSuccess: (data) => {
                dispatch({
                    type: 'ADD_SERVICES',
                    payload: data,
                });
            },
            onError: (err) => {
                toast.error(err?.response?.data?.message);
            },
            refetchOnWindowFocus: false,
            staleTime: 0,
        });

    const ufTitle = userID ? '/users' : numberID ? '/numbers' : '/companies';

    // TODO: uncomment this when we have a proper API for detecting company services 3.39
    // const { data: servicesCompany, isFetching: servicesCompanyLoading } =
    //     useQuery({
    //         // eg. user id
    //         queryKey: ['companyID', companyID],
    //         queryFn: async () => {
    //             const res = await axios.get(
    //                 `company/${companyID}/definedattributes`,
    //             );
    //             return res.data;
    //         },
    //         enabled: Boolean(companyID),
    //         onSuccess: (data) => {
    //             dispatch({
    //                 type: 'ADD_SERVICES',
    //                 payload: data,
    //             });
    //         },
    //         onError: (err) => {
    //             toast.error(err?.response?.data?.message);
    //         },
    //         refetchOnWindowFocus: false,
    //         staleTime: 0,
    //     });

    const [index, setIndex] = useState(
        Array.from(
            {
                length:
                    servicesData?.length ||
                    servicesNumber?.length ||
                    servicesCompany?.length,
            },
            (_, i) => i,
        ),
    );

    const {
        handleAttributeError,
        removeAttributeError,
        handleOGAttributeChange,
    } = useServicesAttributesValidation();

    const handleSubmit = async (servIndex) => {
        // get service and then attributes
        const serviceId = services[servIndex]?.service.id;
        const serviceAttributes = services[servIndex]?.attributes;
        let hasErrors = false;

        const errors = attributesErrors.some(
            (a) => a.serviceIndex === servIndex,
        );

        // if errors already exist, do not submit
        console.log(errors, 'att errors');
        if (errors) {
            toast.error(
                'There are some validation errors. Please check and try again.',
            );
            return;
        }

        // check for errors and required fields
        for (const [index, att] of serviceAttributes.entries()) {
            const validation = JSON.parse(att.definedAttributeValidationType);

            if (validation.Required && isEmpty(att.value)) {
                handleAttributeError(
                    index,
                    'This field is required.',
                    servIndex,
                );
                hasErrors = true;
            } else {
                removeAttributeError(index, servIndex);
            }
        }

        // has errors, do not proceed and show toast error
        if (hasErrors) {
            toast.error(
                'There are some validation errors. Please check and try again.',
            );
            return;
        }

        setIsLoading(true);

        // attributes to be updated
        for (const [index, att] of serviceAttributes.entries()) {
            try {
                // if attribute value are the same (no changes), skip the update
                if (
                    originalData[servIndex]?.attributes.some(
                        (b) => b.value === att.value,
                    )
                )
                    continue;
                const resourceID = userID || numberID || companyID;
                const resourceType = userID
                    ? 'user'
                    : numberID
                      ? 'number'
                      : 'company';

                // need to pass serviceId for number and user defined attributes
                if (resourceType != 'company') {
                    await axios.put(
                        `/${resourceType}/${resourceID}/service/${serviceId}/definedattribute`,
                        {
                            ID: att.id,
                            value: att.value,
                        },
                    );
                } else {
                    await axios.put(
                        `/${resourceType}/${resourceID}/definedattribute`,
                        {
                            ID: att.id,
                            value: att.value,
                        },
                    );
                }

                // update original data with new value as it is successful
                handleOGAttributeChange(att.value, index, servIndex);
            } catch (e) {
                // need to add specific error message from the backend to the attribute error
                hasErrors = true;
                handleAttributeError(
                    index,
                    e?.response?.data?.message,
                    servIndex,
                );
            }
        }

        // error from backend, show toast error
        if (hasErrors) {
            toast.error(
                'There are some validation errors. Please check and try again.',
            );
            setIsLoading(false);
            return;
        }

        toast.success(
            `Successfully updated attributes for ${services[servIndex].service.name}!`,
        );

        setIsLoading(false);
    };

    useEffect(() => {
        if (servicesCompany) {
            dispatch({
                type: 'ADD_SERVICES',
                payload: servicesCompany,
            });
        }
    }, [servicesCompany, dispatch]);

    return servicesLoading ||
        servicesNumberLoading ||
        servicesCompanyLoading ? (
        <Accordion>
            {[...Array(5)].map((_, index) => (
                <AccordionItem key={index}>
                    <AccordionButton>
                        <Skeleton width={'100%'} height={'20px'} />

                        <AccordionIcon />
                    </AccordionButton>
                </AccordionItem>
            ))}
        </Accordion>
    ) : (
        <>
            {originalData?.length > 0 ? (
                <Accordion
                    index={index}
                    onChange={(e) => setIndex(e)}
                    allowMultiple
                    reduceMotion
                    allowToggle>
                    {originalData.map((serv, servIndex) => (
                        <AccordionItem key={serv?.service.id}>
                            <AccordionButton
                                as={'div'}
                                minH={'3rem'}
                                data-walkthroughid={
                                    ufTitle +
                                    '/services' +
                                    `/${serv.service.name}`
                                }>
                                <Heading
                                    as="h3"
                                    fontSize="1.5rem"
                                    fontWeight={500}
                                    flex="1"
                                    textAlign="left">
                                    {serv.service.name}
                                </Heading>

                                {serv.activated ? (
                                    <Tooltip label={'Activated'}>
                                        <CheckCircleIcon
                                            mr={2}
                                            color={'green.500'}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip label={'Disabled'}>
                                        <NotAllowedIcon
                                            mr={2}
                                            color={'red.500'}
                                        />
                                    </Tooltip>
                                )}
                                {serv.attributes?.some(
                                    (att, index) =>
                                        att.value !=
                                        services[servIndex]?.attributes[index]
                                            ?.value,
                                ) && (
                                    <Button
                                        data-walkthroughid={
                                            '/services/button/save'
                                        }
                                        size={'sm'}
                                        mr={2}
                                        isLoading={isLoading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit(servIndex);
                                        }}>
                                        Save
                                    </Button>
                                )}
                                <AccordionIcon />
                            </AccordionButton>

                            {serv.attributes?.length > 0 ? (
                                <AccordionPanel>
                                    <ServicesAttributes
                                        serviceIndex={servIndex}
                                        isBusy={isLoading}
                                    />
                                </AccordionPanel>
                            ) : (
                                <AccordionPanel>
                                    <Text>
                                        No additional information required for
                                        this service.
                                    </Text>
                                </AccordionPanel>
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
            ) : (
                <Alert status="info">
                    <AlertIcon />
                    No services activated.
                </Alert>
            )}
        </>
    );
}
