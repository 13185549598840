import { useSelector, useDispatch } from 'react-redux';
import { Box, FormControl, FormLabel, Stack } from '@chakra-ui/react';

import { Checkbox } from '@/components/v4';
import walkthroughIds from '../walkthroughIds';

// Render Admin tab of Create Admin
const AdminForm = (props) => {
    const dispatch = useDispatch();
    const { state: modalState } = useSelector((state) => {
        return {
            ...state.modal,
        };
    });

    const { ufApiDocReader, ufOverrideOverstampList } =
        walkthroughIds.adminsAdmin;

    const apiChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                apiDocReader: !modalState?.apiDocReader,
            },
        });
    };

    const overrideChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                canOverrideOverStamping: !modalState?.canOverrideOverStamping,
            },
        });
    };

    return (
            <Stack spacing="3" mt='2px'>
                <FormControl>
                    <Box className="flex items-center">
                        <Checkbox
                            isChecked={modalState.canOverrideOverStamping}
                            onChange={overrideChangeHandler}
                            name="canOverrideOverStamping"
                            colorScheme="whatsapp"
                            mb={0}
                            data-walkthroughid={ufOverrideOverstampList}
                        />
                        <FormLabel mb={0} ml={2}>
                            Override Overstamp List
                        </FormLabel>
                    </Box>
                </FormControl>

                <FormControl>
                    <Box className="flex items-center">
                        <Checkbox
                            isChecked={modalState.apiDocReader}
                            onChange={apiChangeHandler}
                            name="apiDocReader"
                            colorScheme="whatsapp"
                            mb={0}
                            data-walkthroughid={ufApiDocReader}
                        />
                        <FormLabel mb={0} ml={2}>
                            API Doc Reader
                        </FormLabel>
                    </Box>
                </FormControl>
            </Stack>
    );
};

export default AdminForm;
