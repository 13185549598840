import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { InputLabel } from '@material-ui/core';
import { Box } from '@chakra-ui/react';

import { classNames } from '@/utils/classNames';

import { PhoneNumber } from '../../../../components/maintenance/NumberFormatCustom';

/*
 *
 */
const ExistingNumbersTable = ({ setState, dataWalkthroughId }) => {
    const dispatch = useDispatch();
    const { state: modalState, stateError: modalStateError } = useSelector(
        (state) => {
            return { ...state.modal };
        },
    );

    const { darkMode } = useSelector((state) => state.settings);

    const getTableState = () => {
        return modalState?.aggregateTable ?? [];
    };
    const getTableStateError = () => {
        return modalStateError?.aggregateTable ?? [];
    };
    const setTableStateError = (payload) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE_ERROR',
            payload: { ...modalStateError, aggregateTable: payload },
        });
    };

    const setTableState = (payload) => {
        setState({ aggregateTable: payload });
    };
    useEffect(() => {
        if (!modalState?.aggregateTable) {
            setTableState([{ id: 0, from: '', to: '' }]);
        }
    }, [modalState?.aggregateTable]);

    return (
        <>
            <InputLabel> Numbers to Port </InputLabel>
            <Box
                sx={{
                    '& .table-bordered td, & .table-bordered th': {
                        border: `1px solid ${
                            darkMode ? '#444' : '#ddd'
                        } !important`,
                        borderRadius: '0.375rem',
                    },
                }}>
                <Table
                    bordered
                    responsive
                    data-walkthroughid={dataWalkthroughId}>
                    <thead>
                        <tr>
                            <th className="bootstrapTableRow heading">From</th>
                            <th className="bootstrapTableRow heading">To</th>
                            <th className="bootstrapTableRow heading"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTableState()
                            ?.sort((left, right) => {
                                return left?.id - right?.id;
                            })
                            .map(({ from, to, id, aggregate }, index) => (
                                <tr key={id}>
                                    <td className="bootstrapTableRow">
                                        <TextField
                                            className={classNames(
                                                darkMode
                                                    ? 'text-gray-700'
                                                    : null,
                                            )}
                                            fullWidth
                                            value={from}
                                            id={`bringExistingNumberFrom-${id}`}
                                            onFocus={(e) => {
                                                if (
                                                    index ===
                                                    getTableState().length - 1
                                                ) {
                                                    setTableState([
                                                        ...getTableState(),
                                                        {
                                                            id: Number(id) + 1,
                                                            from: '',
                                                            to: '',
                                                        },
                                                    ]);
                                                }
                                            }}
                                            helperText={
                                                getTableStateError().find(
                                                    (v) => v.id === id,
                                                )?.from
                                            }
                                            InputProps={{
                                                inputComponent: PhoneNumber,
                                            }}
                                            error={Boolean(
                                                getTableStateError()?.find(
                                                    (v) => v.id === id,
                                                )?.from,
                                            )}
                                            onChange={(e) => {
                                                var item =
                                                    getTableStateError()?.find(
                                                        (v) => v.id === id,
                                                    );
                                                var itemIndex =
                                                    getTableStateError()?.findIndex(
                                                        (v) => v.id === id,
                                                    );
                                                var newItem = {
                                                    to:
                                                        to?.length > 0 &&
                                                        (!to?.match(
                                                            '^[0-9]{8,}$',
                                                        ) ||
                                                            e.target.value >
                                                                to ||
                                                            to.length !==
                                                                e.target.value
                                                                    .length) //has different length
                                                            ? 'invalid number format / range'
                                                            : null,
                                                    id,
                                                    from:
                                                        !e.target.value.match(
                                                            '^[0-9]{8,}$',
                                                        ) ||
                                                        (to &&
                                                            to?.length > 0 &&
                                                            (to <
                                                                e.target
                                                                    .value || //if already have 'to' and value is biiger / length doesnt match.
                                                                to.length !==
                                                                    e.target
                                                                        .value
                                                                        .length))
                                                            ? 'invalid number format / range'
                                                            : null,
                                                };
                                                var newTableStateError = [
                                                    ...getTableStateError(),
                                                ];
                                                if (itemIndex !== -1) {
                                                    newTableStateError.splice(
                                                        itemIndex,
                                                        1,
                                                    );
                                                }
                                                newTableStateError.push(
                                                    newItem,
                                                );

                                                setTableStateError(
                                                    newTableStateError,
                                                );
                                                const newPair = {
                                                    from: e.target.value,
                                                    id,
                                                    to,
                                                    aggregate,
                                                };
                                                var newTable = [
                                                    ...getTableState(),
                                                ];
                                                newTable.splice(
                                                    index,
                                                    1,
                                                    newPair,
                                                );
                                                setTableState(newTable);
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    !e.target.value.match(
                                                        '^[0-9]{8,}$',
                                                    ) ||
                                                    (to?.length > 0 &&
                                                        (to < e.target.value || //if already have 'to' and value is biiger / length doesnt match.
                                                            to.length !==
                                                                e.target.value
                                                                    .length))
                                                ) {
                                                    return;
                                                }
                                                if (!to || to?.length < 1) {
                                                    const newPair = {
                                                        from: e.target.value,
                                                        id,
                                                        to: e.target.value,
                                                    };
                                                    var newTable = [
                                                        ...getTableState(),
                                                    ];
                                                    newTable.splice(
                                                        index,
                                                        1,
                                                        newPair,
                                                    );
                                                    setTableState(newTable);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="bootstrapTableRow">
                                        <TextField
                                            className={classNames(
                                                darkMode
                                                    ? 'text-gray-700'
                                                    : null,
                                            )}
                                            fullWidth
                                            value={to}
                                            id={`bringExistingNumberTo-${id}`}
                                            InputProps={{
                                                inputComponent: PhoneNumber,
                                            }}
                                            helperText={
                                                getTableStateError().find(
                                                    (v) => v.id === id,
                                                )?.to
                                            }
                                            error={Boolean(
                                                getTableStateError().find(
                                                    (v) => v.id === id,
                                                )?.to,
                                            )}
                                            onFocus={(e) => {
                                                if (
                                                    index ===
                                                    getTableState().length - 1
                                                ) {
                                                    setTableState([
                                                        ...getTableState(),
                                                        {
                                                            id: Number(id) + 1,
                                                            from: '',
                                                            to: '',
                                                        },
                                                    ]);
                                                }
                                            }}
                                            onChange={(e) => {
                                                //find existing error item, or make new:
                                                var item =
                                                    getTableStateError()?.find(
                                                        (v) => v.id === id,
                                                    );
                                                var itemIndex =
                                                    getTableStateError()?.findIndex(
                                                        (v) => v.id === id,
                                                    );
                                                var newItem = {
                                                    from:
                                                        !from.match(
                                                            '^[0-9]{8,}$',
                                                        ) ||
                                                        from > e.target.value || //if already have 'to' and value is biiger / length doesnt match.
                                                        from.length !==
                                                            e.target.value
                                                                .length
                                                            ? 'invalid number format / range'
                                                            : null,
                                                    id,
                                                    to:
                                                        !e.target.value.match(
                                                            '^[0-9]{8,}$',
                                                        ) ||
                                                        from > e.target.value ||
                                                        from.length !==
                                                            e.target.value
                                                                .length //has different length
                                                            ? 'invalid number format / range'
                                                            : null,
                                                };
                                                var newTableStateError = [
                                                    ...getTableStateError(),
                                                ];
                                                if (itemIndex !== -1) {
                                                    newTableStateError.splice(
                                                        itemIndex,
                                                        1,
                                                    );
                                                }
                                                newTableStateError.push(
                                                    newItem,
                                                );

                                                setTableStateError(
                                                    newTableStateError,
                                                );
                                                const newPair = {
                                                    to: e.target.value,
                                                    id,
                                                    from,
                                                    aggregate,
                                                };
                                                //TODO: VALIDATION!
                                                var newTable = [
                                                    ...getTableState(),
                                                ];
                                                newTable.splice(
                                                    index,
                                                    1,
                                                    newPair,
                                                );
                                                setTableState(newTable);
                                            }}
                                        />
                                    </td>
                                    <td className="bootstrapTableRow">
                                        {getTableState().length > 1 && (
                                            <IconButton
                                                onClick={() => {
                                                    var newTable = [
                                                        ...getTableState(),
                                                    ];
                                                    newTable.splice(index, 1);
                                                    // dispatch({
                                                    //     type: 'CHANGE_MODAL_STATE',
                                                    //     payload: {
                                                    //         actionHeaders: newTable
                                                    //     }
                                                    // });
                                                    setTableState(newTable);
                                                }}>
                                                <ClearRoundedIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Box>
        </>
    );
};

export default ExistingNumbersTable;
