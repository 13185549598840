// Dashboards
import CallStats from 'pages/dashboards/callStats';

// Accounts
import Admins from 'pages/accounts/admins/Index';
import Permissions from 'pages/accounts/permissions/Index';
import Templates from 'pages/accounts/templates/Index';

import {
    UsersRoutes,
    ResourceAccountsRoutes,
} from '@/routes/users/index.routes';

// Automation
import { Triggers, Jobs, Engine } from 'pages/automation';

// Billing
import { InternationalRatesList, Invoices } from 'pages/billing/';
import PlansRoutes from '@/routes/plans/index.routes';

// Organisations
import { Companies, Partners, WholesalerIndex } from 'pages/organisations/';
import Addresses from 'pages/organisations/addresses';

// Services
import { OperatorProfiles } from 'pages/services';
import ServiceDashboard from 'pages/services/new/ServiceDashboard';
import ServiceProfiles from 'pages/services/serviceprofiles/new';
import ServiceRoutes from 'pages/services/new/routes/index.routes';

// Approval codes
import ApprovalCodes from 'pages/automation/approval-codes';

// Telephony
import {
    Dial,
    VoicePolicies,
    Carriers,
    Trunks,
    Calls,
    Numbers,
    NumberBlocks,
    Regions,
    SpecialNumbers,
    TrunkTypes,
} from 'pages/telephony';

// Settings
import Settings from 'pages/settings';
import ProtectedNoMatch from 'components/v4/ProtectedNoMatch';
import AutoAttendantGuide from 'pages/accounts/users/guides/AutoAttendantGuide';
import VirtualUserGuide from 'pages/accounts/users/guides/VirtualUserGuide';
import CSVNumbersUpdate from 'pages/telephony/numbers/csv';
import Reload from 'pages/reload';
import AdminsEdit from 'pages/accounts/admins/new/AdminsEdit';
import AddTrunk from 'pages/telephony/trunks/new/AddTrunk';
import EditSipTrunk from 'pages/telephony/trunks/new/EditSipTrunk';
import EditExtTrunk from 'pages/telephony/trunks/new/EditExtTrunk';
import EditOCTrunk from 'pages/telephony/trunks/new/EditOCTrunk';
import EditDRTrunk from 'pages/telephony/trunks/new/EditDRTrunk';

// new organisations
import CompanyTable from 'pages/organisations/companies/new/Index';
import { CompanyAdd } from 'pages/organisations/companies/new/CompanyAdd';
import Countries from 'pages/countries';
import AddCountry from 'pages/countries/AddCountry';
import EditCountry from 'pages/countries/EditCountry';

import ApiUserEdit from 'pages/accounts/admins/apiUsers/edit/ApiUserEdit';
import ApiUserCreate from 'pages/accounts/admins/apiUsers/create/ApiUserCreate';
import ServiceCategories from 'pages/services/serviceCategories';
import EditServiceCategory from 'pages/services/serviceCategories/EditServiceCategory';
import AddServiceCategory from 'pages/services/serviceCategories/AddServiceCategory';
import { ServicesDashboardProvider } from 'context/ServicesDashboardContext';

/**
 * @param {import('@tanstack/react-query').QueryClient} [queryClient]
 * @returns {import('react-router-dom').RouteObject[]}
 */
// eslint-disable-next-line no-unused-vars
const authenticatedRoutes = (queryClient = {}) => [
    // Other
    { path: '*', element: <ProtectedNoMatch /> },
    { path: '/settings', element: <Settings />, hidden: true },
    { path: '/csvnumberupdate', element: <CSVNumbersUpdate />, hidden: true },
    { path: '/virtualuserguide', element: <VirtualUserGuide />, hidden: true },
    { path: '/guide', element: <AutoAttendantGuide />, hidden: true },
    { path: '/reload/*', element: <Reload />, hidden: true },
    // Dashboards
    {
        path: '/dashboard-services',
        element: (
            <ServicesDashboardProvider>
                <ServiceDashboard />
            </ServicesDashboardProvider>
        ),
    },
    {
        path: '/call-stats',
        element: <CallStats />,
    },
    // Accounts
    {
        path: '/admins',
        element: <Admins />,
    },
    {
        path: '/admins/:adminId/edit',
        element: <AdminsEdit />,
    },
    {
        path: '/admins/api/',
        element: <ApiUserCreate />,
    },
    {
        path: '/admins/api/:apiUserId/edit',
        element: <ApiUserEdit />,
    },
    {
        path: '/permissions',
        element: <Permissions />,
    },
    {
        path: '/resource-accounts/*',
        element: <ResourceAccountsRoutes />,
    },
    {
        path: '/users/*',
        element: <UsersRoutes />,
    },
    // Automation
    {
        // No route needed for API
    },
    {
        element: <Triggers />,
        path: '/triggers',
    },
    { element: <Jobs />, path: '/jobs' },
    { element: <ApprovalCodes />, path: '/approval-codes' },
    // Billing
    {
        path: '/international-rates',
        element: <InternationalRatesList />,
    },
    {
        path: '/invoices',
        element: <Invoices />,
    },
    {
        path: '/plans/*',
        element: <PlansRoutes />,
    },
    // {
    //     path: '/plans',
    //     element: <Plans />,
    // },
    // Organisations
    {
        path: '/new/companies',
        element: <CompanyTable />,
    },
    {
        path: '/new/companies/add',
        element: <CompanyAdd />,
    },
    {
        path: '/companies',
        element: <Companies />,
    },
    {
        path: '/partners',
        element: <Partners />,
    },
    {
        path: '/addresses',
        element: <Addresses />,
    },
    {
        path: '/service-profiles',
        element: <ServiceProfiles />,
    },
    {
        path: '/services/*',
        element: <ServiceRoutes />,
    },
    // Telephony
    {
        path: '/calls',
        element: <Calls />,
    },
    {
        path: '/dial-plans',
        element: <Dial />,
        // loader: dialLoader,
    },

    {
        path: '/numbers',
        element: <Numbers />,
    },
    {
        path: '/number-blocks',
        element: <NumberBlocks />,
    },
    {
        path: '/trunks',
        element: <Trunks />,
    },
    {
        path: '/trunks/add',
        element: <AddTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/sip/:id',
        element: <EditSipTrunk />,
        errorElement: <ServiceDashboard />,
    },
    {
        path: '/trunks/edit/ext/:id',
        element: <EditExtTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/dr/:id',
        element: <EditDRTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/trunks/edit/oc/:id',
        element: <EditOCTrunk />,
        errorElement: <Trunks />,
    },
    {
        path: '/voice-policies',
        element: <VoicePolicies />,
    },
    // GA
    {
        path: '/templates',
        element: <Templates />,
    },
    {
        path: '/service-categories',
        element: <ServiceCategories />,
    },
    {
        path: '/service-categories/add',
        element: <AddServiceCategory />,
    },
    {
        path: '/service-categories/edit/:serviceCategoryId',
        element: <EditServiceCategory />,
    },
    {
        path: '/engines',
        element: <Engine />,
    },
    {
        path: '/wholesalers',
        element: <WholesalerIndex />,
    },
    {
        path: '/carriers',
        element: <Carriers />,
    },
    {
        path: '/regions-and-locations',
        element: <Regions />,
    },
    {
        path: '/countries',
        element: <Countries />,
    },
    {
        path: '/countries/add',
        element: <AddCountry />,
    },
    {
        path: '/countries/edit/:id',
        element: <EditCountry />,
    },
    {
        path: '/special-numbers',
        element: <SpecialNumbers />,
    },
    {
        path: '/trunk-types',
        element: <TrunkTypes />,
    },
    {
        path: '/operator-connect-profiles',
        element: <OperatorProfiles />,
    },
];

export default authenticatedRoutes;
