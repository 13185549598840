export const _focus = '_focus';
export const _active = '_active';
export const _hover = '_hover';

/** @param {boolean} lightMode */
export const getColorMode = (lightMode) => ({
    [_focus]: {
        bg: lightMode ? 'blackAlpha.300' : 'whiteAlpha.300',
    },
    [_active]: {
        bg: lightMode ? 'blackAlpha.300' : 'whiteAlpha.300',
    },
    [_hover]: {
        bg: lightMode ? 'blackAlpha.100' : 'whiteAlpha.100',
    },
});
export const accordionItemStyle = {
    '.chakra-stack .chakra-text': {
        marginInlineStart: 0,
    },

    '&': {
        marginBottom: 1,
    },
    '& .chakra-stack :where(.chakra-button)': {
        margin: 0,
    },
};
