/* eslint-disable */
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from 'react';
import { Heading, Box } from '@chakra-ui/react';
import { TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { Row, Col, Container, Button } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { onModalError } from '../../../../components/tables/TableAndModal';
import config from '../../../../config.json';
import { LoadingFieldDropdown } from '../../../../components';
import { axios } from '../../../../services/axios';
import useAddress from '../../../../hooks/useAddress';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import { renderTelephoneNumber } from 'utils/utils';
import { customAutoCompleteStyle } from 'pages/navbar/constant';

import walkthroughIds from '../walkthroughIds';

const { editNumbersGeneral } = walkthroughIds;

function AddressLoader({ modalState }) {
    const dispatch = useDispatch();
    const { colorScheme } = useSelector((state) => state.settings);

    const [addresses, setAddresses] = useState([
        {
            id: null,
            description: '- Company Default -',
        },
    ]);
    const [selectedAddress, setSelectedAddress] = useState(addresses[0]);
    const { concatAddressFields } = useAddress();

    useEffect(() => {
        axios.get(`/addresses/${modalState?.companyID}`).then((response) => {
            // Addresses
            const addressesConcated = response.data.map((address) => {
                const addressConcated = concatAddressFields(address);
                if (address.description.length) {
                    return {
                        ...address,
                        description: `${address.description} - ${addressConcated}`,
                    };
                }
                return {
                    ...address,
                    description: addressConcated,
                };
            });
            setAddresses([...addresses, ...addressesConcated]);

            // Default address (if there is one)
            const defaultAddress = addressesConcated.filter(
                (address) => address.id === modalState.addressID,
            );

            if (defaultAddress.length) {
                setSelectedAddress(defaultAddress[0]);
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        addressID: defaultAddress[0].id,
                    },
                });
            } else {
                setSelectedAddress({
                    id: null,
                    description: '- Company Default -',
                });
                dispatch({
                    type: 'CHANGE_MODAL_STATE',
                    payload: {
                        addressID: null,
                    },
                });
            }
        });
    }, []);

    // if (addresses.length === 1 || !selectedAddress) return null;

    return (
        <>
            <Box sx={customAutoCompleteStyle(colorScheme)}>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    options={addresses}
                    value={selectedAddress}
                    onChange={(e, value) => {
                        // Update address ID
                        dispatch({
                            type: 'CHANGE_MODAL_STATE',
                            payload: {
                                addressID: value.id,
                            },
                        });
                        // Update selected option
                        setSelectedAddress(value);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Address" />
                    )}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) => {
                        const a = JSON.stringify(option);
                        const b = JSON.stringify(value);
                        //return option.id === value.id;
                        return a === b;
                    }}
                    data-walkthroughid={editNumbersGeneral.address}
                    popupIcon={<ChevronDownIcon boxSize={5} mt="2px" />}
                />
            </Box>
        </>
    );
}

/*
 * General Form tab of the Numbers -> Numbers component
 * used as a form for CRUD
 */
const NumbersGeneralForm = () => {
    const dispatch = useDispatch();
    const [actionedState, setActionedState] = useState('CLICKABLE'); //CLICKABLE || LOADING || SUCCESS

    // state access
    const { modalState } = useSelector((state) => {
        return {
            modalLoading: state.modal.loading,
            modalData: state.modal.data,
            modalState: state.modal.state,
            modalMode: state.modal.mode,
            modalStateError: state.modal.stateError,
            modalUploading: state.modal.uploading,
            modalLoadFields: state.modal.loadFields,
            modalShow: state.modal.show,
        };
    });

    const { state: tableState } = useSelector((state) => state.table);

    const [availableTrunks, setAvailableTrunks] = React.useState([]);

    const { requiredScope, canOverrideOverStamping } = useSelector((state) => {
        return { ...state.login };
    });

    // change handlers
    const numberChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                cliPresentation: v,
            },
        });
    };

    const operatorUsageChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                operatorConnectUsageID: v,
            },
        });
    };

    const operatorProfileChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                operatorConnectCallingProfileID: v,
            },
        });
    };

    const trunkChangeHandler = (v) => {
        if (!v) {
            dispatch({
                type: 'CHANGE_MODAL_STATE',
                payload: {
                    trunkID: null,
                    sipHeader: null,
                    trunkType: null,
                },
            });
            return;
        }
        const trunk = availableTrunks.find((item) => item.id === v);
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                trunkID: v,
                sipHeader: trunk.sipHeader,
                trunkType: trunk.trunkType?.name,
            },
        });
    };

    const addressChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                addressID: v,
            },
        });
    };

    const checkboxChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                isEnabled: !modalState?.isEnabled,
            },
        });
    };

    const countryChangeHandler = (v) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                countryID: v,
            },
        });
    };

    const overstampChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                overstampNumber: e.target.value,
            },
        });
    };

    const outboundChangeHandler = (e) => {
        dispatch({
            type: 'CHANGE_MODAL_STATE',
            payload: {
                cliPresentation: e.target.value,
            },
        });
    };

    const isOCTrunk = React.useMemo(() => {
        return (
            modalState?.trunkType === 'MS Operator Connect' ||
            modalState?.trunkType?.name === 'MS Operator Connect'
        );
    }, [modalState?.trunkType]);

    const callingProfileList = React.useMemo(() => {
        if (isOCTrunk && modalState?.trunkID && availableTrunks?.length > 0) {
            const trunk = availableTrunks.find(
                (item) => item.id === modalState?.trunkID,
            );

            return trunk.callingProfileList ?? [];
        }
        return [];
    }, [isOCTrunk, modalState?.trunkID, availableTrunks]);

    React.useEffect(() => {
        if (isOCTrunk && !modalState?.operatorConnectUsageID) {
            operatorUsageChangeHandler('CallingUserAssignment');
        }
    }, [isOCTrunk, modalState?.operatorConnectUsageID]);

    React.useEffect(() => {
        if (!modalState?.companyID) return;
        axios
            .get('/trunks/' + modalState?.companyID)
            .then((res) => {
                const availableTrunks = res.data.map((availableTrunk) => ({
                    ...availableTrunk,
                    sipHeader: !availableTrunk?.name
                        ? `(${availableTrunk.sipHeader})`
                        : `${availableTrunk.name} (${availableTrunk.sipHeader})`,
                }));
                setAvailableTrunks(availableTrunks);
            })
            .catch((e) => {
                onModalError(e);
            });
    }, [modalState?.companyID]);

    React.useEffect(() => {
        if (isOCTrunk && callingProfileList.length === 1) {
            operatorProfileChangeHandler(callingProfileList[0]?.id);
        }
    }, [isOCTrunk, callingProfileList]);

    const [trunkUsageDropdown, setTrunkUsageDropdown] = useState([]);
    useEffect(() => {
        if (isOCTrunk && modalState?.companyID) {
            axios
                .get(`Company/${modalState?.companyID}/OperatorConnectUsages`)
                .then((res) => {
                    setTrunkUsageDropdown(res.data);
                })
                .catch((e) => {
                    onModalError(e);
                });
        }
    }, [isOCTrunk, modalState?.companyID]);

    return (
        <>
            <Row style={{ minHeight: 40, marginTop: '25px' }}>
                {modalState?.companyID && (
                    <Col>
                        <LoadingFieldDropdown
                            searchable
                            fieldFetch={`/company/${modalState?.companyID}/numbers/presentable`}
                            fieldName="Outbound Caller ID"
                            dependency={modalState?.companyID}
                            hasDependency={true}
                            dropDownValue="telephoneNumber"
                            noEmptyOption
                            additional={[
                                {
                                    telephoneNumber: 'Restricted',
                                },
                            ]}
                            displayField="telephoneNumber"
                            onDisplayDropdown={(v) =>
                                (Number(v['telephoneNumber']) &&
                                    '+' + v['telephoneNumber']) ||
                                v['telephoneNumber']
                            }
                            groupBy={({ telephoneNumber }) => {
                                if (telephoneNumber.startsWith('611300')) {
                                    return '611300';
                                }
                                if (telephoneNumber.startsWith('611800')) {
                                    return '611800';
                                }
                                if (telephoneNumber.startsWith('6113')) {
                                    return '6113';
                                }
                                return telephoneNumber.substring(0, 3);
                            }}
                            onChange={numberChangeHandler}
                            fieldValue={modalState?.cliPresentation}
                            onError={onModalError}
                            dataWalkthroughid={
                                editNumbersGeneral.outboundCallerId
                            }
                        />
                    </Col>
                )}
                {modalState?.companyID && (
                    <Col>
                        <LoadingFieldDropdown
                            searchable
                            staticEnum={availableTrunks}
                            fieldName="Trunk"
                            dropDownValue="id"
                            displayField="sipHeader"
                            onChange={trunkChangeHandler}
                            fieldValue={modalState?.trunkID}
                            onError={onModalError}
                            dataWalkthroughid={editNumbersGeneral.trunk}
                        />
                    </Col>
                )}
                {modalState?.companyID && (
                    <Col>
                        <AddressLoader modalState={modalState} />
                    </Col>
                )}
                <Col>
                    <LoadingFieldDropdown
                        searchable
                        staticEnum={modalState?.countries}
                        fieldName="Country"
                        dropDownValue="id"
                        displayField="name"
                        noEmptyOption
                        onChange={countryChangeHandler}
                        fieldValue={modalState?.countryID}
                        onError={onModalError}
                        dataWalkthroughid={editNumbersGeneral.country}
                    />
                </Col>
            </Row>

            <br />

            <Row style={{ minHeight: 60 }}>
                {requiredScope === 80 && (
                    <Col>
                        <TextField
                            fullWidth
                            label={'Wholesaler'}
                            disabled
                            type="text"
                            value={modalState?.wholesaler}
                            data-walkthroughid={editNumbersGeneral.wholesaler}
                        />
                    </Col>
                )}
                {requiredScope > 40 && (
                    <Col>
                        <TextField
                            fullWidth
                            label={'Partner'}
                            disabled
                            type="text"
                            value={modalState?.partner}
                            data-walkthroughid={editNumbersGeneral.partner}
                        />
                    </Col>
                )}
                <Col>
                    <TextField
                        fullWidth
                        label={'Company'}
                        disabled
                        type="text"
                        value={modalState?.company}
                        data-walkthroughid={editNumbersGeneral.company}
                    />
                </Col>
                <Col>
                    <TextField
                        fullWidth
                        label={'UserID'}
                        disabled
                        type="text"
                        value={modalState?.user}
                        data-walkthroughid={editNumbersGeneral.userId}
                    />
                </Col>
            </Row>
            {isOCTrunk && (
                <Row>
                    <Col>
                        <LoadingFieldDropdown
                            searchable
                            staticEnum={callingProfileList}
                            fieldName="Operator Connect Calling Profile"
                            noEmptyOption
                            dropDownValue="id"
                            displayField="name"
                            onChange={operatorProfileChangeHandler}
                            fieldValue={
                                modalState?.operatorConnectCallingProfileID
                            }
                            onError={onModalError}
                            dataWalkthroughid={
                                editNumbersGeneral.operatorConnectCallingProfile
                            }
                        />
                    </Col>
                    <Col>
                        <LoadingFieldDropdown
                            searchable
                            staticEnum={trunkUsageDropdown}
                            fieldName="Operator Connect Telephone Number Usage"
                            noEmptyOption
                            dropDownValue="id"
                            displayField="name"
                            onChange={operatorUsageChangeHandler}
                            fieldValue={modalState?.operatorConnectUsageID}
                            onError={onModalError}
                            dataWalkthroughid={
                                editNumbersGeneral.operatorConnectTelephoneNumberUsage
                            }
                        />
                    </Col>
                </Row>
            )}

            <Heading as="h4" fontSize="2xl" mb={4}>
                Danger Zone{' '}
            </Heading>
            <Container fluid className="roundBox">
                <Row style={{ minHeight: 60 }}>
                    <Col style={{ minWidth: '50%' }}>
                        <b> Status (read-only)</b> <br /> Manually forcing a
                        status could result in errors.
                    </Col>
                    <Col style={{ minWidth: '50%', textAlign: 'right' }}>
                        <div className="flex flex-row gap-1">
                            <div className="w-3/4">
                                <LoadingFieldDropdown
                                    searchable
                                    staticEnum={config.numbers.status}
                                    dropDownValue="value"
                                    displayField="name"
                                    onError={onModalError}
                                    disabled
                                    fieldValue={modalState?.status}
                                    onChange={(v) => {
                                        return;
                                    }}
                                    dataWalkthroughid={
                                        editNumbersGeneral.status
                                    }
                                />
                            </div>

                            {modalState?.status === 7 && (
                                <Button
                                    disabled={actionedState !== 'CLICKABLE'}
                                    onClick={() => {
                                        setActionedState('LOADING');

                                        axios
                                            .post(
                                                `/Number/${modalState.id}/Actioned`,
                                            )
                                            .then((res) => {
                                                setActionedState('SUCCESS');
                                                //change table state

                                                dispatch({
                                                    type: 'RESET_TABLE',
                                                });
                                            })
                                            .catch((e) => {
                                                console.log('error');
                                                setActionedState('CLICKABLE');

                                                window.alert(
                                                    `We are having issues submitting your Actioned Notifications for ${renderTelephoneNumber(
                                                        modalState,
                                                        'telephoneNumber',
                                                    )} . Please try again later.`,
                                                );
                                            });
                                    }}
                                    variant={
                                        actionedState === 'LOADING'
                                            ? 'outlined'
                                            : 'primary'
                                    }
                                    size="md"
                                    className="mt-[6px] h-[40px] w-1/4"
                                    data-walkthroughid={
                                        editNumbersGeneral.actionButton
                                    }>
                                    {actionedState === 'CLICKABLE' ? (
                                        <>
                                            <DoneRoundedIcon />
                                            {'Actioned'}
                                        </>
                                    ) : actionedState === 'LOADING' ? (
                                        <>
                                            <CircularProgress
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />{' '}
                                            {'Loading'}
                                        </>
                                    ) : (
                                        <>
                                            <DoneAllRoundedIcon fontSize="small" />
                                            {'Actioned Sent'}{' '}
                                        </>
                                    )}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row style={{ minHeight: 60 }}>
                    <Col style={{ minWidth: '50%' }}>
                        <b> Overstamp Number </b> <br /> This is not validated.
                    </Col>
                    <Col style={{ minWidth: '50%', textAlign: 'right' }}>
                        <TextField
                            fullWidth
                            label=""
                            type="text"
                            value={modalState?.overstampNumber}
                            onChange={overstampChangeHandler}
                            data-walkthroughid={
                                editNumbersGeneral.overStampNumber
                            }
                        />
                    </Col>
                </Row>
                {canOverrideOverStamping && (
                    <>
                        <Divider />
                        <Row style={{ minHeight: 60 }}>
                            <Col style={{ minWidth: '50%' }}>
                                <b> Override Outbound Caller ID </b> <br /> This
                                is not validated.
                            </Col>
                            <Col
                                style={{ minWidth: '50%', textAlign: 'right' }}>
                                <TextField
                                    fullWidth
                                    label=""
                                    type="text"
                                    value={modalState?.cliPresentation}
                                    onChange={outboundChangeHandler}
                                    data-walkthroughid={
                                        editNumbersGeneral.overrideOutboundCallerId
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
};

export default NumbersGeneralForm;
